import {useState, useContext, createContext} from 'react';

const NavigationContext = createContext<{
    activeHeaderKey?: NavigationHeaderKey, setActiveHeaderKey?: any,
    activeSidebarKey?: NavigationSidebarKey, setActiveSidebarKey?: any,
    toggled: boolean, setToggled?: any,
    sportsCenter?: SportsCenter, setSportsCenter?: any
    onToggleClicked?: any
}>({toggled: false});

export const NavMenuProvider: React.FC<{ children: any }> = ({children}) => {
    const [activeHeaderKey, setActiveHeaderKey] = useState<NavigationHeaderKey | undefined>();
    const [activeSidebarKey, setActiveSidebarKey] = useState<NavigationSidebarKey | undefined>();
    const [toggled, setToggled] = useState<boolean>(false);
    const [sportsCenter, setSportsCenter] = useState<SportsCenter | undefined>();


    const onToggleClicked = () => {
        setToggled(!toggled)
    }

    const value = {
        activeHeaderKey: activeHeaderKey, setActiveHeaderKey: setActiveHeaderKey,
        activeSidebarKey: activeSidebarKey, setActiveSidebarKey: setActiveSidebarKey,
        toggled: toggled, setToggled: setToggled,
        sportsCenter: sportsCenter, setSportsCenter: setSportsCenter,
        onToggleClicked: onToggleClicked
    }

    return (
        <NavigationContext.Provider value={value}>
            {children}
        </NavigationContext.Provider>
    );
};

export const useNavMenu = () => useContext(NavigationContext);