import React, {useEffect, useState} from 'react';
import {Alert, Badge, Button, Col, Form, Row, Table} from 'react-bootstrap';
import {
    getSportsCenterNotificationRequests,
    updateNotificationRequestStatus
} from "../../../helpers/api";
import translate from "../../../helpers/translations";
import ConfirmationModal from "../../../components/ConfirmationModal";
import Splash from "../../Splash";
import SRTable from "../../../components/SRTable";
import {formatDateValue} from "../../../helpers/date";
import CenterNotificationRequestDetailsModal from "./CenterNotificationRequestDetailsModal";
import NewCenterNotificationRequestModal from "./NewCenterNotificationRequestModal";

const PAGE_SIZE = 50;

interface Props {
    sportsCenterId: number;
    sportsCenterGalleryEnabled: boolean
}

const CenterNotificationRequests: React.FC<Props> = ({sportsCenterId, sportsCenterGalleryEnabled}) => {
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [status, setStatus] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(true);
    const [errorMsg, setErrorMsg] = useState<string | null>(null);
    const [requests, setRequests] = useState<SportsCenterNotificationRequests[]>([]);
    const [selectedRequest, setSelectedRequest] = useState<SportsCenterNotificationRequests | null>(null);
    const [cancelingId, setCancelingId] = useState<number | null>(null)
    const [showAddNew, setShowAddNew] = useState(false)


    useEffect(() => {
        search();
    }, [status]);

    const search = () => {
        setLoading(true);
        setErrorMsg(null);
        setPageNumber(0);
        getSportsCenterNotificationRequests(sportsCenterId, 0, PAGE_SIZE, status)
            .then(({ data, headers }) => {
                setLoading(false);
                setRequests(data);
                setTotalCount(parseInt(headers['x-total-count']));
            })
            .catch(({ response: { data } }) => {
                setLoading(false);
                setRequests([]);
                setTotalCount(0);
                if (data && data.message) {
                    setErrorMsg(data.message);
                } else {
                    setErrorMsg(translate('errorFetchingResults') + '.');
                }
            })
    }

    const submit = (e: any) => {
        e.preventDefault()
        search()
    }

    const loadMoreResults = () => {
        setLoading(true);
        getSportsCenterNotificationRequests(sportsCenterId, pageNumber + 1, PAGE_SIZE, status)
            .then(({ data, headers }) => {
                setRequests(requests.concat(data));
                setPageNumber(pageNumber + 1);
                setTotalCount(parseInt(headers['x-total-count']));
            })
            .catch(({ response: { data } }) => {
                setRequests([]);
                setTotalCount(0);
                if (data && data.message) {
                    setErrorMsg(data.message);
                } else {
                    setErrorMsg(translate('errorFetchingResults') + '.');
                }
            })
            .finally(() => setLoading(false))
    }
    const getStatusBadge = (status: string) => {
        switch (status) {
            case 'PENDING_APPROVAL':
                return <Badge bg='info'>{translate(status)}</Badge>
            case 'CANCELED':
                return <Badge bg='warning'>{translate(status)}</Badge>
            case 'APPROVED':
                return <Badge bg='primary'>{translate(status)}</Badge>
            case 'REJECTED':
                return <Badge bg='danger'>{translate(status)}</Badge>
            case 'NOTIFICATIONS_SENT':
                return <Badge bg='success'>{translate(status)}</Badge>
            case 'EXPIRED':
                return <Badge bg='secondary'>{translate(status)}</Badge>
            default:
                return <Badge bg='secondary'>{translate(status)}</Badge>
        }
    }

    const handleStatusChange = (id: number, newStatus: string) => {
        updateNotificationRequestStatus(sportsCenterId, id, newStatus)
            .then(() => {
                setRequests((prevRequests) =>
                    prevRequests.map((request) =>
                        request.id === id ? { ...request, status: newStatus } : request
                    )
                );
            })
            .finally(() => setLoading(false))
    }

    return (
        <>
            {cancelingId != null &&
                <ConfirmationModal
                    title={translate('confirmation')}
                    body={translate('sureCancelSendingNotification') + '?'}
                    confirmButtonVariant='danger'
                    onConfirm={() => {
                        setCancelingId(null);
                        handleStatusChange(cancelingId, 'CANCELED')
                    }}
                    onCancel={() => {
                        setCancelingId(null);
                    }}
                />}
            {errorMsg &&
                <Row>
                    <Col>
                        <Alert variant='danger' style={{fontSize: '0.85rem'}}>
                            <div style={{fontWeight: '700'}}>{errorMsg}</div>
                        </Alert>
                    </Col>
                </Row>
            }
            {loading && <Splash/>}
            <Form onSubmit={submit}>
                <Row>
                    <Col md={4} xs={6}>
                        <Form.Group controlId="form.status">
                            <Form.Label style={{fontSize: '12px', margin: 0}}>{translate('status')}</Form.Label>
                            <Form.Select value={status}
                                         onChange={e => setStatus(e.target.value)}
                            >
                                <option value=''>{translate('allStatuses')}</option>
                                <option value='PENDING_APPROVAL'>{translate('PENDING_APPROVAL')}</option>
                                <option value='APPROVED'>{translate('APPROVED')}</option>
                                <option value='CANCELED'>{translate('CANCELED')}</option>
                                <option value='REJECTED'>{translate('REJECTED')}</option>
                                <option value='NOTIFICATIONS_SENT'>{translate('NOTIFICATIONS_SENT')}</option>
                                <option value='EXPIRED'>{translate('EXPIRED')}</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col md={8} xs="6" style={{textAlign: 'end'}}>
                        <Form.Group controlId="form.add">
                            <Form.Label style={{fontSize: '12px', margin: 0}}/>
                            <div>
                                <Button variant='primary'
                                        onClick={() => setShowAddNew(true)}>{translate('sendNotification')}
                                </Button>
                            </div>

                        </Form.Group>
                    </Col>
                </Row>
            </Form>
            <div className='d-flex flex-column'>
                {requests.length !== 0 &&
                    <SRTable tableHover>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>{translate('title')}</th>
                            <th>{translate('description')}</th>
                            <th>{translate('createdAt')}</th>
                            <th>{translate('lastModifiedAt')}</th>
                            <th>{translate('status')}</th>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        {requests.length !== 0 &&
                            requests.map((r, index) =>
                                <tr key={r.id} onClick={() => setSelectedRequest(r)}>
                                    <td className='mx-2'>{requests.length - index}</td>
                                    <td>{r.title}</td>
                                    <td style={{maxWidth: '400px', wordWrap: 'break-word'}}>{r.body}</td>
                                    <td style={{whiteSpace: 'nowrap'}}>{formatDateValue(r.createdAt)}</td>
                                    <td style={{whiteSpace: 'nowrap'}}>{formatDateValue(r.lastModifiedAt)}</td>
                                    <td>{getStatusBadge(r.status)}</td>
                                    <td className='text-end'>
                                        {r.status == 'PENDING_APPROVAL' &&
                                            <Button onClick={e => {
                                                setCancelingId(r.id)
                                                e.stopPropagation()
                                            }} size="sm" variant='danger'>{translate('cancel')}
                                            </Button>
                                        }
                                    </td>
                                </tr>)}
                        </tbody>
                    </SRTable>}
                {requests.length === 0 && <h4 className='text-muted my-5 text-center'>{translate('noResults')}</h4>}
                {requests.length < totalCount &&
                    <span>
                    <Row>
                        <Col className='d-flex justify-content-center'>
                            {requests.length} / {totalCount}
                        </Col>
                    </Row>
                    <Row>
                        <Col className='d-flex justify-content-center'>
                          <Button disabled={loading} variant='link' className='mt-1 mb-0'
                                  onClick={() => loadMoreResults()}>{translate('seeMoreResults')}</Button>
                        </Col>
                    </Row>
                </span>
                }
                {requests.length >= totalCount && requests.length > 0 && <Row>
                    <Col className='d-flex justify-content-center'>
                        <div className='mt-3 mb-5'>{translate('thatsAllResults')}</div>
                    </Col>
                </Row>}
                {selectedRequest &&
                    <CenterNotificationRequestDetailsModal request={selectedRequest}
                                             onHide={() => setSelectedRequest(null)}
                    />
                }
                {showAddNew &&
                    <NewCenterNotificationRequestModal
                        sportsCenterId={sportsCenterId}
                        sportsCenterGalleryEnabled={sportsCenterGalleryEnabled}
                        onHide={() => {
                            setShowAddNew(false)
                        }}
                        onSave={() => {
                            setShowAddNew(false)
                            search()
                        }}
                    />
                }
            </div>
        </>
    )
}

export default CenterNotificationRequests;