import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { AuthProvider } from './helpers/auth';
import { ToastNotificationsProvider } from './helpers/notifications';
import './index.css'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <AuthProvider>
      <ToastNotificationsProvider>
        <App />
      </ToastNotificationsProvider>
    </AuthProvider>
  </React.StrictMode>
);