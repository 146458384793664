import React from 'react';
import { Button, Col, Form, InputGroup, Row, Spinner } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router-dom';
import { dateToDateValue, dateValueToDate } from '../../helpers/date';
import { SPORTS } from '../../helpers/enums';
import translate from '../../helpers/translations';

interface Props {
  filters: Filters;
  setFilters: ((filters: Filters) => any);
  cities: City[];
  countries: Country[];
  neighborhoods: Neighborhood[];
  loading: boolean;
  onSubmit: () => any;
}

const CentersForm: React.FC<Props> = ({ filters, setFilters, cities, countries, neighborhoods, loading, onSubmit }) => {
  const navigate = useNavigate();

  const submit = (e: any) => {
    e.preventDefault()
    onSubmit()
  }

  return <Form onSubmit={submit}>
    <Row>
      <Col md={6} lg={4} className='mb-2'>
        <Form.Control placeholder={translate('query')} onChange={e => setFilters({ ...filters, query: e.target.value || null })} />
      </Col>
      <Col md={6} lg={4} className='mb-2'>
        <Form.Select onChange={e => setFilters({ ...filters, sport: e.target.value || null })}>
          <option key='' value=''>{translate('allSports')}</option>
          {SPORTS.map(s => <option key={s} value={s}>{translate(s)}</option>)}
        </Form.Select>
      </Col>
      <Col md={6} lg={4} className='mb-2 d-flex justify-content-around align-items-end'>
        <Form.Check label={translate('parking')} onClick={e => setFilters({ ...filters, parking: ((e.target as HTMLInputElement).checked ? true : null) })} />
        <Form.Check label={translate('bar')} onClick={e => setFilters({ ...filters, bar: ((e.target as HTMLInputElement).checked ? true : null) })} />
      </Col>
      <Col md={6} lg={4} className='mb-2'>
        <Form.Select onChange={e => setFilters({ ...filters, countryId: e.target.value === '' ? null : parseInt(e.target.value), cityId: null, neighborhoodId: null })}>
          <option key='' value=''>{translate('allCountries')}</option>
          {countries.map(c => <option key={c.id} value={c.id}>{c.name}</option>)}
        </Form.Select>
      </Col>
      <Col md={6} lg={4} className='mb-2'>
        <Form.Select onChange={e => setFilters({ ...filters, cityId: e.target.value === '' ? null : parseInt(e.target.value), neighborhoodId: null })}>
          <option key='' value=''>{translate('allCities')}</option>
          {cities.map(c => <option key={c.id} value={c.id}>{c.name}</option>)}
        </Form.Select>
      </Col>
      <Col md={6} lg={4} className='mb-2'>
        <Form.Select onChange={e => setFilters({ ...filters, neighborhoodId: e.target.value === '' ? null : parseInt(e.target.value) })}>
          <option key='' value=''>{translate('allNeighborhoods')}</option>
          {neighborhoods.map(n => <option key={n.id} value={n.id}>{n.name}</option>)}
        </Form.Select>
      </Col>
      <Col md={6} lg={4} className='mb-2'>
        <InputGroup>
          <Form.Control type='number' placeholder={translate('minRating')} onChange={e => setFilters({ ...filters, minRating: e.target.value === '' ? null : parseFloat(e.target.value) })} />
          <Form.Control type='number' placeholder={translate('maxRating')} onChange={e => setFilters({ ...filters, maxRating: e.target.value === '' ? null : parseFloat(e.target.value) })} />
        </InputGroup>
      </Col>
    </Row>
    <Row>
      <Col>
        <div className='d-flex justify-content-between mt-4'>
          <Button variant='secondary' style={{ width: '8rem' }} onClick={() => navigate('/center/new')}>{translate('newCenter')}</Button>
          <Button type="submit" variant='primary' style={{ width: '8rem' }}>
            {loading ? <Spinner animation='border' as='span' size='sm' /> : translate('search')}
          </Button>
        </div>
      </Col>
    </Row>
  </Form>;
}

export default CentersForm;