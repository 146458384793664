import React, {useEffect, useState} from 'react';
import {useNavMenu} from "../../components/NavMenu/NavMenuProvider";
import SRTable from "../../components/SRTable";
import translate from "../../helpers/translations";
import {clearCache, clearCacheFull, getCacheStats} from "../../helpers/api";
import Splash from "../Splash";
import {Alert, Button} from "react-bootstrap";
import {useToastNotifications} from "../../helpers/notifications";


const CacheStatistics: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [errorMsg, setErrorMsg] = useState<string | null>(null);
    const [results, setResults] = useState<CacheStatistics[]>([]);

    const {newToastNotification} = useToastNotifications();
    const {setActiveHeaderKey, setActiveSidebarKey, setSportsCenter} = useNavMenu();

    useEffect(() => {
        setActiveHeaderKey('cache')
        setActiveSidebarKey(undefined)
        setSportsCenter(undefined)
        getCacheStatistics()
    }, []);

    const getCacheStatistics = () => {
        setLoading(true);
        setErrorMsg(null);
        getCacheStats()
            .then(({data, headers}) => {
                setLoading(false);
                data.sort((o1: CacheStatistics, o2: CacheStatistics) => o1.name.localeCompare(o2.name))
                setResults(data);
            })
            .catch(({response: {data}}) => {
                setLoading(false);
                setResults([]);
                if (data && data.message) {
                    setErrorMsg(data.message);
                } else {
                    setErrorMsg(translate('errorFetchingResults') + '.');
                }
            })
    }

    const handleClearCache = (cacheName: string) => {
        clearCache(cacheName)
            .finally(() => {
                newToastNotification(translate('clear'), translate('operationSuccessful'));
                getCacheStatistics()
            })
    }

  const handleClearCacheFull = () => {
    clearCacheFull()
        .finally(() => {
          newToastNotification(translate('clear'), translate('operationSuccessful'));
          getCacheStatistics()
        })
  }

    return <>
        {loading && <Splash/>}
        {errorMsg &&
            <Alert variant='danger' style={{fontSize: '0.85rem'}}>
                <div style={{fontWeight: '700'}}>{translate('errorFetchingResults')}</div>
                {errorMsg}
            </Alert>
        }
        <div className='my-3 d-flex justify-content-center'>
            <Button variant='primary'
                    onClick={() => getCacheStatistics()}
            >
                {translate('refresh')}
            </Button>&nbsp;
            <Button variant='danger'
                    onClick={() => handleClearCacheFull()}
            >
                {translate('clearCache')}
            </Button>
        </div>
        <SRTable>
            <thead>
            <tr>
                <th>#</th>
                <th>{translate('name')}</th>
                <th style={{textAlign: 'center'}}>{translate('hits')}</th>
                <th style={{textAlign: 'center'}}>{translate('misses')}</th>
                <th style={{textAlign: 'center'}}>{translate('hitPercentage')}</th>
                <th style={{textAlign: 'center'}}>{translate('missPercentage')}</th>
                <th style={{textAlign: 'center'}}>{translate('gets')}</th>
                <th style={{textAlign: 'center'}}>{translate('averageGetTime')} (ms)</th>
                <th/>
            </tr>
            </thead>
            <tbody>
            {results.map((c, i) =>
                <tr key={i} className='bg-light'>
                    <td>{i + 1}.</td>
                    <td>{c.name}</td>
                    <td style={{textAlign: 'center'}}>{c.hits}</td>
                    <td style={{textAlign: 'center'}}>{c.misses}</td>
                    <td style={{textAlign: 'center'}}>{Math.round((c.hitPercentage ? c.hitPercentage : 0) * 100) / 100}%</td>
                    <td style={{textAlign: 'center'}}>{Math.round((c.missPercentage ? c.missPercentage : 0) * 100) / 100}%</td>
                    <td style={{textAlign: 'center'}}>{c.gets}</td>
                    <td style={{textAlign: 'center'}}>{Math.round((c.averageGetTime ? c.averageGetTime : 0) * 100) / 100}</td>
                    <td style={{textAlign: 'end'}}>
                        <Button size="sm" variant='danger'
                                onClick={() => handleClearCache(c.name)}>{translate('clear')}</Button>
                    </td>
                </tr>
            )}
            </tbody>
        </SRTable>
    </>;
};

export default CacheStatistics;