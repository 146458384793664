import React, { useState } from 'react';
import {Alert, Button, Col, Form, Row} from "react-bootstrap";
import translate from "../../../helpers/translations";
import {allTimezones, useTimezoneSelect} from "react-timezone-select";
import {updateSportsCenter} from "../../../helpers/api";
import {useToastNotifications} from "../../../helpers/notifications";
import Splash from "../../Splash";

interface Props {
    sportsCenterId: number;
    sportsCenter: SportsCenter | null;
    refresh: () => any;
}

interface FormValues {
    id: number | null;
    visible: boolean | null;
    name: string | null;
    description: string | null;
    lat: number | null;
    lng: number | null;
    address: string | null;
    countryId: number | null;
    cityId: number | null;
    neighborhoodId: number | null;
    parking: boolean | null;
    bar: boolean | null;
    paymentMethods: string[];
    rating: number | null;
    stripeAccountId: string | null;
    requiredPhoneNumber: boolean | null;
    reservationEmailNotifications: boolean | null;
    halfHourSlot: boolean | null;
    phoneNumber: string | null;
    website: string | null;
    timeZone: string | null;
    galleryEnabled: boolean | null;
    reservationsGap: number | null;
}

const toForm = (sc: SportsCenter | null): FormValues => {
    return {
        id: sc ? sc.id : null,
        visible: sc ? sc.visible : false,
        name: sc ? sc.name : '',
        description: sc ? sc.description : '',
        lat: sc ? sc.lat : null,
        lng: sc ? sc.lng : null,
        address: sc ? sc.address : '',
        neighborhoodId: (sc && sc.neighborhood) ? sc.neighborhood.id : null,
        cityId: (sc && sc.city) ? sc.city.id : null,
        countryId: (sc && sc.country) ? sc.country.id : null,
        parking: sc ? sc.parking : false,
        bar: sc ? sc.bar : false,
        paymentMethods: ['ON_SITE'],
        rating: sc ? sc.rating : null,
        stripeAccountId: sc ? sc.stripeAccountId : null,
        requiredPhoneNumber: sc ? sc.requiredPhoneNumber : null,
        reservationEmailNotifications: sc ? sc.reservationEmailNotifications : null,
        halfHourSlot: sc ? sc.halfHourSlot : null,
        phoneNumber: sc ? sc.phoneNumber : null,
        website: sc ? sc.website : null,
        timeZone: sc ? sc.timeZone : null,
        galleryEnabled: sc ? sc.galleryEnabled : false,
        reservationsGap: sc ? sc.reservationsGap : null
    }
};

const CenterInformation: React.FC<Props> = ({ sportsCenterId, sportsCenter, refresh }) => {
    const { newToastNotification } = useToastNotifications();
    const { options, parseTimezone } = useTimezoneSelect({ labelStyle: 'original', timezones: allTimezones })
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string | null>(null);
    const [form, setForm] = useState<FormValues>(toForm(sportsCenter));

    const isModified = sportsCenter && (
        sportsCenter.rating !== form.rating ||
        sportsCenter?.stripeAccountId !== form.stripeAccountId ||
        sportsCenter?.timeZone !== form.timeZone ||
        sportsCenter.visible !== form.visible ||
        sportsCenter?.galleryEnabled !== form.galleryEnabled ||
        sportsCenter?.halfHourSlot !== form.halfHourSlot ||
        sportsCenter?.reservationsGap !== form.reservationsGap
    );

    const onSubmit = () => {
        setLoading(true);
        if(!!sportsCenter) {
            updateSportsCenter(sportsCenter.id, form)
                .then(() => {
                    setLoading(false);
                    refresh();
                    newToastNotification(translate('save'), translate('operationSuccessful') + '.');
                })
                .catch(({ response: { data } }) => {
                    setLoading(false);
                    if (data && data.message) {
                        setErrorMsg(data.message);
                    } else {
                        setErrorMsg(translate('unexpectedError') + '.');
                    }
                })
        }
    }

    return <Form className='p-2'>
                {loading && <Splash />}
                {errorMsg &&
                    <Row>
                        <Col>
                            <Alert variant='danger' className='mt-2 shadow-sm' style={{ fontSize: '0.85rem' }}>
                                <div style={{ fontWeight: '700' }}>{errorMsg}</div>
                            </Alert>
                        </Col>
                    </Row>
                }
                <Row>
                    <Col className='mb-2'>
                        <Form.Group>
                            <Form.Label className='text-muted'>{translate('rating')}</Form.Label>
                            <Form.Control type='number' value={form.rating === null ? '' : form.rating} onChange={e => setForm({ ...form, rating: e.target.value === '' ? null : parseFloat(e.target.value) })} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col className='mb-2'>
                        <Form.Group>
                            <Form.Label className='text-muted'>{translate('stripeAccountId')}</Form.Label>
                            <Form.Control value={form.stripeAccountId === null ? '' : form.stripeAccountId} onChange={e => setForm({ ...form, stripeAccountId: e.target.value || null })} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col className='mb-2'>
                        <Form.Group>
                            <Form.Label className='text-muted'>{translate('timeZone')}</Form.Label>
                            <Form.Select
                                value={form.timeZone === null ? '' : form.timeZone}
                                onChange={e => setForm({...form, timeZone: e.target.value})}
                            >
                                <option key='' value=''>{translate('selectCity')}</option>
                                {options.map((option, index) => (
                                    <option value={option.value}>{option.label}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col className='mb-2'>
                        <Form.Group>
                            <Form.Label className='text-muted'>{translate('reservationsGap')}</Form.Label>
                            <Form.Select
                                value={form.reservationsGap === null ? 0 : form.reservationsGap}
                                onChange={e => setForm({...form, reservationsGap: +e.target.value})}
                            >
                                <option key={0} value={0}>{translate('allowReservationsGap')}</option>
                                <option key={30} value={30}>{translate('forbid')} 30 {translate('minutes')}</option>
                                <option key={60} value={60}>{translate('forbid')} 60 {translate('minutes')}</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Form.Label className='text-muted'/>
                    <Col className='mb-2'>
                        <Form.Check // prettier-ignore
                            type="switch"
                            label={translate('visible')}
                            checked={form.visible || false}
                            onChange={e => setForm({ ...form, visible: ((e.target as HTMLInputElement).checked) })}
                        />
                    </Col>
                    <Col className='mb-2'>
                        <Form.Check // prettier-ignore
                            type="switch"
                            label={translate('gallery')}
                            checked={form.galleryEnabled || false}
                            onChange={e => setForm({ ...form, galleryEnabled: ((e.target as HTMLInputElement).checked) })}
                        />
                    </Col>
                    <Col className='mb-2'>
                        <Form.Check // prettier-ignore
                            type="switch"
                            label={translate('halfHourSlot')}
                            checked={form.halfHourSlot || false}
                            onChange={e => setForm({ ...form, halfHourSlot: ((e.target as HTMLInputElement).checked) })}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className='d-flex justify-content-end'>
                            <Button disabled={!(isModified === true)} variant='primary' style={{ width: '8rem' }} onClick={onSubmit}>{translate('save')}</Button>
                        </div>
                    </Col>
                </Row>
            </Form>

}

export default CenterInformation;