import React from 'react';
import {Table} from "react-bootstrap";

interface Props {
    children?: React.ReactNode;
    tableHover?: boolean
}

const SRTable: React.FC<Props> = ({children, tableHover = false}) => {

    const getTableClassName = () => {
        let className = 'm-0 align-middle';
        if (tableHover) {
            className += ' table-hover table-pointer';
        }
        return className;
    };

    return (
        <div className='sr-table my-4 card shadow'>
            <Table className={getTableClassName()} responsive>
                {children}
            </Table>
        </div>
    )
}

export default SRTable;