import * as React from "react"
import { SVGProps } from "react"

const User = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="62"
        height="62"
        fill="none"
        viewBox="0 0 62 62"
        {...props}
    >
        <circle
            cx="31"
            cy="31"
            r="29.12"
            fill="#fff"
            stroke="#fff"
            strokeWidth="1"
        ></circle>
        <g clipPath="url(#clip0_3061_15747)">
            <path
                fill="#313131"
                d="M28.415 31.533c3.87 0 7.025-3.448 7.025-7.643 0-4.164-3.122-7.432-7.025-7.432-3.854 0-7.026 3.334-7.026 7.464.017 4.18 3.172 7.611 7.026 7.611zm0-2.456c-2.375 0-4.407-2.276-4.407-5.155 0-2.83 2-5.009 4.407-5.009 2.44 0 4.407 2.147 4.407 4.977 0 2.878-2 5.188-4.407 5.188zM18.202 46.576h14.262a7.447 7.447 0 01-.163-1.659v-.797h-14.88c-.423 0-.601-.113-.601-.455 0-2.667 4.13-7.66 11.595-7.66 1.805 0 3.431.326 4.797.846.423-.78 1.106-1.431 2.05-1.854-1.936-.878-4.229-1.447-6.847-1.447-8.83 0-14.214 6.163-14.214 10.424 0 1.789 1.285 2.602 4 2.602zm16.327-1.594c0 3.123 1.822 4.261 6.245 6.847.39.244 1.009.293 1.513 0 4.423-2.602 6.26-3.724 6.26-6.847v-5.854c0-.944-.357-1.562-1.316-1.968-.96-.39-3.968-1.464-4.895-1.789a2.483 2.483 0 00-1.74.065c-.797.293-3.806 1.334-4.75 1.724-.959.407-1.317 1.025-1.317 1.968v5.854zm6.034 3.123a1.1 1.1 0 01-.813-.358l-2.765-3.009a1.198 1.198 0 01-.26-.748c0-.569.44-1.024 1.008-1.024.342 0 .602.162.78.358l2.001 2.162 3.968-5.496c.179-.277.488-.456.862-.456.537 0 1.025.44 1.025 1.009 0 .178-.082.406-.228.601l-4.732 6.57c-.18.228-.488.39-.846.39z"
            ></path>
        </g>
        <defs>
            <clipPath id="clip0_3061_15747">
                <path
                    fill="#fff"
                    d="M0 0H34.347V41.564H0z"
                    transform="translate(14.2 10.467)"
                ></path>
            </clipPath>
        </defs>
    </svg>
)
export default User
