import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Container, Form, Modal, Row, Spinner, Table } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import SRTable from '../../../components/SRTable';
import { createTimeOff, getReservationsByDateTimeRange, updateTimeOff } from '../../../helpers/api';
import {
  dateToDateValue,
  dateToTimeValue,
  dateValueToDate, END_OF_DAY,
  formatDateValue,
  timeValueToDate
} from '../../../helpers/date';
import { DAYS_OF_WEEK } from '../../../helpers/enums';
import { useToastNotifications } from '../../../helpers/notifications';
import translate from '../../../helpers/translations';
import './CenterTimeOffModal.css';

interface Props {
  show: boolean;
  timeOff: TimeOff | null;
  sportsCenterId: number;
  openingHours: OpeningHours[];
  onHide: () => any,
  refresh: () => any;
}

interface FormValues {
  from: string;
  to: string;
  reason: string;
}

const CenterTimeOffModal: React.FC<Props> = ({ show, timeOff, sportsCenterId, openingHours, onHide, refresh }) => {
  const { newToastNotification } = useToastNotifications();
  const [form, setForm] = useState<FormValues>({ from: '', to: '', reason: '' });
  const [affectedReservations, setAffectedReservations] = useState<ReservationCourtPerson[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  useEffect(() => {
    setAffectedReservations(null);
    if (timeOff) {
      setForm({ from: timeOff.from, to: timeOff.to, reason: '' });
    } else {
      setForm({ from: '', to: '', reason: '' })
    }
  }, [timeOff]);

  const submit = () => {
    setErrorMsg(null);
    setLoading(true);
    if (affectedReservations === null) {
      getReservationsByDateTimeRange(sportsCenterId, form.from, form.to)
        .then(({ data }) => {
          const affectedReservations = data
            .filter(r => !r.status.startsWith('CANCELLED'))
            .sort((x1, x2) => {
              if (x1.from > x2.from) {
                return 1;
              } else if (x2.from > x1.from) {
                return -1;
              } else {
                return 0;
              }
            });

          if (affectedReservations.length === 0) {
            doSubmit();
          } else {
            setAffectedReservations(affectedReservations);
            setLoading(false);
          }
        })
        .catch(({ response: { data } }) => {
          setLoading(false);
          if (data && data.message) {
            setErrorMsg(data.message);
          } else {
            setErrorMsg(translate('unexpectedError') + '.');
          }
          setLoading(false);
        });
    } else {
      doSubmit();
    }
  }

  const doSubmit = () => {
    (timeOff ? updateTimeOff(sportsCenterId, timeOff.id, form) : createTimeOff(sportsCenterId, form))
      .then(() => {
        setLoading(false);
        onHide();
        refresh();
        newToastNotification(translate(timeOff ? 'editTimeOff' : 'addTimeOff'), translate('operationSuccessful'));
      })
      .catch(({ response: { data } }) => {
        setLoading(false);
        if (data && data.message) {
          setErrorMsg(data.message);
        } else {
          setErrorMsg(translate('unexpectedError'));
        }
      })
  }

  const isAllowedDate = (date: Date) => {
    const dayOfWeek = DAYS_OF_WEEK[(date.getDay() + 6) % 7];
    return openingHours.some(oh => oh.dayOfWeek === dayOfWeek);
  }

  const isAllowedDatetime = (datetime: Date) => {
    const dayOfWeek = DAYS_OF_WEEK[(datetime.getDay() + 6) % 7];
    const timeString = dateToTimeValue(datetime);
    return openingHours.some(oh => oh.dayOfWeek === dayOfWeek && timeString >= oh.from && timeString <= oh.to);
  }

  const atAllowedTime = (date: Date) => {
    const dayOfWeek = DAYS_OF_WEEK[(date.getDay() + 6) % 7];
    const openingHoursAtDayOfWeek = openingHours.filter(oh => oh.dayOfWeek === dayOfWeek);
    const timeString = dateToTimeValue(date);
    if (openingHoursAtDayOfWeek.some(oh => timeString >= oh.from && timeString <= oh.to)) {
      return date;
    } else {
      const timeParts = openingHoursAtDayOfWeek[0].from.split(":");
      date.setHours(parseInt(timeParts[0]));
      date.setMinutes(parseInt(timeParts[1]));
      return date;
    }
  }

  return <Modal show={show} dialogClassName={affectedReservations && affectedReservations.length > 0 ? 'add-time-off-wide-modal' : ''}>
    <Modal.Header><strong>{translate(timeOff ? 'editTimeOff' : 'addTimeOff')}</strong></Modal.Header>
    <Modal.Body>
      {affectedReservations === null &&
        <Form>
          <Container>
            {errorMsg && <Row>
              <Col>
                <Alert variant='danger' className='mt-2' style={{ fontSize: '0.85rem' }}>
                  <div style={{ fontWeight: '700' }}>{errorMsg}</div>
                </Alert>
              </Col>
            </Row>}
            <Row>
              <Col xs={6}>
                <DatePicker
                  placeholderText={translate('from')}
                  selected={form.from !== '' ? dateValueToDate(form.from) : null}
                  onChange={date => setForm({ ...form, from: date ? dateToDateValue(atAllowedTime(date)) : '' })}
                  showTimeSelect
                  filterDate={isAllowedDate}
                  filterTime={isAllowedDatetime}
                  timeFormat='HH:mm'
                  timeIntervals={30}
                  dateFormat='dd.MM.yyyy. HH:mm'
                  customInput={<Form.Control />}
                />
              </Col>
              <Col xs={6}>
                <DatePicker
                  placeholderText={translate('to')}
                  selected={form.to !== '' ? dateValueToDate(form.to) : null}
                  onChange={date => setForm({ ...form, to: date ? dateToDateValue(atAllowedTime(date)) : '' })}
                  showTimeSelect
                  filterDate={isAllowedDate}
                  filterTime={isAllowedDatetime}
                  timeFormat='HH:mm'
                  timeIntervals={30}
                  injectTimes={[END_OF_DAY]}
                  dateFormat='dd.MM.yyyy. HH:mm'
                  customInput={<Form.Control />}
                />
              </Col>
            </Row>
          </Container>
        </Form>}
      {affectedReservations !== null && <div>
        {errorMsg && <Row>
          <Col>
            <Alert variant='danger' style={{ fontSize: '0.85rem' }}>
              <div style={{ fontWeight: '700' }}>{errorMsg}</div>
            </Alert>
          </Col>
        </Row>}
        <div>{translate('reservationsAlreadyScheduled')}.</div>
        <div>{translate('proceedAddingTimeOff')}?</div>
        <div className="add-time-off-wide-modal-body">
            <SRTable>
              <thead>
                <tr>
                  <th>{translate('from')}</th>
                  <th>{translate('to')}</th>
                  <th>{translate('price')}</th>
                  <th>{translate('court')}</th>
                  <th>{translate('personOrEmail')}</th>
                </tr>
              </thead>
              <tbody>
                {affectedReservations
                  .map(ar => <tr key={ar.id}>
                    <td>{formatDateValue(ar.from)}</td>
                    <td>{formatDateValue(ar.to)}</td>
                    <td>{ar.price} {ar.currency}</td>
                    <td>{ar.court.name}</td>
                    {ar.person && <td>
                      {ar.person.firstName && ar.person.lastName && <div>{ar.person.firstName} {ar.person.lastName}</div>}
                      <div>{ar.person.email}</div>
                      {ar.person.phoneNumber && <div>{ar.person.phoneNumber}</div>}
                    </td>}
                    {!ar.person && <td>{ar.email || '-'}</td>}
                  </tr>)}
              </tbody>
            </SRTable>
        </div>
        <Form className='p-2'>
          <Form.Control as='textarea' placeholder={translate('addCancellationReason')} rows={3} value={form.reason} onChange={e => setForm({ ...form, reason: e.target.value })} />
        </Form>
      </div>}
    </Modal.Body>
    <Modal.Footer>
      {loading && <Spinner animation='border' variant='secondary' className='mx-auto' />}
      {!loading && <>
        <Button variant='secondary' onClick={() => { onHide(); setErrorMsg(null); setAffectedReservations(null); }}>{translate('close')}</Button>
        <Button variant='primary' onClick={() => submit()}>{translate(affectedReservations && affectedReservations.length > 0 ? 'proceed' : 'save')}</Button>
      </>}
    </Modal.Footer>
  </Modal>
}

export default CenterTimeOffModal;