import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Container, Form, Modal, Row, Spinner } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { createRecurringReservation } from '../../../helpers/api';
import { dateToDateValue, dateValueToDate, dateToTimeValue, timeValueToDate, END_OF_DAY } from '../../../helpers/date';
import { DAYS_OF_WEEK } from '../../../helpers/enums';
import { useToastNotifications } from '../../../helpers/notifications';
import Select from 'react-select';
import translate from '../../../helpers/translations';

interface Props {
  recurringReservation: RecurringReservation | null;
  sportsCenterId: number;
  courts: Court[];
  openingHours: OpeningHours[];
  halfHourSlot: boolean;
  onHide: () => any;
  refresh: () => any;
}

interface FormValues {
  id: number | null;
  paymentMethod: string;
  courtId: number | null;
  sport: string | null;
  start: string | null;
  end: string | null;
  daysOfWeek: string[];
  from: string | null;
  to: string | null;
  description: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  repeatEvery: number;
  sportsCenterId: number;
  paymentSuccessful: boolean;
}

const emptyForm = (sportsCenterId: number) => ({
  id: null,
  paymentMethod: 'ON_SITE',
  courtId: null,
  sport: null,
  start: null,
  end: null,
  daysOfWeek: [],
  from: null,
  to: null,
  description: '',
  email: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  repeatEvery: 1,
  sportsCenterId: sportsCenterId,
  paymentSuccessful: false
});

const mapForm = (sportsCenterId: number, reservation: RecurringReservation | null) => {
  if(!reservation) return emptyForm(sportsCenterId)
  return {
    id: reservation.id ? reservation.id : null,
    paymentMethod: 'ON_SITE',
    courtId: reservation.court ? reservation.court.id : null,
    sport: reservation.sport ? reservation.sport: null,
    start: reservation.startDate ? reservation.startDate : null,
    end: reservation.endDate ? reservation.endDate : null,
    daysOfWeek: reservation.days ? reservation.days.map(d => DAYS_OF_WEEK[d]) : [],
    from: reservation.fromTime ? reservation.fromTime : null,
    to: reservation.toTime ? reservation.toTime : null,
    description: '',
    email: reservation.email ? reservation.email : '',
    firstName: reservation.firstName ? reservation.firstName : '',
    lastName: reservation.lastName ? reservation.lastName : '',
    phoneNumber: reservation.phoneNumber ? reservation.phoneNumber : '',
    repeatEvery: reservation.recurringEveryWeeks ? reservation.recurringEveryWeeks : 1,
    sportsCenterId: sportsCenterId,
    paymentSuccessful: reservation.paymentSuccessful ? reservation.paymentSuccessful : false
  }
}

const RecurringReservationModal: React.FC<Props> = ({ recurringReservation, sportsCenterId, halfHourSlot, courts, openingHours, onHide, refresh }) => {
  const { newToastNotification } = useToastNotifications();
  const [availableSports, setAvailableSports] = useState<Sport[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [form, setForm] = useState<FormValues>(emptyForm(sportsCenterId));


  const submit = () => {
    setErrorMsg(null);
    setLoading(true);
    createRecurringReservation(form)
      .then(({ data }) => {
        setLoading(false);
        onHide();
        refresh();
        newToastNotification(translate('addRecurringReservation'), translate('addedReservations').replaceAll('?1', `${data}`));
        setForm(emptyForm(sportsCenterId));
      })
      .catch(({ response: { data } }) => {
        setLoading(false);
        if (data && data.message) {
          setErrorMsg(data.message);
        } else {
          setErrorMsg(translate('unexpectedError') + '.');
        }
      })
  }

  useEffect(() => {
    setForm(mapForm(sportsCenterId, recurringReservation))
  }, [sportsCenterId, recurringReservation]);

  useEffect(() => {
    const court = courts.find(c => c.id === form.courtId);
    const availableSports = court ? court.sports : [];
    if (availableSports.length === 1 && form.sport !== availableSports[0]) {
      setForm({ ...form, sport: availableSports[0] });
    }
    setAvailableSports(availableSports);
  }, [courts, form]);

  return <Modal show>
    <Modal.Header>
      <strong>{translate('addRecurringReservation')}</strong>
    </Modal.Header>
    <Modal.Body>
      <Form>
        <Container>
          {errorMsg && <Row>
            <Col>
              <Alert variant='danger' className='mt-2' style={{ fontSize: '0.85rem' }}>
                <div style={{ fontWeight: '700' }}>{errorMsg}</div>
              </Alert>
            </Col>
          </Row>}
          <Row className='mt-2'>
            <Col>
              <Form.Select value={form.courtId === null ? '' : form.courtId} onChange={e => setForm({ ...form, courtId: e.target.value === '' ? null : parseInt(e.target.value), sport: null })}>
                <option key='' value=''>{translate('selectCourt')}</option>
                {courts.map(c => <option key={c.id} value={c.id}>{c.name}</option>)}
              </Form.Select>
            </Col>
          </Row>
          <Row className='mt-2'>
            <Col>
              {availableSports.length === 1 && <Form.Control disabled value={translate(availableSports[0])} />}
              {availableSports.length !== 1 && <Form.Select value={form.sport === null ? '' : form.sport} onChange={e => setForm({ ...form, sport: e.target.value === '' ? null : e.target.value })}>
                <option key='' value=''>{translate('selectSport')}</option>
                {availableSports.map(s => <option key={s} value={s}>{translate(s)}</option>)}
              </Form.Select>}
            </Col>
          </Row>
          <Row className='mt-2'>
            <Col xs={12}>
              <Form.Label>{translate('dateRange')}</Form.Label>
            </Col>
            <Col sm={6}>
              <DatePicker
                placeholderText={translate('start')}
                selected={form.start ? dateValueToDate(form.start) : null}
                onChange={date => setForm({ ...form, start: date ? dateToDateValue(date).substring(0, 10) : '' })}
                dateFormat='dd.MM.yyyy.'
                timeFormat='dd.MM.yyyy.'
                customInput={<Form.Control />}
              />
            </Col>
            <Col sm={6}>
              <DatePicker
                placeholderText={translate('end')}
                selected={form.end ? dateValueToDate(form.end) : null}
                onChange={date => setForm({ ...form, end: date ? dateToDateValue(date).substring(0, 10) : '' })}
                dateFormat='dd.MM.yyyy.'
                timeFormat='dd.MM.yyyy.'
                customInput={<Form.Control />}
              />
            </Col>
          </Row>
          <Row className='mt-2'>
            <Col xs={12}>
              <Form.Label>{translate('reservationTime')}</Form.Label>
            </Col>
            <Col>
              <Select
                isMulti
                placeholder={translate('selectDaysOfWeek')}
                options={DAYS_OF_WEEK.map(dow => ({label: translate(dow), value: dow}))}
                value={form.daysOfWeek.map(dow => ({label: translate(dow), value: dow}))}
                onChange={selected => setForm({...form, daysOfWeek: selected.map(s => s.value), from: null, to: null})}
                />
            </Col>
          </Row>
          <Row className='mt-2'>
            <Col sm={6}>
              <DatePicker
                placeholderText={translate('from')}
                selected={form.from ? timeValueToDate(form.from) : null}
                onChange={date => setForm({ ...form, from: date && dateToTimeValue(date) })}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={halfHourSlot ? 30 : 60}
                dateFormat='HH:mm'
                timeFormat='HH:mm'
                filterTime={time => {
                  const timeString = dateToTimeValue(time);
                  return form.daysOfWeek.every(dow => openingHours.some(oh => oh.dayOfWeek === dow && timeString >= oh.from && timeString <= oh.to));
                }}
                customInput={<Form.Control />}
              />
            </Col>
            <Col sm={6}>
              <DatePicker
                placeholderText={translate('to')}
                selected={form.to ? timeValueToDate(form.to) : null}
                onChange={date => setForm({ ...form, to: date && dateToTimeValue(date) })}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={halfHourSlot ? 30 : 60}
                dateFormat='HH:mm'
                timeFormat='HH:mm'
                filterTime={time => {
                  const timeString = dateToTimeValue(time);
                  return form.daysOfWeek.every(dow => openingHours.some(oh => oh.dayOfWeek === dow && timeString >= oh.from && timeString <= oh.to));
                }}
                injectTimes={[END_OF_DAY]}
                customInput={<Form.Control />}
              />
            </Col>
          </Row>
          <Row className='mt-2'>
            <Col xs={12}>
              <Form.Label>{translate('repetition')}</Form.Label>
            </Col>
            <Col xs={12}>
              {
                [1, 2, 3].map(value => (
                    <Form.Check
                        key={value}
                        value={value}
                        type="radio"
                        label={translate("everyWeek" + value)}
                        onChange={() => setForm({...form, repeatEvery: value})}
                        checked={form.repeatEvery === value}
                    />
                ))
              }
            </Col>
          </Row>
          {/* <Row className='mt-2'>
            <Col>
              <Form.Label>Description</Form.Label>
              <Form.Control as='textarea' placeholder='Add your description here' rows={3} value={form.description} onChange={e => setForm({ ...form, description: e.target.value })} />
            </Col>
          </Row> */}
          <Row className='mt-2'>
            <Col>
              <Form.Label>{translate('email')}</Form.Label>
              <Form.Control value={form ? form.email : ''} onChange={e => form && setForm({ ...form, email: e.target.value })} />
            </Col>
          </Row>
          <Row className='mt-2'>
            <Col>
              <Form.Label>{translate('firstName')}</Form.Label>
              <Form.Control value={form ? form.firstName : ''} onChange={e => form && setForm({ ...form, firstName: e.target.value })} />
            </Col>
          </Row>
          <Row className='mt-2'>
            <Col>
              <Form.Label>{translate('lastName')}</Form.Label>
              <Form.Control value={form ? form.lastName : ''} onChange={e => form && setForm({ ...form, lastName: e.target.value })} />
            </Col>
          </Row>
          <Row className='mt-2'>
            <Col>
              <Form.Label>{translate('phoneNumber')}</Form.Label>
              <Form.Control value={form ? form.phoneNumber : ''} onChange={e => form && setForm({ ...form, phoneNumber: e.target.value })} />
            </Col>
          </Row>
          <Row className='mt-2'>
            <Col md={12} className='my-2 d-flex'>
              <Form.Label>{translate('paymentSuccessful')}&nbsp;&nbsp;</Form.Label>
              <Form.Check checked={form.paymentSuccessful} onChange={e => form && setForm({ ...form, paymentSuccessful: ((e.target as HTMLInputElement).checked) })} />
            </Col>
          </Row>
        </Container>
      </Form>
    </Modal.Body>
    <Modal.Footer>
      {loading && <Spinner animation='border' variant='secondary' className='mx-auto' />}
      {!loading && <>
        <Button variant='secondary' onClick={() => onHide()}>{translate('close')}</Button>
        <Button variant='primary' onClick={() => submit()}>{translate('save')}</Button>
      </>}
    </Modal.Footer>
  </Modal>;
};

export default RecurringReservationModal;