import React, {useEffect, useState} from 'react';
import {Alert, Button, Card, Col, Form, Row,} from 'react-bootstrap';
import translate from '../../../helpers/translations';
import { ChromePicker } from 'react-color';
import {saveReservationColors} from "../../../helpers/api";

interface Props {
  sportsCenterId: number;
  reservationColors?: IReservationColors;
  refresh: () => any;
}

const defaultReservationColors = {
    customColorEnabled: false,
    fromAdminBackgroundColor: "#6C757D",
    fromAdminTextColor: "#FFFFFF",
    fromAppBackgroundColor: "#6C757D",
    fromAppTextColor: "#FFFFFF",
    recurringBackgroundColor: "#6C757D",
    recurringTextColor: "#FFFFFF"
}

const ReservationColors: React.FC<Props> = ({ reservationColors, sportsCenterId, refresh }) => {
  const [form, setForm] = useState<IReservationColors>(defaultReservationColors);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  useEffect(() => {
    setForm(reservationColors ? reservationColors : defaultReservationColors)
  }, [reservationColors]);

  const checkIsModified = () => {
    if(form.customColorEnabled) {
      if(!reservationColors) return true;
      if(
          form.fromAdminBackgroundColor != reservationColors.fromAdminBackgroundColor ||
          form.fromAdminTextColor != reservationColors.fromAdminTextColor ||
          form.fromAppBackgroundColor != reservationColors.fromAppBackgroundColor ||
          form.fromAppTextColor != reservationColors.fromAppTextColor ||
          form.recurringBackgroundColor != reservationColors.recurringBackgroundColor ||
          form.recurringTextColor != reservationColors.recurringTextColor
      ) return true;
    }
    return reservationColors && form.customColorEnabled != reservationColors.customColorEnabled;
  }

  const handleSave = () => {
    saveReservationColors(sportsCenterId, form)
        .then(() => refresh())
        .catch(({ response: { data } }) => {
          if (data && data.message) {
            setErrorMsg(data.message);
          } else {
            setErrorMsg(translate('unexpectedError') + '.');
          }
        })
  }

    return <div className='d-flex flex-column'>
    <>
      {errorMsg &&
          <Row>
            <Col>
              <Alert variant='danger' className='mt-2' style={{ fontSize: '0.85rem' }}>
                <div style={{ fontWeight: '700' }}>{errorMsg}</div>
              </Alert>
            </Col>
          </Row>
      }
      <Alert variant="primary" className='px-3 mt-4 mb-0 shadow-sm' style={{fontWeight: 'bold', color:'inherit'}}>
          <Form.Check // prettier-ignore
              type="switch"
              label={translate('enableCustomColor')}
              checked={form.customColorEnabled}
              onChange={() => setForm({...form, customColorEnabled: !form.customColorEnabled})}
          />
      </Alert>
      <Alert style={{backgroundColor: '#DCDCDC'}} variant='secondary' className="fw-bold px-3 mt-4 mb-0 shadow-sm">{translate('reservationsFromAdmin')}</Alert>
      <Row className='p-3 px-3'>
        <Col sm={4} className='text-center'>
          <div className='d-flex flex-column mb-2'>
            <div className='text-center fw-bold mb-2'>{translate('backgroundColor')}</div>
            <div style={{width: '100%', maxWidth: '225px', alignSelf: 'center'}}>
              <ChromePicker disableAlpha
                            color={form.fromAdminBackgroundColor}
                            onChange={e => form.customColorEnabled && setForm({
                              ...form,
                              fromAdminBackgroundColor: e.hex
                            })}
                            styles={{default: {picker: {width: '100%'}}}}
              />
            </div>
          </div>
        </Col>
        <Col sm={4} className='text-center'>
        <div className='d-flex flex-column mb-2'>
            <div className='text-center fw-bold mb-2'>{translate('textColor')}</div>
            <div style={{width: '100%', maxWidth: '225px', alignSelf: 'center'}}>
              <ChromePicker disableAlpha
                            color={form.fromAdminTextColor}
                            onChange={e => form.customColorEnabled && setForm({...form, fromAdminTextColor: e.hex})}
                            styles={{default: {picker: {width: '100%'}}}}
              />
            </div>
          </div>
        </Col>
        <Col sm={4} className='text-center'>
        <div className='d-flex flex-column mb-2'>
            <div className='text-center fw-bold mb-2'>{translate('preview')}</div>
            <div>
              <Button className="shadow" size='sm' variant='secondary'
                      style={{
                        width: '100%', maxWidth: '225px', height: '35px',
                        backgroundColor: form.customColorEnabled ? form.fromAdminBackgroundColor : defaultReservationColors.fromAdminBackgroundColor,
                        color: form.customColorEnabled ? form.fromAdminTextColor : defaultReservationColors.fromAdminTextColor
              }}>
                <Card.Body className='d-flex justify-content-center align-items-center' style={{fontSize: '12px', lineHeight: '12px'}}>
                  Jane Doe
                </Card.Body>
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <Alert style={{backgroundColor: '#DCDCDC'}} variant='secondary' className="fw-bold px-3 mt-4 mb-0 shadow-sm">{translate('reservationsFromApp')}</Alert>
      <Row className='p-3 px-3'>
        <Col sm={4} className='text-center'>
          <div className='d-flex flex-column mb-2'>
            <div className='text-center fw-bold mb-2'>{translate('backgroundColor')}</div>
            <div style={{width: '100%', maxWidth: '225px', alignSelf: 'center'}}>
              <ChromePicker disableAlpha
                            color={form.fromAppBackgroundColor}
                            onChange={e => form.customColorEnabled && setForm({...form, fromAppBackgroundColor: e.hex})}
                            styles={{default: {picker: {width: '100%'}}}}
              />
            </div>
          </div>
        </Col>
        <Col sm={4} className='text-center'>
        <div className='d-flex flex-column mb-2'>
            <div className='text-center fw-bold mb-2'>{translate('textColor')}</div>
            <div style={{width: '100%', maxWidth: '225px', alignSelf: 'center'}}>
              <ChromePicker disableAlpha
                            color={form.fromAppTextColor}
                            onChange={e => form.customColorEnabled && setForm({...form, fromAppTextColor: e.hex})}
                            styles={{default: {picker: {width: '100%'}}}}
              />
            </div>
          </div>
        </Col>
        <Col sm={4} className='text-center'>
        <div className='d-flex flex-column mb-2'>
            <div className='text-center fw-bold mb-2'>{translate('preview')}</div>
            <div>
              <Button className="shadow" size='sm' variant='secondary'
                      style={{
                        width: '100%', maxWidth: '225px', height: '35px',
                        backgroundColor: form.customColorEnabled ? form.fromAppBackgroundColor : defaultReservationColors.fromAppBackgroundColor,
                        color: form.customColorEnabled ? form.fromAppTextColor : defaultReservationColors.fromAdminTextColor
              }}>
                <Card.Body className='d-flex justify-content-center align-items-center' style={{fontSize: '12px', lineHeight: '12px'}}>
                    Jane Doe
                </Card.Body>
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <Alert style={{backgroundColor: '#DCDCDC'}} variant='secondary' className="fw-bold px-3 mt-4 mb-0 shadow-sm">{translate('recurringReservations')}</Alert>
      <Row className='p-3 px-3'>
        <Col sm={4} className='text-center'>
          <div className='d-flex flex-column mb-2'>
            <div className='text-center fw-bold mb-2'>{translate('backgroundColor')}</div>
            <div style={{width: '100%', maxWidth: '225px', alignSelf: 'center'}}>
              <ChromePicker disableAlpha
                            color={form.recurringBackgroundColor}
                            onChange={e => form.customColorEnabled && setForm({
                              ...form,
                              recurringBackgroundColor: e.hex
                            })}
                            styles={{default: {picker: {width: '100%'}}}}
              />
            </div>
          </div>
        </Col>
        <Col sm={4} className='text-center'>
          <div className='d-flex flex-column mb-2'>
            <div className='text-center fw-bold mb-2'>{translate('textColor')}</div>
            <div style={{width: '100%', maxWidth: '225px', alignSelf: 'center'}}>
              <ChromePicker disableAlpha
                            color={form.recurringTextColor}
                            onChange={e => form.customColorEnabled && setForm({...form, recurringTextColor: e.hex})}
                            styles={{default: {picker: {width: '100%'}}}}
              />
            </div>
          </div>
        </Col>
        <Col sm={4} className='text-center'>
        <div className='d-flex flex-column mb-2'>
            <div className='text-center fw-bold mb-2'>{translate('preview')}</div>
            <div>
              <Button className="shadow" size='sm' variant='secondary'
                      style={{
                        width: '100%', maxWidth: '225px', height: '35px',
                        backgroundColor: form.customColorEnabled ? form.recurringBackgroundColor : defaultReservationColors.recurringBackgroundColor,
                        color: form.customColorEnabled ? form.recurringTextColor : defaultReservationColors.recurringTextColor
              }}>
                <Card.Body className='d-flex justify-content-center align-items-center' style={{fontSize: '12px', lineHeight: '12px'}}>
                    Jane Doe
                </Card.Body>
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className='d-flex justify-content-end mb-5'>
            <Button onClick={handleSave} disabled={!checkIsModified()} variant='primary' style={{ width: '8rem' }} >{translate('save')}</Button>
          </div>
        </Col>
      </Row>
    </>
  </div>;
}

export default ReservationColors;