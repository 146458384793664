import { useState, useContext, createContext } from 'react';

interface ToastNotificationsContextValue {
  nextId: number;
  notifications: ToastNotification[];
  newToastNotification: (header: string, body: string, variant?: string) => any;
  close: (id: number) => any;
}

const ToastNotificationsContext = createContext<ToastNotificationsContextValue>({nextId: 0, notifications: [], newToastNotification: () => {}, close: () => {}});

export const ToastNotificationsProvider: React.FC<{ children: any }> = ({ children }) => {
  const [nextId, setNextId] = useState<number>(0);
  const [notifications, setNotifications] = useState<ToastNotification[]>([]);

  const newToastNotification = (header: string, body: string, variant?: string) => {
    setNextId(nextId + 1);
    setNotifications([...notifications, {id: nextId, header, body, variant}]);
  };

  const close = (id: number) => {
    setNotifications(notifications.filter(n => n.id !== id));
  }

  return (
    <ToastNotificationsContext.Provider value={{nextId, notifications, newToastNotification, close}}>
      {children}
    </ToastNotificationsContext.Provider>
  );
};

export const useToastNotifications = () => useContext(ToastNotificationsContext);