import * as React from "react"
import { SVGProps } from "react"

const CalendarClock = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="35"
        height="29"
        fill="none"
        viewBox="0 0 35 29"
        {...props}
    >
        <path
            fill="#313131"
            d="M0 21.795C0 24.818 1.596 26.4 4.647 26.4H19.38a7.878 7.878 0 01-1.06-2.81H4.633c-1.172 0-1.822-.608-1.822-1.851V8.744c0-1.243.65-1.85 1.822-1.85h19.479c1.172 0 1.822.607 1.822 1.85v3.785c.494-.085.96-.127 1.427-.127.466 0 .932.042 1.398.113v-7.91C28.76 1.582 27.163 0 24.098 0H4.648C1.595 0 0 1.568 0 4.605v17.19zm11.653-10.029h.834c.508 0 .678-.155.678-.65v-.833c0-.508-.17-.664-.678-.664h-.834c-.508 0-.678.156-.678.664v.834c0 .494.17.65.678.65zm4.62 0h.833c.494 0 .664-.155.664-.65v-.833c0-.508-.17-.664-.664-.664h-.834c-.508 0-.678.156-.678.664v.834c0 .494.17.65.678.65zm4.604 0h.834c.508 0 .678-.155.678-.65v-.833c0-.508-.17-.664-.678-.664h-.834c-.494 0-.664.156-.664.664v.834c0 .494.17.65.664.65zM7.05 16.315h.819c.508 0 .678-.156.678-.664v-.834c0-.494-.17-.65-.678-.65h-.82c-.508 0-.678.156-.678.65v.834c0 .508.17.664.679.664zm4.604 0h.834c.508 0 .678-.156.678-.664v-.834c0-.494-.17-.65-.678-.65h-.834c-.508 0-.678.156-.678.65v.834c0 .508.17.664.678.664zm4.62 0h.833c.494 0 .664-.156.664-.664v-.834c0-.494-.17-.65-.664-.65h-.834c-.508 0-.678.156-.678.65v.834c0 .508.17.664.678.664zM27.36 28.97c3.955 0 7.288-3.305 7.288-7.289 0-3.983-3.29-7.274-7.288-7.274-3.984 0-7.275 3.291-7.275 7.274 0 3.998 3.291 7.289 7.275 7.289zm-3.362-6.074a.998.998 0 01-1.003-1.003c0-.537.452-.974 1.003-.974h2.458v-3.306a.97.97 0 01.988-.974c.551 0 1.003.423 1.003.974v4.28a.989.989 0 01-1.003 1.003H24zM7.049 20.85h.819c.508 0 .678-.155.678-.65v-.833c0-.509-.17-.664-.678-.664h-.82c-.508 0-.678.155-.678.664v.833c0 .494.17.65.679.65zm4.604 0h.834c.508 0 .678-.155.678-.65v-.833c0-.509-.17-.664-.678-.664h-.834c-.508 0-.678.155-.678.664v.833c0 .494.17.65.678.65zm4.62 0h.833c.494 0 .664-.155.664-.65v-.833c0-.509-.17-.664-.664-.664h-.834c-.508 0-.678.155-.678.664v.833c0 .494.17.65.678.65z"
        ></path>
    </svg>
)
export default CalendarClock
