import React, { useEffect, useState } from 'react';
import { Alert, Button, Table } from 'react-bootstrap';
import ConfirmationModal from '../../../components/ConfirmationModal';
import SRTable from '../../../components/SRTable';
import { deleteOffer } from '../../../helpers/api';
import { openingHoursSorter } from '../../../helpers/date';
import { useToastNotifications } from '../../../helpers/notifications';
import translate from '../../../helpers/translations';
import CenterOfferModal from './CenterOfferModal';

interface Props {
  sportsCenterId: number;
  offers: Offer[];
  courts: Court[];
  openingHours: OpeningHours[];
  refresh: () => any;
}

const CenterOffers: React.FC<Props> = ({ sportsCenterId, offers, courts, openingHours, refresh }) => {
  const { newToastNotification } = useToastNotifications();
  const [uniqueOffers, setUniqueOffers] = useState<Offer[]>([]);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [deletingId, setDeletingId] = useState<number | null>(null);
  const [deleteInProgress, setDeleteInProgress] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [editingOffer, setEditingOffer] = useState<Offer | null>(null);

  useEffect(() => {
    const uniqueOffers: {[key: number]: Offer} = {};
    offers.forEach(offer => {
      const previous = uniqueOffers[offer.id];
      if (previous) {
        offer.courts.forEach(court => {
          if (!previous.courts.some(c => c.id === court.id)) {
            previous.courts.push(court);
          }
        });
      } else {
        uniqueOffers[offer.id] = offer;
      }
    });
    setUniqueOffers(Object.values(uniqueOffers));
  }, [offers]);

  const doDelete = () => {
    if (deletingId) {
      setErrorMsg(null);
      setDeleteInProgress(true);
      deleteOffer(sportsCenterId, deletingId)
        .then(() => {
          setDeleteInProgress(false);
          setDeletingId(null);
          refresh();
          newToastNotification(translate('deleteOffer'), translate('operationSuccessful') + '.')
        })
        .catch(({ response: { data } }) => {
          setDeleteInProgress(false);
          if (data && data.message) {
            setErrorMsg(data.message);
          } else {
            setErrorMsg(translate('unexpectedError') + '.');
          }
        });
    }
  }

  return <div className='d-flex flex-column'>
    {offers.length !== 0 && <>
      {errorMsg && <Alert variant='danger' className='mt-2' style={{ fontSize: '0.85rem' }}>
        <div style={{ fontWeight: '700' }}>{errorMsg}</div>
      </Alert>}
        <SRTable>
          <thead>
            <tr>
              <th>{translate('id')}</th>
              <th style={{textAlign: 'center'}}>{translate('pricePerHour')}</th>
              <th style={{textAlign: 'center'}}>{translate('courts')}</th>
              <th style={{textAlign: 'center'}}>{translate('sport')}</th>
              <th style={{textAlign: 'center'}}>{translate('hours')}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {uniqueOffers.length !== 0 &&
              uniqueOffers
                .sort((o1, o2) => o1.id - o2.id)
                .map(o => <tr key={o.id}>
                <td>{o.id}</td>
                <td style={{textAlign: 'center'}}>{o.price} {o.currency}</td>
                <td style={{textAlign: 'center'}}>{o.courts.map(c => <div key={c.id}>{c.name}</div>)}</td>
                <td style={{textAlign: 'center'}}>{translate(o.sport)}</td>
                <td style={{textAlign: 'center'}}>{
                  o.hours
                    .sort(openingHoursSorter)
                    .map(oh => <div key={oh.id} style={{ whiteSpace: 'nowrap' }}>{translate(oh.dayOfWeek.substring(0, 3))} {oh.from}-{oh.to}</div>)}</td>
                <td className='text-end' style={{whiteSpace: 'nowrap'}}>
                  <Button size="sm" variant='secondary' className='mx-1' onClick={() => { setEditingOffer(o); setShowEditModal(true); }}>{translate('edit')}</Button>
                  <Button size="sm" variant='danger' className='mx-1' onClick={() => setDeletingId(o.id)}>{translate('delete')}</Button>
                </td>
              </tr>)}
          </tbody>
        </SRTable>
    </>}
    {uniqueOffers.length === 0 && <h4 className='text-muted my-5 text-center'>{translate('noOffersAvailable')}</h4>}
    <Button className='mx-auto px-5' variant='secondary' onClick={() => { setEditingOffer(null); setShowEditModal(true); }}>{translate('addOffer')}</Button>
    {deletingId && <ConfirmationModal
      title={translate('confirmDeleting')}
      body={<>
        {errorMsg && <Alert variant='danger' style={{ fontSize: '0.85rem' }}>
          <div style={{ fontWeight: '700' }}>{errorMsg}</div>
        </Alert>}
        {translate('sureToDeleteOffer')}?
      </>}
      confirmButtonVariant='danger'
      inProgress={deleteInProgress}
      onConfirm={doDelete}
      onCancel={() => {
        setDeletingId(null);
        setDeleteInProgress(false);
      }}
    />}
    <CenterOfferModal
      show={showEditModal}
      offer={editingOffer}
      sportsCenterId={sportsCenterId}
      courts={courts}
      openingHours={openingHours}
      onHide={() => setShowEditModal(false)}
      refresh={refresh} />
  </div>;
}

export default CenterOffers;