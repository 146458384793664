import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import {createSportsCenter, getSportsCenter, updateSportsCenter} from '../../../helpers/api';
import { useAuth } from '../../../helpers/auth';
import { useToastNotifications } from '../../../helpers/notifications';
import translate from '../../../helpers/translations';
import Splash from '../../Splash';
import { useTimezoneSelect, allTimezones } from 'react-timezone-select'
import {useConfiguration} from "../../../helpers/configuration";
import Configuration from "../Configuration/Configuration";

interface Props {
  id?: number;
  sportsCenter?: SportsCenter;
  refresh?: () => any;
}

interface FormValues {
  id: number | null;
  visible: boolean | null;
  name: string | null;
  description: string | null;
  lat: number | null;
  lng: number | null;
  address: string | null;
  countryId: number | null;
  cityId: number | null;
  neighborhoodId: number | null;
  parking: boolean | null;
  bar: boolean | null;
  paymentMethods: string[];
  rating: number | null;
  stripeAccountId: string | null;
  requiredPhoneNumber: boolean | null;
  reservationEmailNotifications: boolean | null;
  halfHourSlot: boolean | null;
  phoneNumber: string | null;
  website: string | null;
  timeZone: string | null;
  galleryEnabled: boolean | null;
  reservationsGap: number | null;
}

const toForm = (sc: SportsCenter | undefined): FormValues => {
  return {
    id: sc ? sc.id : null,
    visible: sc ? sc.visible : false,
    name: sc ? sc.name : '',
    description: sc ? sc.description : '',
    lat: sc ? sc.lat : null,
    lng: sc ? sc.lng : null,
    address: sc ? sc.address : '',
    neighborhoodId: (sc && sc.neighborhood) ? sc.neighborhood.id : null,
    cityId: (sc && sc.city) ? sc.city.id : null,
    countryId: (sc && sc.country) ? sc.country.id : null,
    parking: sc ? sc.parking : false,
    bar: sc ? sc.bar : false,
    paymentMethods: ['ON_SITE'],
    rating: sc ? sc.rating : null,
    stripeAccountId: sc ? sc.stripeAccountId : null,
    requiredPhoneNumber: sc ? sc.requiredPhoneNumber : null,
    reservationEmailNotifications: sc ? sc.reservationEmailNotifications : null,
    halfHourSlot: sc ? sc.halfHourSlot : null,
    phoneNumber: sc ? sc.phoneNumber : null,
    website: sc ? sc.website : null,
    timeZone: sc ? sc.timeZone : null,
    galleryEnabled: sc ? sc.galleryEnabled : false,
    reservationsGap: sc ? sc.reservationsGap : null
  }
};

const CenterInformation: React.FC<Props> = ({ id, sportsCenter, refresh }) => {
  const configuration = useConfiguration();

  const { newToastNotification } = useToastNotifications();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [countries, setCountries] = useState<any>([])
  const [cities, setCities] = useState<any>([])
  const [neighborhoods, setNeighborhoods] = useState<any>([])

  const [form, setForm] = useState<FormValues>({
    id: null,
    visible: null,
    name: null,
    description: null,
    lat: null,
    lng: null,
    address: null,
    countryId: null,
    cityId: null,
    neighborhoodId: null,
    parking: null,
    bar: null,
    paymentMethods: ['ON_SITE'],
    rating: 0,
    stripeAccountId: null,
    requiredPhoneNumber: false,
    reservationEmailNotifications: false,
    halfHourSlot: false,
    phoneNumber: null,
    website: null,
    timeZone: null,
    galleryEnabled: false,
    reservationsGap: null
  });

  useEffect(() => {
    if(configuration) {
      setCountries(configuration.countries);
      // @ts-ignore
      const cities = form.countryId ? (countries.find(c => c.id === form.countryId)?.cities || []) : configuration.countries.flatMap(c => c.cities);
      setCities(cities)
      // @ts-ignore
      const neighborhoods = form.cityId ? (cities.find(c => c.id === form.cityId)?.neighborhoods || []) : cities.flatMap(c => c.neighborhoods);
      setNeighborhoods(neighborhoods)
    }
  }, [configuration, form.countryId, form.cityId]);

  useEffect(() => {
    if(id) {
      getSportsCenter(id)
          .then(({data}) => setForm(toForm(data)))
          .catch(({ response: { data } }) => {
            setLoading(false);
            if (data && data.message) {
              setErrorMsg(data.message);
            } else {
              setErrorMsg(translate('unexpectedError') + '.');
            }
          })
    }
  }, [id]);

  useEffect(() => {
    setForm(toForm(sportsCenter));
  }, [sportsCenter]);

  if (loading) {
    return <Splash />;
  }


  const onSubmit = () => {
    setLoading(true);
    (id ? updateSportsCenter(id, form) : createSportsCenter(form))
      .then(() => {
        setLoading(false);
        if (id && refresh != undefined) {
          refresh();
        } else {
          navigate('/centers');
        }
        newToastNotification(translate(id ? 'editCenter' : 'addCenter'), translate('operationSuccessful') + '.');
      })
      .catch(({ response: { data } }) => {
        setLoading(false);
        if (data && data.message) {
          setErrorMsg(data.message);
        } else {
          setErrorMsg(translate('unexpectedError') + '.');
        }
      })
  }

  const isModified = !id || (sportsCenter && (
    sportsCenter.name !== form.name ||
    sportsCenter.description !== form.description ||
    sportsCenter.lat !== form.lat ||
    sportsCenter.lng !== form.lng ||
    sportsCenter.address !== form.address ||
    sportsCenter.neighborhood?.id !== form.neighborhoodId ||
    sportsCenter.city.id !== form.cityId ||
    sportsCenter.country.id !== form.countryId ||
    sportsCenter.parking !== form.parking ||
    sportsCenter.bar !== form.bar ||
    sportsCenter?.requiredPhoneNumber !== form.requiredPhoneNumber ||
    sportsCenter?.reservationEmailNotifications !== form.reservationEmailNotifications ||
    sportsCenter?.phoneNumber !== form.phoneNumber ||
    sportsCenter?.website !== form.website
  ));


  // @ts-ignore
  return <>
    {!id && <>
        <h4 className='my-0'>{translate('addNewCenter')}</h4>
        <hr/>
      </>
    }
    <Form>
      {errorMsg && <Alert variant='danger' className='mt-4' style={{ fontSize: '0.85rem' }}>
        <div style={{ fontWeight: '700' }}>{translate('unexpectedError')}.</div>
        {errorMsg}
      </Alert>}
      <Row>
        <Col>
          <Form.Group>
            <Form.Label className='text-muted'>{translate('name')}</Form.Label>
            <Form.Control value={form.name === null ? '' : form.name} onChange={e => setForm({ ...form, name: e.target.value || null })} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col className='mb-2'>
          <Form.Group>
            <Form.Label className='text-muted'>{translate('description')}</Form.Label>
            <Form.Control as='textarea' placeholder={translate('addDescription')} rows={4} value={form.description === null ? '' : form.description} onChange={e => setForm({ ...form, description: e.target.value || null })} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col className='mb-2'>
          <Form.Group>
            <Form.Label className='text-muted'>{translate('address')}</Form.Label>
            <Form.Select value={form.countryId === null ? '' : form.countryId} onChange={e => setForm({ ...form, countryId: e.target.value === '' ? null : parseInt(e.target.value), cityId: null, neighborhoodId: null })}>
              <option key='' value=''>{translate('selectCountry')}</option>
              {// @ts-ignore
                countries?.map(c => <option key={c.id} value={c.id}>{c.name}</option>)
              }
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col className='mb-2'>
          <Form.Select value={form.cityId === null ? '' : form.cityId} onChange={e => setForm({ ...form, cityId: e.target.value === '' ? null : parseInt(e.target.value), neighborhoodId: null })}>
            <option key='' value=''>{translate('selectCity')}</option>
            {// @ts-ignore
              cities?.map(c => <option key={c.id} value={c.id}>{c.name}</option>)
            }
          </Form.Select>
        </Col>
      </Row>
      <Row>
        <Col className='mb-2'>
          <Form.Select value={form.neighborhoodId === null ? '' : form.neighborhoodId} onChange={e => setForm({ ...form, neighborhoodId: e.target.value === '' ? null : parseInt(e.target.value) })}>
            <option key='' value=''>{translate('selectNeighborhood')}</option>
            {// @ts-ignore
              neighborhoods?.map(n => <option key={n.id} value={n.id}>{n.name}</option>)
            }
          </Form.Select>
        </Col>
      </Row>
      <Row>
        <Col className='mb-2'>
          <Form.Control placeholder={translate('address')} value={form.address === null ? '' : form.address} onChange={e => setForm({ ...form, address: e.target.value || null })} />
        </Col>
      </Row>
      <Row>
        <Col md={6} className='mb-2'>
          <Form.Group>
            <Form.Label className='text-muted'>{translate('latitude')}</Form.Label>
            <Form.Control type='number' value={form.lat === null ? '' : form.lat} onChange={e => setForm({ ...form, lat: e.target.value === '' ? null : parseFloat(e.target.value) })} />
          </Form.Group>
        </Col>
        <Col md={6} className='mb-2'>
          <Form.Group>
            <Form.Label className='text-muted'>{translate('longitude')}</Form.Label>
            <Form.Control type='number' value={form.lng === null ? '' : form.lng} onChange={e => setForm({ ...form, lng: e.target.value === '' ? null : parseFloat(e.target.value) })} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label className='text-muted'>{translate('phoneNumber')}</Form.Label>
            <Form.Control type='text' value={form.phoneNumber === null ? '' : form.phoneNumber} onChange={e => setForm({ ...form, phoneNumber: e.target.value === '' ? null : e.target.value })} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label className='text-muted'>{translate('website')}</Form.Label>
            <Form.Control type='text' value={form.website === null ? '' : form.website} onChange={e => setForm({ ...form, website: e.target.value === '' ? null : e.target.value })} />
          </Form.Group>
        </Col>
      </Row>
      <Form.Label className='text-muted'/>
      <Row>
        <Col>
          <Form.Check checked={form.parking || false} label={translate('parking')} onChange={e => setForm({ ...form, parking: ((e.target as HTMLInputElement).checked ? true : null) })} />
        </Col>
        <Col>
          <Form.Check checked={form.bar || false} label={translate('bar')} onChange={e => setForm({ ...form, bar: ((e.target as HTMLInputElement).checked ? true : null) })} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Check checked={form.requiredPhoneNumber || false} label={translate('reservationPhoneNumberRequired')} onChange={e => setForm({ ...form, requiredPhoneNumber: (e.target as HTMLInputElement).checked })} />
        </Col>
        <Col>
          <Form.Check checked={form.reservationEmailNotifications || false} label={translate('reservationEmailNotifications')} onChange={e => setForm({ ...form, reservationEmailNotifications: ((e.target as HTMLInputElement).checked ? true : null) })} />
        </Col>
      </Row>
    </Form>
    <Row>
      <Col>
        <div className='d-flex justify-content-end mb-5'>
          <Button disabled={!(isModified === true)} variant='primary' style={{ width: '8rem' }} onClick={onSubmit}>{translate('save')}</Button>
        </div>
      </Col>
    </Row>
  </>
}

export default CenterInformation;