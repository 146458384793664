import React, {useEffect, useRef, useState} from 'react';
import { Alert, Button, Col, Form, Row} from 'react-bootstrap';
import {
  blockUser,
  getSportsCenterUsers,
  removeUserAsPremium,
  setUserAsPremium, unBlockUser
} from '../../../helpers/api';
import translate from '../../../helpers/translations';
import Splash from "../../Splash";
import SRTable from '../../../components/SRTable';

const PAGE_SIZE = 50;

interface Props {
  sportsCenterId: number;
}

const CenterUsers: React.FC<Props> = ({sportsCenterId}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);

  const [users, setUsers] = useState<SportsCenterUser[]>([]);
  const [userFilter, setUserFilter] = useState<string>('')
  const [filter, setFilter] = useState<SportsCenterUserFilter>({user: ''})

  const search = () => {
    setLoading(true);
    setErrorMsg(null);
    setPageNumber(0);
    getSportsCenterUsers(sportsCenterId, 0, PAGE_SIZE, filter)
      .then(({ data, headers }) => {
        setLoading(false);
        setUsers(data);
        setTotalCount(parseInt(headers['x-total-count']));
      })
      .catch(({ response: { data } }) => {
        setLoading(false);
        setUsers([]);
        setTotalCount(0);
        if (data && data.message) {
          setErrorMsg(data.message);
        } else {
          setErrorMsg(translate('errorFetchingResults') + '.');
        }
      })
  }

  const submit = (e: any) => {
    e.preventDefault()
    search()
  }

  const loadMoreResults = () => {
    setLoading(true);
    getSportsCenterUsers(sportsCenterId, pageNumber + 1, PAGE_SIZE, filter)
        .then(({ data, headers }) => {
          setUsers(users.concat(data));
          setPageNumber(pageNumber + 1);
          setTotalCount(parseInt(headers['x-total-count']));
        })
        .catch(({ response: { data } }) => {
          setUsers([]);
          setTotalCount(0);
          if (data && data.message) {
            setErrorMsg(data.message);
          } else {
            setErrorMsg(translate('errorFetchingResults') + '.');
          }
        })
        .finally(() => setLoading(false))
  }

  const handleIsPremium = (userId: number, isPremium: boolean) => {
    setLoading(true);

    const promise = isPremium ?
        setUserAsPremium(sportsCenterId, userId) :
        removeUserAsPremium(sportsCenterId, userId)

    promise.then(() => {
      if(filter.isPremium) {
        setUsers(prevUsers => prevUsers.filter(user => user.id !== userId));
      } else {
        setUsers((prevUsers) =>
            prevUsers.map((user) =>
                user.id === userId ? { ...user, isPremium: isPremium } : user
            )
        );
      }
    }).finally(() => setLoading(false))
  }

  const handleIsBlocked = (userId: number, isBlocked: boolean) => {
    setLoading(true);

    const promise = isBlocked ?
        blockUser(sportsCenterId, userId) :
        unBlockUser(sportsCenterId, userId)

    promise.then(() => {
      if(filter.isBlocked) {
        setUsers(prevUsers => prevUsers.filter(user => user.id !== userId));
      } else {
        setUsers((prevUsers) =>
            prevUsers.map((user) =>
                user.id === userId ? { ...user, isBlocked: isBlocked } : user
            )
        );
      }
    }).finally(() => setLoading(false))
  }


  const isMounted = useRef(false);
  useEffect(() => {
    if (isMounted.current) {
      const timeOutId = setTimeout(() => {
        if(filter.user != userFilter.trimStart()) {
          setFilter({...filter, user: userFilter.trimStart()})
        }
      }, 500);
      return () => clearTimeout(timeOutId);
    } else {
      isMounted.current = true;
    }
  }, [userFilter]);


  useEffect(search, [filter]);

  return <>
    {loading && <Splash />}
    {errorMsg &&
        <Alert variant='danger' style={{ fontSize: '0.85rem' }}>
          <div style={{ fontWeight: '700' }}>{translate('errorFetchingResults')}</div>
          {errorMsg}
        </Alert>
    }
    <Form onSubmit={submit}>
      <Row className="align-items-center">
        <Col md={6}>
          <Form.Control type="text" placeholder={translate('USER')}
                        value={userFilter}
                        onChange={e => setUserFilter(e.target.value)}
          />
        </Col>
        <Col md={6} style={{paddingTop: '5px', paddingBottom: '5px'}}>
          <Row className="align-items-center">
            <Col className="d-flex justify-content-center" md={6} xs={6}>
              <Form.Check checked={!!filter.isPremium}
                          label={translate('premiumsOnly')}
                          onChange={e => setFilter({...filter, isPremium: e.target.checked ? true : undefined})}
              />
            </Col>
            <Col className="d-flex justify-content-center" md={6} xs={6}>
              <Form.Check checked={!!filter.isBlocked}
                          label={translate('blockedOnly')}
                          onChange={e => setFilter({...filter, isBlocked: e.target.checked ? true : undefined})}
              />
            </Col>
          </Row>
        </Col>

      </Row>
    </Form>
    {users.length !== 0 && <>
      <SRTable>
        <thead>
        <tr>
          <th>#</th>
          <th>{translate('firstName')}</th>
          <th>{translate('lastName')}</th>
          <th>{translate('email')}</th>
          <th>{translate('phoneNumber')}</th>
          <th className='text-center'>{translate('premium')}</th>
          <th className='text-center'>{translate('blocked')}</th>
        </tr>
        </thead>
        <tbody>
        {users.map((p, index) => <tr key={p.id} className='bg-light'>
          <td className='mx-2'>{totalCount - index}</td>
          <td>{p.firstName || '-'}</td>
          <td>{p.lastName || '-'}</td>
          <td>{p.email || '-'}</td>
          <td>{p.phoneNumber || '-'}</td>
          <td className='text-center'>
            <Form.Check type='switch'
                        checked={p.isPremium}
                        disabled={loading}
                        onChange={e => handleIsPremium(p.id, e.target.checked)}
            />
          </td>
          <td className='text-center'>
            <Form.Check type='switch'
                        className='switch-danger'
                        checked={p.isBlocked}
                        disabled={loading}
                        onChange={e => handleIsBlocked(p.id, !p.isBlocked)}
            />

          </td>
        </tr>)}
        </tbody>
      </SRTable>
      {users.length < totalCount && <Row>
        <Col className='d-flex justify-content-center'>
          <Button variant='link' className='mt-3 mb-5' onClick={loadMoreResults}>{translate('seeMoreResults')}</Button>
        </Col>
      </Row>}
      {users.length === totalCount && <Row>
        <Col className='d-flex justify-content-center'>
          <div className='mt-3 mb-5'>{translate('thatsAllResults')}</div>
        </Col>
      </Row>}
    </>}
    {users.length === 0 && <Row>
      <Col>
        <h3 className='text-muted mt-5 text-center'>{translate('noResults')}</h3>
      </Col>
    </Row>}
  </>;
};

export default CenterUsers;