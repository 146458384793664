import { useState, useContext, createContext } from 'react';

interface AuthContextValue {
  person: Person | null;
  setPerson: ((person: Person | null) => any) | (() => {});
}

const AuthContext = createContext<AuthContextValue>({
  person: null,
  setPerson: () => { }
});

export const AuthProvider: React.FC<{ children: any }> = ({ children }) => {
  const [person, setPerson] = useState<Person | null>(null);

  return (
    <AuthContext.Provider value={{ person, setPerson }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);