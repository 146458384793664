import React, { useState } from 'react';
import { Alert, Button, Col, Container, Form, Modal, Row, Spinner } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import {saveSportsCenterNotificationRequest} from '../../../helpers/api';
import { useToastNotifications } from '../../../helpers/notifications';
import translate from '../../../helpers/translations';
import {dateValueToDate} from "../../../helpers/date";

interface Props {
  sportsCenterId: number;
  sportsCenterGalleryEnabled: boolean;
  onHide: () => any;
  onSave: () => any;
}

const NewCenterNotificationRequestModal: React.FC<Props> = ({ sportsCenterId, sportsCenterGalleryEnabled, onHide, onSave }) => {
  const { newToastNotification } = useToastNotifications();
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [screenType, setScreenType] = useState<MobileAppScreen | undefined>(undefined);
  const [atTheExactTime, setAtTheExactTime] = useState<boolean>(false);
  const [openUrl, setOpenUrl] = useState<boolean>(true);
  const [url, setUrl] = useState<string | undefined>('');
  const [request, setRequest] = useState<SportsCenterNotificationForm>(
      {
        title: '',
        body: '',
        sportsCenterId: sportsCenterId,
        properties: {}
      }
  );

  const submit = () => {
    if(openUrl && !isURLValid(url)) {
      setErrorMsg(translate('invalidWebsite'))
      return
    }
    if(atTheExactTime && !isNotifyAtValid(request.notifyAt)) {
      setErrorMsg(translate('invalidTime'))
      return
    }
    if(isBlank(request.title) || isBlank(request.body)) {
      setErrorMsg(translate('fillAllFields'))
      return
    }

    const args: { [key: string]: string } = {};
    if(screenType) {
      args['screenType'] = screenType;
    }
    if(openUrl && url) {
      let urlToOpen = url.slice();
      if(!urlToOpen.startsWith('http://') && !urlToOpen.startsWith('https://')) {
        urlToOpen = 'https://' + urlToOpen
      }

      args['openExternally'] = 'false';
      args['urlToOpen'] = urlToOpen;
    }

    setErrorMsg(null);
    setLoading(true);
    saveSportsCenterNotificationRequest(sportsCenterId, {...request, notifyAt: atTheExactTime ? request.notifyAt : undefined, properties: args})
        .then(() => {
          setLoading(false);
          newToastNotification(translate('save'), translate('operationSuccessful') + '.');
          onSave();
        })
        .catch(({ response: { data } }) => {
          setLoading(false);
          if (data && data.message) {
            setErrorMsg(data.message);
          } else {
            setErrorMsg(translate('unexpectedError') + '.');
          }
        })
  }

  const getViewUrl = (url?: string) => {
    if(!url) return '';
    if(!url.startsWith('http://') && !url.startsWith('https://')) {
      return  'https://' + url
    }
    return url;
  }

  const isURLValid = (url?: string) => {
    if(!url) return false;
    const expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;
    const regex = new RegExp(expression);
    return regex.test(url);
  };

  const isNotifyAtValid = (notifyAt?: string) => {
    if(!notifyAt) return false;
    const notifyAtDate = new Date(notifyAt);
    const currentDate = new Date();
    return currentDate < notifyAtDate;
  }

  const isBlank = (str: string) => {
    return /^\s*$/.test(str);
  }

  const handleOpenUrlChange = (openUrl: boolean) => {
    if(openUrl){
      setUrl('');
      setScreenType(undefined)
    } else {
      setUrl(undefined);
      setScreenType('SPORTS_CENTER_DETAILS' as MobileAppScreen)
    }
    setOpenUrl(openUrl)
  }

  return <Modal show={true} onHide={onHide}>
    <Modal.Header closeButton>
      <strong>{translate('sendRequest')}</strong>
    </Modal.Header>
    <Modal.Body>
      <Form>
        <Container>
          {errorMsg && <Row>
            <Col>
              <Alert variant='danger' className='mt-2' style={{fontSize: '0.85rem'}}>
                <div style={{fontWeight: '700'}}>{errorMsg}</div>
              </Alert>
            </Col>
          </Row>}
          <Row className='mt-2'>
            <Col><span>{translate('notificationAppearance')}</span></Col>
          </Row>
          <Row className='mt-2'>
            <Col>
              <Form.Group controlId="form.title">
                <Form.Label style={{fontSize: '12px', margin: 0}}>{translate('title')}</Form.Label>
                <Form.Control required={true} value={request.title}
                              onChange={e => setRequest({...request, title: e.target.value})}/>
              </Form.Group>
            </Col>
          </Row>
          <Row className='mt-2'>
            <Col>
              <Form.Group controlId="form.description">
                <Form.Label style={{fontSize: '12px', margin: 0}}>{translate('description')}</Form.Label>
                <Form.Control as="textarea" value={request.body}
                              onChange={e => setRequest({...request, body: e.target.value})}/>
              </Form.Group>
            </Col>
          </Row>
          <hr/>
          <Row className='mt-2'>
            <Col>
              <span>{translate('clickOnNotification')}</span>
              <br/>
              <div style={{fontSize: '12px', marginTop: 5, fontStyle: 'italic'}}>
                {translate('clickOnNotificationDescription')}
              </div>
            </Col>
          </Row>
          <Row className='mt-2'>
            <Col>
              <Form.Group controlId="form.description">
                <Form.Select name="notificationClick"
                             placeholder={translate('select')}
                             value={openUrl ? 'true' : 'false'}
                             onChange={e => handleOpenUrlChange(e.target.value == 'true')}
                >
                  <option value="true">{translate('website')}</option>
                  <option value="false">{translate('screenInsideApp')}</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          {openUrl &&
              <Row className='mt-2'>
                <Col xs={9}>
                  <Form.Group>
                    <Form.Label style={{fontSize: '12px', margin: 0}}>{translate('website')}</Form.Label>
                    <Form.Control
                        placeholder='https://example.com'
                        value={url}
                        onChange={e => setUrl(e.target.value)}/>
                  </Form.Group>
                </Col>
                <Col xs={3}>
                  <Form.Label/>
                  <div>
                    <a href={getViewUrl(url)} target="_blank">
                      {translate('view')}
                    </a>
                  </div>
                </Col>
              </Row>
          }
          {!openUrl &&
              <Row className='mt-2'>
                <Col>
                  <Form.Group>
                    <Form.Label style={{fontSize: '12px', margin: 0}}>{translate('appScreen')}</Form.Label>
                    <Form.Select name="notificationClick"
                                 placeholder={translate('select')}
                                 value={screenType + ''}
                                 onChange={e => setScreenType(e.target.value as MobileAppScreen)}
                    >
                      <option value="SPORTS_CENTER_DETAILS">{translate('SPORTS_CENTER_DETAILS')}</option>
                      {sportsCenterGalleryEnabled &&
                          <option value="SPORTS_CENTER_GALLERY">{translate('SPORTS_CENTER_GALLERY')}</option>
                      }
                      <option value="SPORTS_CENTER_SEARCH">{translate('SPORTS_CENTER_SEARCH')}</option>
                      <option value="SPORTS_CENTER_NOTIFICATIONS">{translate('SPORTS_CENTER_NOTIFICATIONS')}</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
          }
          <hr/>
          <Row className='mt-2'>
            <Col><span>{translate('notificationSendingTime')}</span></Col>
          </Row>
          <Row className='mt-2'>
            <Col>
              <Form.Group controlId="form.description">
                <Form.Select name="notificationClick"
                             placeholder={translate('select')}
                             value={atTheExactTime ? 'true' : 'false'}
                             onChange={e => setAtTheExactTime(e.target.value == 'true')}
                >
                  <option value="false">{translate('immediatelyAfterApproval')}</option>
                  <option value="true">{translate('atTheExactTime')}</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          {atTheExactTime &&
              <Row className='mt-2'>
                <Col>
                  <Form.Group>
                    <Form.Label style={{fontSize: '12px', margin: 0}}>{translate('time')}</Form.Label>
                    <DatePicker
                        required={true}
                        selected={request.notifyAt ? dateValueToDate(request.notifyAt) : null}
                        onChange={date => setRequest({ ...request, notifyAt: date ? date.toISOString() : '' })}
                        showTimeSelect
                        timeFormat='HH:mm'
                        timeIntervals={30}
                        dateFormat='dd.MM.yyyy. HH:mm'
                        customInput={<Form.Control />}
                        filterTime={time => time > new Date()}
                    />
                  </Form.Group>
                </Col>
              </Row>
          }
        </Container>
      </Form>
    </Modal.Body>
    <Modal.Footer>
      {loading && <Spinner animation='border' variant='secondary' className='mx-auto' />}
      {!loading && <>
        <Button variant='secondary' onClick={() => onHide()}>{translate('close')}</Button>
        <Button variant='primary' onClick={() => submit()}>{translate('sendForReview')}</Button>
      </>}
    </Modal.Footer>
  </Modal>;
};

export default NewCenterNotificationRequestModal;