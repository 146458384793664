import React from 'react';
import {useAuth} from '../../helpers/auth';
import SRSidebar from "./SRSidebar";
import SRHeader from "./SRHeader";
import {Container} from "react-bootstrap";


interface Props {
    children?: React.ReactNode;
}

const SRNavigation: React.FC<Props> = ({children}) => {
    const {person} = useAuth();

    return (person && <>
        <SRSidebar/>
        <div className='bradius' style={{display: 'flex', height: '100vh', width: '100%'}}>
                <div style={{
                    height: '100vh',
                    width: '100%',
                    overflowY: 'scroll',
                    overflowX: "hidden",
                    position: 'relative'
                }}>
                    <SRHeader/>
                    <Container fluid className='pt-4 pb-4' style={{paddingLeft: '16px', paddingRight: '16px'}}>
                        {children}
                    </Container>
                </div>
            </div>
        </>
    );
}

export default SRNavigation;