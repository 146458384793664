import React, {useEffect, useRef, useState} from 'react';
import {Alert, Button, Col, Form, Row, Table} from 'react-bootstrap';
import {getReservationsByCriteria, getSportsCentersLabels} from '../../../helpers/api';
import DatePicker from 'react-datepicker';
import {useToastNotifications} from '../../../helpers/notifications';
import translate from '../../../helpers/translations';
import {dateToDateValue, dateValueToDate, formatDate, formatTime,} from "../../../helpers/date";
import {sportIcons} from "../../../helpers/sportIcons";
import Splash from "../../Splash";
import ReservationDetailsModal from "./ReservationDetailsModal";
import {useNavMenu} from "../../../components/NavMenu/NavMenuProvider";
import SRTable from "../../../components/SRTable";
import Iphone from "../../../components/Icons/Iphone";
import CreditCard from "../../../components/Icons/CreditCard";
import Checkmark from "../../../components/Icons/Checkmark";
import Repeat from "../../../components/Icons/Repeat";
import Person from "../../../components/Icons/Person";

interface Props {
    selected?: boolean,
    sportsCenter?: SportsCenter,
    sports: Sport[]
}

interface ReservationCriteria {
    user?: string;
    sports?: string[];
    paymentMethod?: string;
    from?: string;
    to?: string;
    active?: boolean;
    recurringReservation?: boolean;
    createdByRoles?: string[];
    sportsCenterId?: number;
    pageNumber?: number;
    pageSize?: number;
}

const dateFrom = new Date(new Date().setHours(0, 0, 0, 0));
const dateTo = new Date(new Date(dateFrom).setDate(dateFrom.getDate() + 7));

const SearchReservations: React.FC<Props> = ({selected, sportsCenter, sports}) => {
    const {newToastNotification} = useToastNotifications();
    const [selectedReservation, setSelectedReservation] = useState<ReservationCourtPerson | null>(null);
    const [reservations, setReservations] = useState<ReservationCourtPerson[]>([]);
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [errorMsg, setErrorMsg] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [oldCriteria, setOldCriteria] = useState<ReservationCriteria>(
        {
            sportsCenterId: sportsCenter ? sportsCenter.id : undefined,
            pageNumber: 0,
            pageSize: 50,
            active: true,
            from: dateToDateValue(dateFrom),
            to: dateToDateValue(dateTo),
        });
    const [criteria, setCriteria] = useState<ReservationCriteria>(oldCriteria);
    const [searchString, setSearchString] = useState(criteria.user);
    const [sportsCenters, setSportsCenters] = useState<SportsCenterLabel[]>([]);

    const {setActiveHeaderKey, setActiveSidebarKey, setSportsCenter} = useNavMenu();

    useEffect(() => {
        if(!sportsCenter) {
            setActiveHeaderKey('reservations')
            setActiveSidebarKey(undefined)
            setSportsCenter(undefined)
        }

        if (!sportsCenter) {
            getSportsCentersLabels()
                .then(({data}) => {
                    const sportsCenters = data.sort((sc1, sc2) => sc1.name.localeCompare(sc2.name));
                    setSportsCenters(sportsCenters);
                })
                .catch(() => {
                });
        }
    }, []);

    const isMounted = useRef(false);
    useEffect(() => {
        if (isMounted.current) {
            const timeOutId = setTimeout(() => setCriteria({...criteria, user: searchString}), 500);
            return () => clearTimeout(timeOutId);
        } else {
            isMounted.current = true;
        }
    }, [searchString]);

    useEffect(() => {
        if (selected == undefined || selected) {
            search();
        }
    }, [selected, criteria]);

    const search = () => {
        setLoading(true)
        getReservationsByCriteria(criteria)
            .then(({data, headers}) => {
                setOldCriteria({...criteria})
                setReservations(data)
                setPageNumber(criteria.pageNumber ? criteria.pageNumber : 0)
                setTotalCount(parseInt(headers['x-total-count']));
                setErrorMsg(null)
            })
            .catch(({response: {data}}) => {
                setTotalCount(0);
                if (data && data.message) {
                    setErrorMsg(data.message);
                } else {
                    setErrorMsg(translate('errorFetchingResults') + '.');
                }
            })
            .finally(() => setLoading(false))
    }

    const submit = (e: any) => {
        e.preventDefault()
        search()
    }

    const loadMoreResults = () => {
        setLoading(true)
        getReservationsByCriteria({...oldCriteria, pageNumber: pageNumber + 1})
            .then(({data, headers}) => {
                setReservations(reservations.concat(data));
                setPageNumber(pageNumber + 1);
                setTotalCount(parseInt(headers['x-total-count']));
            })
            .catch(({response: {data}}) => {
                setReservations([]);
                setTotalCount(0);
                if (data && data.message) {
                    setErrorMsg(data.message);
                } else {
                    setErrorMsg(translate('errorFetchingResults') + '.');
                }
            })
            .finally(() => setLoading(false))
    }

    const getUserString = (reservation: ReservationCourtPerson) => {
        let userString = '-';
        if (reservation.person && (reservation.person.firstName || reservation.person.lastName)) {
            userString = `${reservation.person.firstName ? reservation.person.firstName : ''} ${reservation.person.lastName ? reservation.person.lastName : ''}`;
        } else if (reservation.firstName || reservation.lastName) {
            userString = `${reservation.firstName ? reservation.firstName : ''} ${reservation.lastName ? reservation.lastName : ''}`;
        } else if(reservation.person && reservation.person.email) {
            userString = `${reservation.person.email}`
        } else if(reservation.email) {
            userString = `${reservation.email}`
        }
        return userString;
    };

    const getReservationsType = () => {
        if (criteria.recurringReservation) {
            return "recurring";
        } else if (criteria.createdByRoles && criteria.createdByRoles.includes("USER")) {
            return "fromMobile";
        } else if (criteria.createdByRoles && criteria.createdByRoles.includes("ADMINISTRATOR")) {
            return "fromAdmin";
        }
        return "all";
    }

    const handleReservationsTypeChanged = (type: string) => {
        if (type == "fromMobile") {
            setCriteria({...criteria, recurringReservation: undefined, createdByRoles: ["USER"]})
        } else if (type == "fromAdmin") {
            setCriteria({
                ...criteria,
                recurringReservation: false,
                createdByRoles: ["SPORTS_CENTER", "ADMINISTRATOR"]
            })
        } else if (type == "recurring") {
            setCriteria({...criteria, recurringReservation: true, createdByRoles: undefined})
        } else {
            setCriteria({...criteria, recurringReservation: undefined, createdByRoles: undefined})
        }
    }

    return (
        <>
            {errorMsg &&
                <Row>
                    <Col>
                        <Alert variant='danger' style={{fontSize: '0.85rem'}}>
                            <div style={{fontWeight: '700'}}>{errorMsg}</div>
                        </Alert>
                    </Col>
                </Row>
            }
            <Form onSubmit={submit}>
                {loading && <Splash/>}
                <Row>
                    <Col md={sportsCenter ? "6" : "3"}>
                        <Form.Group controlId="form.search">
                            <Form.Label style={{fontSize: '12px', margin: 0}}>{translate('USER')}</Form.Label>
                            <Form.Control type="text" placeholder={translate('search')}
                                          value={searchString}
                                          onChange={e => setSearchString(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    {!sportsCenter &&
                        <Col md="3">
                            <Form.Group controlId="form.sportsCenter">
                                <Form.Label
                                    style={{fontSize: '12px', margin: 0}}>{translate('sportsCenter')}</Form.Label>
                                <Form.Select value={criteria.sportsCenterId ? criteria.sportsCenterId : ""}
                                             onChange={e => setCriteria({
                                                 ...criteria,
                                                 sportsCenterId: e.target.value ? +e.target.value : undefined
                                             })}
                                >
                                    <option value={undefined}>{translate('allSportsCenter')}</option>
                                    {sportsCenters.map(sc => <option key={sc.id} value={sc.id}>{sc.name}</option>)}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    }
                    <Col md="3">
                        <Form.Group controlId="form.from">
                            <Form.Label style={{fontSize: '12px', margin: 0}}>{translate('from')}</Form.Label>
                            <DatePicker
                                required
                                placeholderText={translate('from')}
                                selected={criteria.from ? dateValueToDate(criteria.from) : null}
                                onChange={date => setCriteria({
                                    ...criteria,
                                    from: date ? dateToDateValue(date) : undefined
                                })}
                                dateFormat='dd.MM.yyyy.'
                                timeFormat='dd.MM.yyyy.'
                                customInput={<Form.Control/>}
                            />
                        </Form.Group>
                    </Col>
                    <Col md="3">
                        <Form.Group controlId="form.to">
                            <Form.Label style={{fontSize: '12px', margin: 0}}>{translate('to')}</Form.Label>
                            <DatePicker
                                required
                                placeholderText={translate('to')}
                                selected={criteria.to ? dateValueToDate(criteria.to) : null}
                                onChange={date => setCriteria({
                                    ...criteria,
                                    to: date ? dateToDateValue(date) : undefined
                                })}
                                dateFormat='dd.MM.yyyy.'
                                timeFormat='dd.MM.yyyy.'
                                customInput={<Form.Control/>}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="ps-1 pe-1">
                    <Col md="3">
                        <Form.Group controlId="form.sport">
                            <Form.Label style={{fontSize: '12px', margin: 0}}>{translate('sport')}</Form.Label>
                            <Form.Select
                                name="sport"
                                placeholder={translate('select')}
                                value={criteria.sports && criteria.sports.length > 0 ? criteria.sports[0] : ""}
                                onChange={e => setCriteria({
                                    ...criteria,
                                    sports: e.target.value ? [e.target.value] : undefined
                                })}
                            >
                                <option value="">{translate('allSports')}</option>
                                {sports.map(s => <option key={s} value={s}>{translate(s)}</option>)}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col md="3">
                        <Form.Group controlId="form.paymentMethod">
                            <Form.Label style={{fontSize: '12px', margin: 0}}>{translate('paymentMethod')}</Form.Label>
                            <Form.Select value={criteria.paymentMethod} onChange={e => setCriteria({
                                ...criteria,
                                paymentMethod: e.target.value ? e.target.value : undefined
                            })}>
                                <option value="">{translate('resFilterAllPaymentMethods')}</option>
                                <option value="ON_SITE">{translate('resFilterCash')}</option>
                                <option value="CARD">{translate('resFilterCard')}</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col md="3">
                        <Form.Group controlId="form.reservationType">
                            <Form.Label
                                style={{fontSize: '12px', margin: 0}}>{translate('labelReservationType')}</Form.Label>
                            <Form.Select value={getReservationsType()}
                                         onChange={e => handleReservationsTypeChanged(e.target.value)}>
                                <option value="all">{translate('resFilterAllReservation')}</option>
                                <option value="recurring">{translate('resFilterRecurring')}</option>
                                <option value="fromAdmin">{translate('resFilterFromAdmin')}</option>
                                <option value="fromMobile">{translate('resFilterFromMobile')}</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col md="3">
                        <Form.Group controlId="form.labelReservationStatus">
                            <Form.Label
                                style={{fontSize: '12px', margin: 0}}>{translate('labelReservationStatus')}</Form.Label>
                            <Form.Select
                                value={criteria.active === undefined ? "" : (criteria.active ? "true" : "false")}
                                onChange={e => setCriteria({
                                    ...criteria,
                                    active: e.target.value ? (e.target.value === "true") : undefined
                                })}>
                                <option value="true">{translate('resFilterSuccessful')}</option>
                                <option value="false">{translate('resFilterCanceled')}</option>
                                <option value="">{translate('resFilterSuccessfulAndCanceled')}</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
            </Form>
            <div className='d-flex flex-column'>
                {reservations.length !== 0 &&
                    <SRTable tableHover>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>{translate('sport')}</th>
                            <th>{translate('USER')}</th>
                            <th>{translate('court')}</th>
                            <th>{translate('time')}</th>
                            <th>{translate('price')}</th>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        {reservations.length !== 0 &&
                            reservations.map((r, index) =>
                                <tr key={r.id}
                                    onClick={() => setSelectedReservation(r)}>
                                    <td style={{backgroundColor: !r.active ? '#fae3e3' : ''}}>{index + 1}</td>
                                    <td style={{backgroundColor: !r.active ? '#fae3e3' : '', whiteSpace: 'nowrap'}}>
                                        <img height={20} width={20}
                                             src={sportIcons[r.sport.toLowerCase().replaceAll('-', '_') as keyof typeof sportIcons]}
                                             alt={r.sport} style={{margin: '1px'}}/>&nbsp;&nbsp;{translate(r.sport)}

                                    </td>
                                    <td style={{
                                        backgroundColor: !r.active ? '#fae3e3' : '',
                                        maxWidth: '150px',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }}>{getUserString(r)}</td>
                                    <td style={{backgroundColor: !r.active ? '#fae3e3' : ''}}>{r.court.name}</td>
                                    <td style={{backgroundColor: !r.active ? '#fae3e3' : ''}}>{formatDate(r.from)} {formatTime(r.from)}-{formatTime(r.to)}</td>
                                    <td style={{backgroundColor: !r.active ? '#fae3e3' : ''}}>{r.price} {r.currency}</td>
                                    <td style={{
                                        backgroundColor: !r.active ? '#fae3e3' : '',
                                        paddingLeft: 0,
                                        textAlign: 'center',
                                        whiteSpace: 'nowrap'
                                    }}>
                                        {r.createdByRole === "USER" ?
                                            <Iphone
                                                style={{marginRight: '3px', verticalAlign: 'text-bottom'}}/> : null}
                                        {r.paymentMethod === "CARD" ? <CreditCard
                                            style={{marginRight: '3px', verticalAlign: 'text-bottom'}}/> : null}
                                        {r.paymentMethod !== "CARD" && r.paymentSuccessful ? <Checkmark
                                            style={{marginRight: '3px', verticalAlign: 'text-bottom'}}/> : null}
                                        {r.recurring === true ? <Repeat
                                            style={{marginRight: '3px', verticalAlign: 'text-bottom'}}/> : null}
                                        {r.createdByRole !== "USER" && !r.recurring ? <Person
                                            style={{marginRight: '3px', verticalAlign: 'text-bottom'}}/> : null}
                                    </td>
                                </tr>)}
                        </tbody>
                    </SRTable>}
                {reservations.length === 0 && <h4 className='text-muted my-5 text-center'>{translate('noResults')}</h4>}
                {reservations.length < totalCount &&
                    <span>
                    <Row>
                        <Col className='d-flex justify-content-center'>
                            {reservations.length} / {totalCount}
                        </Col>
                    </Row>
                    <Row>
                        <Col className='d-flex justify-content-center'>
                          <Button disabled={loading} variant='link' className='mt-1 mb-0'
                                  onClick={() => loadMoreResults()}>{translate('seeMoreResults')}</Button>
                        </Col>
                    </Row>
                </span>
                }
                {reservations.length >= totalCount && reservations.length > 0 && <Row>
                    <Col className='d-flex justify-content-center'>
                        <div className='mt-3 mb-5'>{translate('thatsAllResults')}</div>
                    </Col>
                </Row>}
                {selectedReservation &&
                    <ReservationDetailsModal showSportsCenter={!sportsCenter}
                                             reservation={selectedReservation}
                                             onHide={() => setSelectedReservation(null)}
                    />
                }
            </div>
        </>
    )
}

export default SearchReservations;