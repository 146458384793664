import React, {useEffect, useState} from 'react';
import {Alert, Button, Col, Form, Row, Table} from 'react-bootstrap';
import translate from '../../../helpers/translations';
import {updateReservationCancellationPolicy} from '../../../helpers/api';
import {useToastNotifications} from "../../../helpers/notifications";
import SRTable from "../../../components/SRTable";

interface Props {
    sportsCenter: SportsCenter;
    refresh: () => any;
}

const ReservationCancellationPolicy: React.FC<Props> = ({ sportsCenter, refresh }) => {
    const { newToastNotification } = useToastNotifications();
    const [loading, setLoading] = useState<boolean>(false);
    const [isModified, setIsModified] = useState<boolean>(false);
    const [sports, setSports] = useState<Sport[]>([]);
    const [form, setForm] = useState<IReservationCancellationPolicy[]>([]);
    const [errorMsg, setErrorMsg] = useState<string | null>(null);


    useEffect(() => {
        setSports(
            Array.from(new Set(
                sportsCenter.offers
                    .flatMap(o => o.courts)
                    .flatMap(c => c.sports)
            ))
        )
        setForm(sportsCenter.reservationCancellationPolicies.filter(p => p.hoursBeforeStart > 0))
    }, [sportsCenter]);

    useEffect(() => {
        let modified = false;
        sports.forEach(sport => {
            if(
                sportsCenter.reservationCancellationPolicies.find(rcp => rcp.sport == sport)?.hoursBeforeStart
                != form.find(rcp => rcp.sport == sport)?.hoursBeforeStart
            ) modified = true;
        })
        setIsModified(modified)
    }, [sportsCenter.reservationCancellationPolicies, form]);

    const getValue = (sport: Sport) => {
        const policy = form.find((p) => p.sport === sport);
        return policy ? policy.hoursBeforeStart : 0;
    };

    const setValue = (sport: Sport, value: number) => {
        const updatedPolicies = [...form];
        const index = updatedPolicies.findIndex((p) => p.sport === sport);
        if (index !== -1) {
            // If the policy exists, update it
            updatedPolicies[index] = { sport, hoursBeforeStart: value };
        } else {
            // If the policy does not exist, create a new one
            updatedPolicies.push({ sport, hoursBeforeStart: value });
        }
        setForm(updatedPolicies.filter(p => p.hoursBeforeStart > 0));
    };
    const handleSave = () => {
        setLoading(true);
        updateReservationCancellationPolicy(sportsCenter.id, form)
            .then(() => {
                setLoading(false);
                refresh()
                newToastNotification(translate('save'), translate('operationSuccessful') + '.');
            })
            .catch(({ response: { data } }) => {
                if (data && data.message) {
                    setErrorMsg(data.message);
                } else {
                    setErrorMsg(translate('unexpectedError') + '.');
                }
            })
    }


    return <div className='d-flex flex-column p-2'>
            {errorMsg &&
                <Row>
                    <Col>
                        <Alert variant='danger' className='mt-2' style={{ fontSize: '0.85rem' }}>
                            <div style={{ fontWeight: '700' }}>{errorMsg}</div>
                        </Alert>
                    </Col>
                </Row>
            }
            <SRTable>
                <thead>
                    <tr>
                        <th>{translate('sport')}</th>
                        <th>{translate('timeBeforeStart')}</th>
                    </tr>
                </thead>
                <tbody>
                    {sports.map(s =>
                        <tr key={s}>
                            <td>{translate(s)}</td>
                            <td className="p-1">
                                <Form.Control type='number' min={0}
                                              value={getValue(s)}
                                              onChange={e => setValue(s, +e.target.value)}
                                />
                            </td>
                        </tr>)
                    }
                </tbody>
            </SRTable>
        <Row className="mt-2">
            <Col>
                <div className='d-flex justify-content-end'>
                    <Button onClick={handleSave} disabled={!isModified} variant='primary' style={{ width: '8rem' }} >{translate('save')}</Button>
                </div>
            </Col>
        </Row>
    </div>;
}

export default ReservationCancellationPolicy;