import * as React from "react"
import { SVGProps } from "react"

const CreditCard = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width ? props.width : "16"}
        height={props.height ? props.height : "16"}
        fill="current"
        viewBox="0 0 65 48"
        {...props}
    >
        <g clipPath="url(#clip0_3138_12770)">
            <path
                fill="current"
                d="M12.42 37.59h6.776c1.632 0 2.71-1.107 2.71-2.656v-5.117c0-1.577-1.078-2.655-2.71-2.655h-6.777c-1.632 0-2.71 1.078-2.71 2.655v5.117c0 1.55 1.078 2.655 2.71 2.655zM2.24 17.314h60.547v-6.279H2.24v6.279zM8.685 47.27h47.63c5.809 0 8.685-2.849 8.685-8.547V8.604C65 2.903 62.123.027 56.315.027H8.685C2.905.028 0 2.904 0 8.602v30.121c0 5.698 2.904 8.547 8.685 8.547zm.055-4.453c-2.766 0-4.287-1.466-4.287-4.343V8.851c0-2.877 1.521-4.37 4.287-4.37h47.52c2.71 0 4.287 1.493 4.287 4.37v29.623c0 2.877-1.577 4.343-4.287 4.343H8.74z"
            ></path>
        </g>
        <defs>
            <clipPath id="clip0_3138_12770">
                <path fill="#fff" d="M0 0H65V47.27H0z"></path>
            </clipPath>
        </defs>
    </svg>
)
export default CreditCard
