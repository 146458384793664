import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Modal, Row} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { dateToTimeValue, END_OF_DAY, timeValueToDate } from '../../../helpers/date';
import { DAYS_OF_WEEK } from '../../../helpers/enums';
import translate from '../../../helpers/translations';
import './CenterOpeningHoursModal.css';

interface Props {
  show: boolean;
  openingHours: OpeningHours | null;
  onHide: () => any;
  refresh: () => any;
  editingIndex: number | null;
  handleAdd: (newOpeningHour: OpeningHours) => void;
  handleEdit: (index: number, editedHour: OpeningHours) => void;
}

const CenterOpeningHoursModal: React.FC<Props> = ({ show, openingHours, onHide, refresh, editingIndex, handleAdd, handleEdit }) => {
  const [form, setForm] = useState<OpeningHours>({ dayOfWeek: 'MONDAY', from: '', to: '' });
  const [validationErrors, setValidationErrors] = useState<boolean>(false);

  useEffect(() => {
    if (openingHours) {
      setForm({ dayOfWeek: openingHours.dayOfWeek, from: openingHours.from, to: openingHours.to });
    } else {
      setForm({ dayOfWeek: 'MONDAY', from: '', to: ''})
    }
  }, [openingHours]);

  const submit = () => {
    if(validateForm()) {
      if (editingIndex !== null) {
        handleEdit(editingIndex, form)
      } else {
        handleAdd(form)
      }
      setValidationErrors(false)
    }
  }

  const validateForm = () => {
    if (
        !form.from ||
        !form.to ||
        form.from && form.to && form.from >= form.to
    ) {
      setValidationErrors(true)
      return false;
    }
    return true;
  };

  return <>
    <Modal show={show}>
      <Modal.Header><strong>{translate(openingHours ? 'editOpeningHours' : 'addOpeningHours')}</strong></Modal.Header>
      <Modal.Body>
        <Form>
          <Container>
            <Row>
              <Col>
                <Form.Select value={form.dayOfWeek} onChange={e => setForm({ ...form, dayOfWeek: (e.target.value as DayOfWeek) })}>
                  {DAYS_OF_WEEK.map(dow => <option key={dow} value={dow}>{translate(dow)}</option>)}
                </Form.Select>
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col xs={6}>
                <DatePicker
                  placeholderText={translate('from')}
                  selected={form.from ? timeValueToDate(form.from) : null}
                  onChange={date => setForm({ ...form, from: date ? dateToTimeValue(date) : '' })}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  dateFormat='HH:mm'
                  timeFormat='HH:mm'
                  customInput={<Form.Control />}
                />
              </Col>
              <Col xs={6}>
                <DatePicker
                  placeholderText={translate('to')}
                  selected={form.to ? timeValueToDate(form.to) : null}
                  onChange={date => setForm({ ...form, to: date ? dateToTimeValue(date) : '' })}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  dateFormat='HH:mm'
                  timeFormat='HH:mm'
                  injectTimes={[END_OF_DAY]}
                  customInput={<Form.Control />}
                />
              </Col>
            </Row>
            {validationErrors &&
                <Row>
                  <div className="text-danger">{translate('errorOccurred')}</div>
                </Row>
            }
          </Container>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={() => {onHide(); setValidationErrors(false)}}>{translate('close')}</Button>
        <Button variant='primary' onClick={() => submit()}>{translate('save')}</Button>
      </Modal.Footer>
    </Modal>
  </>;
}

export default CenterOpeningHoursModal;