import * as React from "react"
import { SVGProps } from "react"

const Person = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width ? props.width : "16"}
        height={props.height ? props.height : "20"}
        fill="current"
        viewBox="0 0 29 36"
        {...props}
    >
        <g clipPath="url(#clip0_3061_15621)">
            <path
                fill="current"
                d="M12.001 17.787c3.268 0 5.932-2.91 5.932-6.454 0-3.515-2.637-6.275-5.932-6.275-3.254 0-5.932 2.815-5.932 6.303.014 3.529 2.678 6.426 5.932 6.426zm0-2.073c-2.005 0-3.721-1.923-3.721-4.353 0-2.39 1.689-4.23 3.721-4.23 2.06 0 3.721 1.813 3.721 4.202 0 2.43-1.689 4.38-3.721 4.38zM3.378 30.488H15.42a6.286 6.286 0 01-.137-1.4v-.673H2.719c-.357 0-.508-.096-.508-.384 0-2.252 3.487-6.468 9.79-6.468 1.524 0 2.897.275 4.05.714.358-.659.934-1.208 1.73-1.565-1.633-.742-3.57-1.222-5.78-1.222C4.545 19.49 0 24.694 0 28.29c0 1.51 1.085 2.197 3.378 2.197zm13.786-1.345c0 2.636 1.538 3.597 5.272 5.78.33.206.852.248 1.277 0C27.448 32.726 29 31.78 29 29.143v-4.944c0-.796-.302-1.318-1.112-1.661-.81-.33-3.35-1.236-4.133-1.51a2.097 2.097 0 00-1.47.054c-.672.248-3.212 1.126-4.009 1.456-.81.343-1.112.865-1.112 1.661v4.944zm5.094 2.636a.93.93 0 01-.686-.302l-2.335-2.54a1.01 1.01 0 01-.22-.632c0-.48.371-.865.852-.865.288 0 .508.137.659.302l1.689 1.826 3.35-4.64a.85.85 0 01.728-.385c.453 0 .865.37.865.851a.904.904 0 01-.192.508l-3.996 5.547a.91.91 0 01-.714.33z"
            ></path>
        </g>
        <defs>
            <clipPath id="clip0_3061_15621">
                <path fill="#fff" d="M0 0H29V35.094H0z"></path>
            </clipPath>
        </defs>
    </svg>
)
export default Person
