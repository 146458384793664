import React from 'react';
import {BrowserRouter, Route, Routes, Navigate} from 'react-router-dom';
import {useAuth} from './helpers/auth';
import Centers from './screens/Centers/Centers';
import Center from './screens/Center/Center';
import Users from './screens/Users/Users';
import Login from './screens/Login';
import ToastNotifications from './components/ToastNotifications';
import Error from './screens/Error';
import {ConfigurationProvider} from './helpers/configuration';
import SearchReservations from "./screens/Center/SearchReservations/SearchReservations";
import {SPORTS} from "./helpers/enums";
import SRNavigation from "./components/NavMenu/SRNavigation";
import {NavMenuProvider} from "./components/NavMenu/NavMenuProvider";
import CenterInformation from "./screens/Center/Information/CenterInformation";
import CacheStatistics from "./screens/Cache/CacheStatistics";
import NotificationRequests from "./screens/NotificationRequests/NotificationRequests";


const App: React.FC = () => {
    const {person} = useAuth();

    const DefaultRouteElement: React.FC = () => {
        if(person && person.role === 'ADMINISTRATOR') {
            return <Navigate to={"/centers"}/>
        }
        if(person && person.role === 'SPORTS_CENTER' && person.sportsCenters && person.sportsCenters.length > 0) {
            return <Navigate to={"/center/".concat(person.sportsCenters[0].id.toString())}/>
        }
        return <Error children='The page you are looking for does not exist.'/>
    }

    return (
        <ConfigurationProvider>
            <NavMenuProvider>
                <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
                    {person ? null : <Login/>}
                    {person && (person.role === 'ADMINISTRATOR' || person.role === 'SPORTS_CENTER') &&  <>
                        <SRNavigation>
                            <Routes>
                                <Route path='/' element={<DefaultRouteElement/>}/>

                                {person.role === 'ADMINISTRATOR' &&
                                    <>
                                        <Route path='cache' element={<CacheStatistics/>}/>
                                        <Route path='users' element={<Users/>}/>
                                        <Route path='reservations' element={<SearchReservations sports={[...SPORTS] as Sport[]}/>}/>
                                        <Route path='centers' element={<Centers/>}/>
                                        <Route path='notification-requests' element={<NotificationRequests/>}/>
                                        <Route path='center/new' element={<CenterInformation/>}/>
                                    </>
                                }

                                <Route path='center/:id' element={<Center/>}/>

                                <Route path="*" element={<DefaultRouteElement/>}/>
                            </Routes>
                        </SRNavigation>
                    </>}
                    <ToastNotifications/>
                </BrowserRouter>
            </NavMenuProvider>
        </ConfigurationProvider>
    )
};

export default App;
