import React, {useEffect, useState} from 'react';
import {Alert, Button, Col, Form, Row, Table} from 'react-bootstrap';
import translate from '../../../helpers/translations';
import {getHalfHourSports, updateHalfHourSports} from '../../../helpers/api';
import {DAYS_OF_WEEK_SHORT} from "../../../helpers/enums";
import SRTable from '../../../components/SRTable';

interface Props {
  sportsCenter: SportsCenter;
  refresh: () => any;
}

const HalfHourReservations: React.FC<Props> = ({ sportsCenter, refresh }) => {
  const [isModified, setIsModified] = useState<boolean>(false);
  const [sports, setSports] = useState<Sport[]>([]);
  const [form, setForm] = useState<HalfHourSport[]>([]);
  const [halfHourSports, setHalfHourSports] = useState<HalfHourSport[]>([]);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  useEffect(() => {
    setSports(
        Array.from(new Set(
            sportsCenter.offers
                .flatMap(o => o.courts)
                .flatMap(c => c.sports)
        ))
    )

    getHalfHourSports(sportsCenter.id)
        .then(({ data }) => {
          setHalfHourSports(data)
          setForm(data)
        })

  }, [sportsCenter]);

  useEffect(() => {
    setIsModified(!arraysEqual(halfHourSports, form))
  }, [sportsCenter.sportsCenterPaymentMethods, form]);

  const compareHalfHourSport = (a: HalfHourSport, b: HalfHourSport) => {
    return a.sport.localeCompare(b.sport);
  }
  function arraysEqual(a: HalfHourSport[], b: HalfHourSport[]) {
    for(let i = 0; i < a.length; i++) {
      a[i].days.sort()
    }
    a.sort(compareHalfHourSport)
    b.sort(compareHalfHourSport)
    return JSON.stringify(a) === JSON.stringify(b)
  }

  const isSportChecked = (s: Sport, hhs: HalfHourSport[]) => {
    return hhs.findIndex(hs => hs.sport === s) > -1
  }

  const isDayChecked = (s: Sport, day: number, hhs: HalfHourSport[]) => {
    return hhs.findIndex(hs => hs.sport === s && hs.days && hs.days.findIndex(d => d == day) > -1) > -1
  }

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>, sport: Sport) => {
    if(!e.target.checked) {
      setForm(form.filter(pm => !(pm.sport === sport)))
    } else {
      setForm([...form, {sportsCenterId: sportsCenter.id, sport: sport, halfHourSlot: true, days: [1, 2, 3, 4, 5, 6, 7]}])
    }
  }

  const handleOnDayChange = (e: React.ChangeEvent<HTMLInputElement>, sport: Sport, day: number) => {
    let hhs: HalfHourSport = structuredClone(form.filter(pm => (pm.sport === sport))[0])
    if(hhs) {
      hhs.days = !e.target.checked ?
          hhs.days.filter(d => d != day) :
          hhs.days = [...hhs.days, day]
      setForm([...form.filter(pm => !(pm.sport === sport)), hhs])
    }
  }

  const handleSave = () => {
    if(!isFormValid(form)) {
      setErrorMsg(translate('unexpectedError') + '.');
      return;
    }

    updateHalfHourSports(sportsCenter.id, {halfHourSports: form})
        .then(() => refresh())
        .catch(({ response: { data } }) => {
          if (data && data.message) {
            setErrorMsg(data.message);
          } else {
            setErrorMsg(translate('unexpectedError') + '.');
          }
        })
  }

  const isFormValid = (hhs: HalfHourSport[]) => {
    for (let i = 0; i < hhs.length; i++) {
      if(sports.find(s => s === hhs[i].sport) === undefined) {
        return false
      }
    }
    return true;
  }

  return <div className='d-flex flex-column p-2'>
    {sports.length !== 0 && <>
      {errorMsg &&
          <Row>
            <Col>
              <Alert variant='danger' className='mt-2' style={{ fontSize: '0.85rem' }}>
                <div style={{ fontWeight: '700' }}>{errorMsg}</div>
              </Alert>
            </Col>
          </Row>
      }
        <SRTable>
          <thead>
            <tr>
              <th>{translate('sport')}</th>
              <th>{translate('enabled')}</th>
              {
                [1, 2, 3, 4, 5, 6, 7].map(day => <th>{translate(DAYS_OF_WEEK_SHORT[day - 1])}</th>)
              }
            </tr>
            </thead>
            <tbody>
            {sports.map(s =>
                <tr key={s}>
                  <td>{translate(s)}</td>
                  <td>
                  <Form.Check type="switch" checked={isSportChecked(s, form)}
                                onChange={e => handleOnChange(e, s)}
                    />
                  </td>
                  {
                    [1, 2, 3, 4, 5, 6, 7].map(day =>
                        <td key={day}>
                          {isSportChecked(s, form) &&
                              <Form.Check checked={isDayChecked(s, day, form)}
                                          onChange={e => handleOnDayChange(e, s, day)}
                              />
                          }
                        </td>
                    )
                  }
                </tr>)
            }
          </tbody>
        </SRTable>
      <Row className="mt-2">
        <Col>
          <div className='d-flex justify-content-end mt-2'>
            <Button onClick={handleSave} disabled={!isModified} variant='primary' style={{ width: '8rem' }} >{translate('save')}</Button>
          </div>
        </Col>
      </Row>
    </>}
    {sports.length === 0 && <h4 className='text-muted my-5 text-center'>{translate('noResults')}</h4>}
  </div>;
}

export default HalfHourReservations;