import React, { useState } from 'react';
import { Alert, Button, Col, Row, Spinner, Table } from 'react-bootstrap';
import { updateCourtActive } from '../../../helpers/api';
import { useToastNotifications } from '../../../helpers/notifications';
import translate from '../../../helpers/translations';
import CenterCourtModal from './CenterCourtModal';

interface Props {
  sportsCenterId: number;
  courts: Court[];
  refresh: () => any;
}

const CenterCourts: React.FC<Props> = ({ sportsCenterId, courts, refresh }) => {
  const { newToastNotification } = useToastNotifications();
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [editingCourt, setEditingCourt] = useState<Court | null>(null);
  const [settingActive, setSettingActive] = useState<number | null>(null);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const setActive = (courtId: number, active: boolean) => {
    setSettingActive(courtId);
    updateCourtActive(sportsCenterId, courtId, active)
      .then(() => {
        setSettingActive(null);
        refresh();
        newToastNotification(translate(active ? 'activateCourt' : 'deactivateCourt'), translate('operationSuccessful') + '.')
      })
      .catch(({ response: { data } }) => {
        setSettingActive(null);
        if (data && data.message) {
          setErrorMsg(data.message);
        } else {
          setErrorMsg(translate('unexpectedError') + '.');
        }
      })
  }

  return <>
    {errorMsg && <Row>
      <Col className='mt-2'>
        <Alert variant='danger' className='mt-2' style={{ fontSize: '0.85rem' }}>
          <div style={{ fontWeight: '700' }}>{errorMsg}</div>
        </Alert>
      </Col>
    </Row>}
    <div className='d-flex flex-column'>
      {courts.length !== 0 &&
          <div className='my-4 table-responsive card shadow sr-table'>
            <Table className='m-0 align-middle'>
              <thead>
              <tr>
                <th>{translate('id')}</th>
                <th>{translate('name')}</th>
                <th style={{textAlign: 'center'}}>{translate('number')}</th>
                <th style={{textAlign: 'center'}}>{translate('dimensions')}</th>
                <th style={{textAlign: 'center'}}>{translate('courtType')}</th>
                <th style={{textAlign: 'center'}}>{translate('surfaceType')}</th>
                <th style={{textAlign: 'center'}}>{translate('sports')}</th>
                <th/>
              </tr>
              </thead>
              <tbody>
              {courts.length !== 0 &&
                  courts
                      .map(c => <tr key={c.id}>
                        <td>{c.id}</td>
                        <td>{c.name}</td>
                        <td style={{textAlign: 'center'}}>{c.number || '-'}</td>
                        <td style={{textAlign: 'center'}}>{c.length || ''}{c.length && c.width && 'x'}{c.width || ''}</td>
                        <td style={{textAlign: 'center'}}>{translate(c.courtType)}</td>
                        <td style={{textAlign: 'center'}}>{translate(c.surfaceType)}</td>
                        <td style={{textAlign: 'center'}}>
                          {c.sports.map(s => <div key={s}>{translate(s)}</div>)}
                        </td>
                        <td className='text-end' style={{whiteSpace: 'nowrap'}}>
                          <Button size="sm" variant='secondary' className='mx-1' onClick={() => {
                            setEditingCourt(c);
                            setShowEditModal(true);
                          }}>{translate('edit')}</Button>
                          {c.active && <Button size="sm" variant='danger' className='mx-1' style={{width: '100px'}}
                                               onClick={() => setActive(c.id, false)}>
                            {settingActive === c.id ?
                                <Spinner animation='border' as='span' size='sm'/> : translate('deactivate')}
                          </Button>}
                          {c.active || <Button size="sm" variant='success' className='mx-1' style={{width: '100px'}}
                                               onClick={() => setActive(c.id, true)}>
                            {settingActive === c.id ?
                                <Spinner animation='border' as='span' size='sm'/> : translate('activate')}
                          </Button>}
                        </td>
                      </tr>)}
              </tbody>
            </Table>
          </div>
      }
      {courts.length === 0 && <h4 className='text-muted my-5 text-center'>{translate('noCourtsAvailable')}</h4>}
      <Button className='mx-auto px-5' variant='secondary' onClick={() => { setEditingCourt(null); setShowEditModal(true); }}>{translate('addCourt')}</Button>
      <CenterCourtModal
        show={showEditModal}
        court={editingCourt}
        sportsCenterId={sportsCenterId}
        onHide={() => setShowEditModal(false)}
        refresh={refresh} />
    </div>
  </>;
}

export default CenterCourts;