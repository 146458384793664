import * as React from "react"
import { SVGProps } from "react"

const Iphone = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width ? props.width : "16"}
        height={props.height ? props.height : "16"}
        viewBox="0 0 36 59"
    >
        <g clipPath="url(#clip0_3138_12747)">
            <path
                fill="transparent"
                d="M8.083 53.956c-2.345 0-3.642-1.242-3.642-3.449V7.89c0-2.207 1.297-3.448 3.642-3.448h19.309c2.317 0 3.613 1.241 3.613 3.448v42.618c0 2.207-1.296 3.449-3.613 3.449H8.082z"
            ></path>
            <path
                fill={props.fill ? props.fill : "current"}
                d="M7.503 58.396h20.44c4.497 0 7.503-2.868 7.503-7.172V7.172C35.446 2.869 32.44 0 27.943 0H7.503C3.007 0 0 2.869 0 7.172v44.052c0 4.304 3.007 7.172 7.503 7.172zm.58-4.44c-2.345 0-3.642-1.242-3.642-3.449V7.89c0-2.207 1.297-3.448 3.641-3.448h19.31c2.317 0 3.613 1.241 3.613 3.448v42.618c0 2.207-1.296 3.448-3.614 3.448H8.082zm3.806-2.014h11.723c.745 0 1.27-.525 1.27-1.297 0-.772-.525-1.269-1.27-1.269H11.89c-.745 0-1.296.497-1.296 1.27 0 .771.551 1.296 1.296 1.296zm2.51-41.432h6.676c1.075 0 1.93-.855 1.93-1.959 0-1.076-.855-1.93-1.93-1.93h-6.676c-1.103 0-1.958.854-1.958 1.93 0 1.104.855 1.959 1.958 1.959z"
            ></path>
        </g>
        <defs>
            <clipPath id="clip0_3138_12747">
                <path fill="#fff" d="M0 0H35.446V58.452H0z"></path>
            </clipPath>
        </defs>
    </svg>
)
export default Iphone
