import axios from 'axios';

const api = axios.create({
  baseURL: window.location.origin
});

api.interceptors.request.use(request => {
  return {
    ...request,
    headers: {
      ...(request.headers || {}),
      'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
      'Accept-Language': localStorage.getItem('language') || 'HR'
    }
  }
});

api.interceptors.response.use(response => response, async (error) => {
  if (axios.isAxiosError(error) && error.response?.status === 401) {
    return (async () => {
      const response = await refresh();
      if (response.status === 200) {
        const { accessToken } = response.data;
        localStorage.setItem('accessToken', accessToken);
        if (error.config.headers?.Authorization) {
          error.config.headers.Authorization = `Bearer ${accessToken}`;
          return api(error.config);
        }
      }
      throw error;
    })();
  }
  throw error;
});

// auth
export const login = (email: string, password: string) => api.post<Tokens>(`/auth/login`, { email, password });
export const refresh = () => api.get<AccessToken>(`/auth/refresh?refreshToken=${localStorage.getItem('refreshToken')}`);

// configuration
export const getConfiguration = () => api.get<Configuration>(`/api/web/configuration`);

// person
export const getProfile = () => api.get<Person>(`/api/person/me`);
export const getPersons = (query: string, role: string | null, pageNumber: number, pageSize: number) => api.get<Person[]>(`/api/person`, { params: { query, role, pageNumber, pageSize } });
export const createSportsCenterAdministrator = (data: any) => api.post('/api/person/', data);
export const deletePerson = (id: number) => api.delete(`/api/person/${id}`);

// reservations
export const getReservationsByTimeRange = (sportsCenterId: number, dayOfWeek: number, from: string, to: string) => api.get<ReservationCourtPerson[]>(`/api/reservation/by-time-range?sportsCenterId=${sportsCenterId}&dayOfWeek=${dayOfWeek}&from=${from}&to=${to}`);
export const getReservationsOutsideOpeningHours = (sportsCenterId: number, openingHours: OpeningHours[]) => api.post<ReservationCourtPerson[]>(`/api/reservation/outside-opening-hours`,{sportsCenterId: sportsCenterId, openingHours: openingHours});
export const getReservationsByDateTimeRange = (sportsCenterId: number, from: string, to: string, courtId?: number) => api.get<ReservationCourtPerson[]>(`/api/reservation/by-date-time-range?sportsCenterId=${sportsCenterId}&from=${from}&to=${to}&courtId=${courtId || ''}`);
export const getReservationsByCriteria = (data: any) => api.post<ReservationCourtPerson[]>(`/api/reservation/criteria`, data);
export const createReservation = (data: any) => api.post<ReservationCourtSportsCenter>(`api/reservation`, data);
export const updateReservation = (data: any) => api.put<ReservationCourtSportsCenter>(`api/reservation`, data);
export const createRecurringReservation = (data: any) => api.post<number>(`api/reservation/recurring`, data);
export const deleteReservation = (id: number, reason: string) => api.delete(`api/reservation/${id}?reason=${reason}`);
export const getRecurringReservations = (sportsCenterId: number, sport: string, active: boolean) => api.get<RecurringReservation[]>(`api/reservation/recurring?sportsCenterId=${sportsCenterId}&active=${active}&sport=${sport}`);
export const deleteRecurringReservations = (id: number) => api.delete(`api/reservation/recurring/${id}`);

// sports center
export const getSportsCenters = () => api.get<SportsCenter[]>('/api/sports-center?visibleOnly=false');
export const getSportsCentersLabels = () => api.get<SportsCenterLabel[]>('/api/sports-center/labels');
export const getSportsCentersLabel = (id: string | number) => api.get<SportsCenterLabel>(`/api/sports-center/labels/${id}`);
export const getSportsCenter = (id: string | number) => api.get<SportsCenter>(`/api/sports-center/${id}?offers=true`)
export const searchSportsCenters = (data: any) => api.post<SearchResult[]>(`/api/sports-center/search/admin`, data);

export const createSportsCenter = (data: any) => api.post(`/api/sports-center`, data);
export const updateSportsCenter = (id: number, data: any) => api.put(`/api/sports-center/${id}`, data);
export const deleteSportsCenter = (id: number) => api.delete(`/api/sports-center/${id}`);

export const createOpeningHours = (sportsCenterId: number, data: OpeningHours[]) => api.post<OpeningHours[]>(`/api/web/sports-center/${sportsCenterId}/opening-hours`, data);
export const createCourt = (sportsCenterId: number, data: any) => api.post(`/api/sports-center/${sportsCenterId}/court`, data);
export const updateCourt = (sportsCenterId: number, courtId: number, data: any) => api.put(`/api/sports-center/${sportsCenterId}/court/${courtId}`, data);
export const updateCourtActive = (sportsCenterId: number, courtId: number, active: boolean) => api.put(`/api/sports-center/${sportsCenterId}/court/${courtId}/active?active=${active}`);
export const deleteCourt = (sportsCenterId: number, courtId: number) => api.delete(`/api/sports-center/${sportsCenterId}/court/${courtId}`);

export const createOffer = (sportsCenterId: number, data: any) => api.post(`/api/sports-center/${sportsCenterId}/offer`, data);
export const updateOffer = (sportsCenterId: number, offerId: number, data: any) => api.put(`/api/sports-center/${sportsCenterId}/offer/${offerId}`, data);
export const deleteOffer = (sportsCenterId: number, offerId: number) => api.delete(`/api/sports-center/${sportsCenterId}/offer/${offerId}`);
export const getHalfHourSports = (sportsCenterId: number) => api.get(`/api/sports-center/${sportsCenterId}/half-hour-sports`);
export const updateHalfHourSports = (sportsCenterId: number, data: any) => api.put(`/api/sports-center/${sportsCenterId}/half-hour-sports`, data);
export const uploadCoverImage = (sportsCenterId: number, data: any) => api.post(`/api/sports-center/${sportsCenterId}/cover-image`, data);
export const deleteCoverImage = (sportsCenterId: number) => api.delete(`/api/sports-center/${sportsCenterId}/cover-image`);
export const uploadImage = (data: any) => api.post(`/api/image`, data);
export const deleteImage = (id: string, sportsCenterId: number) => api.delete(`/api/image/${id}?sportsCenterId=${sportsCenterId}`);
export const updateSportsCenterImagesOrder = (sportsCenterId: number, data: any) => api.put(`/api/sports-center/${sportsCenterId}/images/order`, data);
export const getSportsCenterImages = (sportsCenterId: number) => api.get(`/api/sports-center/${sportsCenterId}/images`);
export const sendNotification = (data: any) => api.post<number>(`api/fcm/send-notification`, data);
export const saveReservationColors = (sportsCenterId: number, data: IReservationColors) => api.post<IReservationColors>(`api/web/sports-center/${sportsCenterId}/reservation-colors`, data);
export const createTimeOff = (sportsCenterId: number, data: any) => api.post<TimeOff>(`/api/web/sports-center/${sportsCenterId}/time-off`, data);
export const updateTimeOff = (sportsCenterId: number, timeOffId: number, data: any) => api.put<TimeOff>(`/api/web/sports-center/${sportsCenterId}/time-off/${timeOffId}`, data);
export const deleteTimeOff = (sportsCenterId: number, timeOffId: number) => api.delete(`/api/web/sports-center/${sportsCenterId}/time-off/${timeOffId}`);
export const getCacheStats = () => api.get(`api/web/admin/cache/stats`);
export const clearCache = (cacheName: string) => api.put(`api/web/admin/cache/clear/${cacheName}`);
export const clearCacheFull = () => api.put(`api/web/admin/cache/clear`);

const getSportsCenterUsersQueryString = (filter?: SportsCenterUserFilter) => {
  let query = ''
  if(filter?.user) query += '&user=' + filter.user
  if(filter?.isBlocked != undefined) query += '&isBlocked=' + filter.isBlocked
  if(filter?.isPremium != undefined) query += '&isPremium=' + filter.isPremium
  if(filter?.sportsCenterId) query += '&sportsCenterId=' + filter.sportsCenterId
  return query;
}

const getSportsCenterNotificationRequestsQueryString = (filter?: SportsCenterNotificationRequestsFilter) => {
  let query = ''
  if(filter?.status) query += '&status=' + filter.status
  if(filter?.sportsCenterId) query += '&sportsCenterId=' + filter.sportsCenterId
  return query;
}


export const saveSportsCenterNotificationRequest = (sportsCenterId: number, data: any) => api.post(`/api/web/sports-center/${sportsCenterId}/notification-request`, data);
export const getSportsCenterNotificationRequests = (sportsCenterId: number, page: number, size: number, status?: string) => api.get<SportsCenterNotificationRequests[]>(`/api/web/sports-center/${sportsCenterId}/notification-request?page=${page}&size=${size}${status ? '&status=' + status : ''}`);
export const getAllSportsCenterNotificationRequests = (page: number, size: number, filter?: SportsCenterNotificationRequestsFilter) => api.get<SportsCenterNotificationRequests[]>(`/api/web/sports-center-notification-request?page=${page}&size=${size}${getSportsCenterNotificationRequestsQueryString(filter)}`);
export const updateNotificationRequestStatus = (sportsCenterId: number, requestId: number, status: string, reason?: string) => api.put(`/api/web/sports-center/${sportsCenterId}/notification-request/${requestId}/status/${status}${reason ? '?reason=' + reason : ''}`);
export const getSportsCenterUsers = (sportsCenterId: number, page: number, size: number, filter?: SportsCenterUserFilter) => api.get<SportsCenterUser[]>(`/api/web/sports-center/${sportsCenterId}/users?page=${page}&size=${size}${getSportsCenterUsersQueryString(filter)}`);
export const setUserAsPremium = (sportsCenterId: number, userId: number) => api.put<void>(`/api/web/sports-center/${sportsCenterId}/premium-users/user/${userId}`);
export const removeUserAsPremium = (sportsCenterId: number, userId: number) => api.delete<void>(`/api/web/sports-center/${sportsCenterId}/premium-users/user/${userId}`);
export const blockUser = (sportsCenterId: number, userId: number) => api.put<void>(`/api/web/sports-center/${sportsCenterId}/blocked-users/user/${userId}`);
export const unBlockUser = (sportsCenterId: number, userId: number) => api.delete<void>(`/api/web/sports-center/${sportsCenterId}/blocked-users/user/${userId}`);
export const updatePaymentMethods = (sportsCenterId: number, data: any) => api.post(`/api/web/sports-center/${sportsCenterId}/payment-methods`, data);
export const updateReservationCancellationPolicy = (sportsCenterId: number, data: any) => api.post(`/api/web/admin/sports-center/${sportsCenterId}/reservation-cancellation-policy`, data);
