import React, { useEffect, useState } from 'react';
import { Alert, Button, Modal, Row, Form, Container, Col, Spinner } from 'react-bootstrap';
import { createCourt, updateCourt } from '../../../helpers/api';
import { COURT_TYPES, SPORTS, SURFACE_TYPES } from '../../../helpers/enums';
import { useToastNotifications } from '../../../helpers/notifications';
import Select from 'react-select';
import translate from '../../../helpers/translations';

interface Props {
  show: boolean;
  court: Court | null;
  sportsCenterId: number;
  onHide: () => any;
  refresh: () => any;
}

interface FormValues {
  name: string,
  number: number | null;
  length: number | null;
  width: number | null;
  courtType: CourtType;
  surfaceType: SurfaceType;
  sports: Sport[];
}

const CenterCourtModal: React.FC<Props> = ({ show, court, sportsCenterId, onHide, refresh }) => {
  const { newToastNotification } = useToastNotifications();
  const [form, setForm] = useState<FormValues>({ name: '', number: null, length: null, width: null, courtType: 'INDOOR', surfaceType: 'ARTIFICIAL_GRASS', sports: [] });
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  useEffect(() => {
    setForm({
      name: court?.name || '',
      number: court?.number || null,
      length: court?.length || null,
      width: court?.width || null,
      courtType: court?.courtType || 'INDOOR',
      surfaceType: court?.surfaceType || 'ARTIFICIAL_GRASS',
      sports: court?.sports || []
    })
  }, [court]);

  const submit = () => {
    setLoading(true);
    (court ? updateCourt(sportsCenterId, court.id, form) : createCourt(sportsCenterId, form))
      .then(() => {
        setLoading(false);
        onHide();
        refresh();
        newToastNotification(translate(court ? 'editCourt' : 'addCourt'), translate('operationSuccessful') + '.');
      })
      .catch(({ response: { data } }) => {
        setLoading(false);
        if (data && data.message) {
          setErrorMsg(data.message);
        } else {
          setErrorMsg(translate('unexpectedError') + '.');
        }
      })
  }

  return <Modal show={show}>
    <Modal.Header><strong>{translate(court ? 'editCourt' : 'addCourt')}</strong></Modal.Header>
    <Modal.Body>
      <Form>
        <Container>
          {errorMsg && <Row>
            <Col className='mt-2'>
              <Alert variant='danger' className='mt-2' style={{ fontSize: '0.85rem' }}>
                <div style={{ fontWeight: '700' }}>{errorMsg}</div>
              </Alert>
            </Col>
          </Row>}
          <Row>
            <Col>
              <Form.Label>{translate('name')}</Form.Label>
              <Form.Control value={form.name} onChange={e => setForm({ ...form, name: e.target.value })} />
            </Col>
          </Row>
          <Row className='mt-2'>
            <Col>
              <Form.Group>
                <Form.Label>{translate('number')}</Form.Label>
                <Form.Control type='number' value={form.number === null ? '' : form.number} onChange={e => setForm({ ...form, number: e.target.value === '' ? null : parseInt(e.target.value) })} />
              </Form.Group>
            </Col>
          </Row>
          <Row className='mt-2'>
            <Col md={6}>
              <Form.Group>
                <Form.Label>{translate('length')}</Form.Label>
                <Form.Control type='number' value={form.length === null ? '' : form.length} onChange={e => setForm({ ...form, length: e.target.value === '' ? null : parseInt(e.target.value) })} />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>{translate('width')}</Form.Label>
                <Form.Control type='number' value={form.width === null ? '' : form.width} onChange={e => setForm({ ...form, width: e.target.value === '' ? null : parseInt(e.target.value) })} />
              </Form.Group>
            </Col>
          </Row>
          <Row className='mt-2'>
            <Col>
              <Form.Label>{translate('courtType')}</Form.Label>
              <Form.Select
                value={form.courtType}
                onChange={e => setForm({ ...form, courtType: (e.target.value as CourtType)})}>
                {COURT_TYPES.map(ct => <option key={ct} value={ct}>{translate(ct)}</option>)}
              </Form.Select>
            </Col>
          </Row>
          <Row className='mt-2'>
            <Col>
              <Form.Label>{translate('surfaceType')}</Form.Label>
              <Form.Select
                value={form.surfaceType}
                onChange={e => setForm({ ...form, surfaceType: (e.target.value as SurfaceType)})}>
                {SURFACE_TYPES.map(st => <option key={st} value={st}>{translate(st)}</option>)}
              </Form.Select>
            </Col>
          </Row>
          <Row className='mt-2'>
            <Col>
              <Form.Label>{translate('sports')}</Form.Label>
              <Select
                isMulti
                options={SPORTS.map(s => ({label: translate(s), value: (s as Sport)}))}
                value={form.sports.map(s => ({label: translate(s), value: s}))}
                onChange={selected => setForm({ ...form, sports: selected.map(s => s.value) })}
                />
            </Col>
          </Row>
        </Container>
      </Form>
    </Modal.Body>
    <Modal.Footer>
      {loading && <Spinner animation='border' variant='secondary' className='mx-auto' />}
      {!loading && <>
        <Button variant='secondary' onClick={() => onHide()}>{translate('close')}</Button>
        <Button variant='primary' onClick={() => submit()}>{translate('save')}</Button>
      </>}
    </Modal.Footer>
  </Modal>
}

export default CenterCourtModal;