import React, {useEffect, useState} from 'react';
import {Alert, Button, Col, Form, Row, Table} from 'react-bootstrap';
import translate from '../../../helpers/translations';
import {updatePaymentMethods} from '../../../helpers/api';
import SRTable from "../../../components/SRTable";

interface Props {
  sportsCenter: SportsCenter;
  refresh: () => any;
}

const PaymentMethods: React.FC<Props> = ({ sportsCenter, refresh }) => {
  const [isModified, setIsModified] = useState<boolean>(false);
  const [sports, setSports] = useState<Sport[]>([]);
  const [form, setForm] = useState<SportsCenterPaymentMethod[]>([]);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  useEffect(() => {
    setSports(
        Array.from(new Set(
            sportsCenter.offers
                .flatMap(o => o.courts)
                .flatMap(c => c.sports)
        ))
    )

    setForm(sportsCenter.sportsCenterPaymentMethods)
  }, [sportsCenter]);

  useEffect(() => {
    setIsModified(!arraysEqual(sportsCenter.sportsCenterPaymentMethods, form))
  }, [sportsCenter.sportsCenterPaymentMethods, form]);

  const compareSportsCenterPaymentMethod = (a: SportsCenterPaymentMethod, b: SportsCenterPaymentMethod) => {
    if (a.sportsCenterId !== b.sportsCenterId) {
      return a.sportsCenterId - b.sportsCenterId;
    } else if (a.sport !== b.sport) {
      return a.sport.localeCompare(b.sport);
    } else {
      return a.paymentMethod.localeCompare(b.paymentMethod);
    }
  }
  function arraysEqual(a: SportsCenterPaymentMethod[], b: SportsCenterPaymentMethod[]) {
    a.sort(compareSportsCenterPaymentMethod)
    b.sort(compareSportsCenterPaymentMethod)
    return JSON.stringify(a) === JSON.stringify(b)
  }

  const isChecked = (s: Sport, scpm: SportsCenterPaymentMethod[], paymentMethod: PaymentMethod) => {
    return scpm.findIndex(pm => pm.sport === s && pm.paymentMethod === paymentMethod) > -1
  }

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>, sport: Sport, paymentMethod: PaymentMethod) => {
    if(!e.target.checked) {
      setForm(form.filter(pm => !(pm.sport === sport && pm.paymentMethod === paymentMethod)))
    } else {
      setForm([...form, {sportsCenterId: sportsCenter.id, sport: sport, paymentMethod: paymentMethod}])
    }
  }

  const handleSave = () => {
    if(!isFormValid(form)) {
      setErrorMsg(translate('unexpectedError') + '.');
      return;
    }

    updatePaymentMethods(sportsCenter.id, form)
        .then(() => refresh())
        .catch(({ response: { data } }) => {
          if (data && data.message) {
            setErrorMsg(data.message);
          } else {
            setErrorMsg(translate('unexpectedError') + '.');
          }
        })
  }

  const isFormValid = (scpm: SportsCenterPaymentMethod[]) => {
    for (let i = 0; i < sports.length; i++) {
      if(scpm.find(pm => pm.sport === sports[i]) === undefined) {
        return false;
      }
    }
    return true;
  }

  return <div className='d-flex flex-column'>
    {sports.length !== 0 && <>
      {errorMsg &&
          <Row>
            <Col>
              <Alert variant='danger' className='mt-2' style={{ fontSize: '0.85rem' }}>
                <div style={{ fontWeight: '700' }}>{errorMsg}</div>
              </Alert>
            </Col>
          </Row>
      }
        <SRTable>
          <thead>
            <tr>
              <th>{translate('sport')}</th>
              <th style={{textAlign: 'center'}}>{translate('ON_SITE')}</th>
              <th style={{textAlign: 'center'}}>{translate('CARD')}</th>
            </tr>
          </thead>
          <tbody>
            {sports.map(s =>
                <tr key={s}>
                  <td>{translate(s)}</td>
                  <td style={{textAlign: 'center'}}>
                    <Form.Check checked={isChecked(s, form, "ON_SITE")}
                                onChange={e => handleOnChange(e, s, "ON_SITE")}
                    />
                  </td>
                  <td style={{textAlign: 'center'}}>
                    <Form.Check checked={isChecked(s, form, "CARD")}
                                onChange={e => handleOnChange(e, s, "CARD")}
                                disabled={!sportsCenter.stripeAccountId}
                    />
                  </td>
                </tr>)
            }
          </tbody>
        </SRTable>
      <Row>
        <Col>
          <div className='d-flex justify-content-end mb-5'>
            <Button onClick={handleSave} disabled={!isModified} variant='primary' style={{ width: '8rem' }} >{translate('save')}</Button>
          </div>
        </Col>
      </Row>
    </>}
    {sports.length === 0 && <h4 className='text-muted my-5 text-center'>{translate('noResults')}</h4>}
  </div>;
}

export default PaymentMethods;