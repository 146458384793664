import React from 'react';
import { Alert, Button, Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import translate from '../helpers/translations';

const Error: React.FC<{ children: any }> = ({ children }) => {
  const navigate = useNavigate();

  return <div className='bg-light' style={{ height: '100vh', width: '100vw' }}>
    <Container className='h-100'>
      <Row className='h-100'>
        <Col className='mx-auto my-auto'>
          <Alert variant='dark'>
            <div className='mb-1' style={{ fontWeight: '700' }}>{translate('errorOccurred')}.</div>
            {children}
            <div className='mt-4'>
              <Button variant='outline-dark' onClick={() => navigate('/')}>{translate('goToHomepage')}</Button>
            </div>
          </Alert>
        </Col>
      </Row>
    </Container>
  </div>;
}

export default Error;