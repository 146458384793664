import React from 'react';
import {Badge, Modal, Table} from 'react-bootstrap';
import translate from "../../helpers/translations";
import {formatDateValue} from "../../helpers/date";

interface Props {
    request: SportsCenterNotificationRequests;
    onHide: () => any;
}



const getStatusBadge = (status: string) => {
    switch (status) {
        case 'PENDING_APPROVAL':
            return <Badge bg='info'>{translate(status)}</Badge>
        case 'CANCELED':
            return <Badge bg='warning'>{translate(status)}</Badge>
        case 'APPROVED':
            return <Badge bg='primary'>{translate(status)}</Badge>
        case 'REJECTED':
            return <Badge bg='danger'>{translate(status)}</Badge>
        case 'NOTIFICATIONS_SENT':
            return <Badge bg='success'>{translate(status)}</Badge>
        case 'EXPIRED':
            return <Badge bg='secondary'>{translate(status)}</Badge>
        default:
            return <Badge bg='secondary'>{translate(status)}</Badge>
    }
}


const NotificationRequestDetailsModal: React.FC<Props> = ({request, onHide}) => {
    return <Modal show={true} onHide={onHide} size='lg'>
        <Modal.Header closeButton><strong>{translate('notificationRequest')}</strong></Modal.Header>
        <Modal.Body className='p-4'>
            <Table style={{verticalAlign: 'middle', width: '100%', tableLayout: 'fixed'}} >
                <tbody>
                <tr>
                    <th style={{width: '30%', paddingRight: '10px'}}>{translate('sportsCenter')}</th>
                    <td style={{width: '70%'}}>{request.sportsCenter?.name}</td>
                </tr>
                <tr>
                    <th style={{width: '30%', paddingRight: '10px'}}>{translate('status')}</th>
                    <td style={{width: '70%'}}>
                        {getStatusBadge(request.status)}
                    </td>
                </tr>
                {request.status == 'REJECTED' &&
                    <tr>
                        <th style={{width: '30%', paddingRight: '10px'}}>{translate('reason')}</th>
                        <td style={{width: '70%'}}>{request.reason ? request.reason : '-'}</td>
                    </tr>
                }
                <tr>
                    <th style={{width: '30%', paddingRight: '10px'}}>{translate('title')}</th>
                    <td style={{width: '70%'}}>{request.title}</td>
                </tr>
                <tr>
                    <th style={{width: '30%', paddingRight: '10px'}}>{translate('body')}</th>
                    <td style={{width: '70%', wordWrap: 'break-word'}}>{request.body}</td>
                </tr>
                <tr>
                    <th style={{width: '30%', paddingRight: '10px'}}>{translate('sendingTime')}</th>
                    <td style={{width: '70%'}}>{request.notifyAt ? formatDateValue(request.notifyAt) : '-'}</td>
                </tr>
                <tr>
                    <th style={{width: '30%', paddingRight: '10px'}}>{translate('createdAt')}</th>
                    <td style={{width: '70%'}}>{formatDateValue(request.createdAt)}</td>
                </tr>
                <tr>
                    <th style={{width: '30%', paddingRight: '10px'}}>{translate('lastModifiedAt')}</th>
                    <td style={{width: '70%'}}>{formatDateValue(request.lastModifiedAt)}</td>
                </tr>
                <tr>
                    <th style={{width: '30%', paddingRight: '10px'}}>{translate('properties')}</th>
                    <td style={{width: '70%'}}>{Object.entries(request.properties).map(([key, value]) =>
                        <div key={key} style={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
                            {key}:&nbsp;
                            {key == 'urlToOpen' ? <a href={value} target="_blank">{value}</a> : value}
                        </div>)}
                    </td>
                </tr>
                </tbody>
            </Table>
        </Modal.Body>
    </Modal>
}

export default NotificationRequestDetailsModal;