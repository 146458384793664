import * as React from "react"
import { SVGProps } from "react"

const Repeat = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width ? props.width : "16"}
        height={props.height ? props.height : "16"}
        fill="current"
        viewBox="0 0 56 47"
        {...props}
    >
        <g clipPath="url(#clip0_3138_12756)">
            <path
                fill="current"
                d="M2.211 24.165a2.177 2.177 0 002.211-2.21v-2.472c0-4.474 3.043-7.387 7.752-7.387h20.29v6.113c0 1.04.623 1.638 1.69 1.638.468 0 .962-.182 1.327-.494l9.832-8.142c.832-.676.885-1.743 0-2.47L35.481.571c-.365-.312-.859-.468-1.327-.468-1.067 0-1.69.572-1.69 1.639v6.035H12.59C4.968 7.778 0 12.122 0 19.17v2.783c0 1.249.936 2.211 2.211 2.211zm51.114-1.846c-1.275 0-2.211.936-2.211 2.21v2.472c0 4.474-3.043 7.361-7.778 7.361h-20.29v-6.035c0-1.066-.598-1.639-1.664-1.639-.494 0-.962.157-1.353.469l-9.832 8.142c-.833.728-.859 1.768 0 2.47l9.832 8.169c.39.312.859.494 1.353.494 1.066 0 1.665-.599 1.665-1.64v-6.086H42.92c7.648 0 12.616-4.344 12.616-11.393v-2.784c0-1.274-.962-2.21-2.211-2.21z"
            ></path>
        </g>
        <defs>
            <clipPath id="clip0_3138_12756">
                <path fill="#fff" d="M0 0H55.536V46.432H0z"></path>
            </clipPath>
        </defs>
    </svg>
)
export default Repeat
