import { Spinner } from 'react-bootstrap';

function Splash() {
  return <Spinner animation='border' variant='secondary' style={{
    position: 'absolute',
    top: 'calc(50% - 1rem)',
    left: 'calc(50% - 1rem)',
    width: '2rem',
    height: '2rem',
    zIndex: 10
  }} />;
}

export default Splash;