import badminton from '../assets/sports/badminton.png';
import basketball from '../assets/sports/basketball.png';
import bowling from '../assets/sports/bowling.png';
import climbing from '../assets/sports/climbing.png';
import golf from '../assets/sports/golf.png';
import handball from '../assets/sports/handball.png';
import padel from '../assets/sports/padel.png';
import soccer from '../assets/sports/soccer.png';
import squash from '../assets/sports/squash.png';
import table_tennis from '../assets/sports/table_tennis.png';
import tennis from '../assets/sports/tennis.png';
import volleyball from '../assets/sports/volleyball.png';
import cageball from '../assets/sports/cageball.png';
import football_tennis from '../assets/sports/football_tennis.png';

export const sportIcons = {
  badminton,
  basketball,
  bowling,
  climbing,
  golf,
  handball,
  padel,
  soccer,
  squash,
  table_tennis,
  tennis,
  volleyball,
  cageball,
  football_tennis
}