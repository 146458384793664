import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Container, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { updateReservation } from '../../../helpers/api';
import {
  dateStringEquals,
  dateToDateValue,
  dateToTimeValue,
  dateValueToDate, END_OF_DAY,
  formatDateValue, subtractMinutes,
} from '../../../helpers/date';
import { useToastNotifications } from '../../../helpers/notifications';
import translate from '../../../helpers/translations';
import DatePicker from "react-datepicker";

interface Props {
  show: boolean;
  courts: Court[];
  sportsCenterId: number;
  reservation: ReservationCourtPerson;
  openingHours: OpeningHours[];
  halfHourSlot: boolean
  refresh: () => any;
  onHide: () => any;
}

const CenterReservationUpdateModal: React.FC<Props> = ({ show, courts, sportsCenterId, reservation, refresh, onHide, openingHours, halfHourSlot}) => {
  const { newToastNotification } = useToastNotifications();
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [sports, setSports] = useState<Sport[]>([]);
  const [form, setForm] = useState<ReservationUpdateForm>({
    id: reservation.id, email: reservation.person ? reservation.person.email : reservation.email, firstName: reservation.firstName, lastName: reservation.lastName,
    phoneNumber: reservation.phoneNumber, paymentSuccessful: reservation.paymentSuccessful, ignoreReservationId: reservation.id, courtId: reservation.court.id,
    from: reservation.from, to: reservation.to, sport: reservation.sport, paymentMethod: reservation.paymentMethod, currency: reservation.currency, sportsCenterId: sportsCenterId
  });

  const submit = () => {
    if (form) {
      setErrorMsg(null);
      setLoading(true);
      updateReservation(form)
        .then(() => {
          setLoading(false);
          onHide();
          refresh();
          newToastNotification(translate('editReservation'), translate('operationSuccessful') + '.');
        })
        .catch(({ response: { data } }) => {
          setLoading(false);
          if (data && data.message) {
            setErrorMsg(data.message);
          } else {
            setErrorMsg(translate('unexpectedError') + '.');
          }
        })
    }
  }

  useEffect(() => {
    setErrorMsg(null);
  }, [form])

  useEffect(() => {
    if (form) {
      const court = courts.find(c => c.id === form.courtId);
      setSports(court ? court.sports : []);
    } else {
      setSports([]);
    }
  }, [courts, form]);

  return <Modal show={show}>
    <Modal.Header className='flex-column justify-content-start align-items-start'>
      <strong>{translate('editReservation')}</strong>
      {reservation && <div className='mt-1 text-center text-muted'>
        {formatDateValue(reservation.from)} - {formatDateValue(reservation.to)}
      </div>}
    </Modal.Header>
    <Modal.Body>
      <Form>
        <Container>
          {errorMsg && <Row>
            <Col>
              <Alert variant='danger' className='mt-2' style={{ fontSize: '0.85rem' }}>
                <div style={{ fontWeight: '700' }}>{errorMsg}</div>
              </Alert>
            </Col>
          </Row>}
          {reservation.createdByRole != 'USER' &&
              <>
                <Row className='mb-3'>
                  <Col sm={6}><Form.Label>{translate('from')}</Form.Label></Col>
                  <Col sm={6}><Form.Label>{translate('to')}</Form.Label></Col>
                  <Col sm={6}>
                    <DatePicker
                        selected={form.from ? dateValueToDate(form.from) : null}
                        showTimeSelect
                        onChange={date => setForm({ ...form, from: date && dateToDateValue(date) })}
                        dateFormat='dd.MM.yyyy. HH:mm'
                        customInput={<Form.Control />}
                        timeIntervals={halfHourSlot ? 30 : 60}
                        filterTime={time => {
                          const timeString = dateToTimeValue(time);
                          return openingHours.some(oh =>  timeString >= oh.from && timeString <= oh.to);
                        }}
                    />
                  </Col>
                  <Col sm={6}>
                    <DatePicker
                        selected={form.from && form.to ? (dateStringEquals(form.from, form.to) ? dateValueToDate(form.to) : subtractMinutes(dateValueToDate(form.to), 1)) : null}
                        showTimeSelect
                        onChange={date => setForm({ ...form, to: date && dateToDateValue(date) })}
                        dateFormat='dd.MM.yyyy. HH:mm'
                        customInput={<Form.Control />}
                        timeIntervals={halfHourSlot ? 30 : 60}
                        injectTimes={[END_OF_DAY]}
                        filterTime={time => {
                          const timeString = dateToTimeValue(time);
                          return openingHours.some(oh =>  timeString >= oh.from && timeString <= oh.to);
                        }}
                    />
                  </Col>
                </Row>
                <Row className='mb-3'>
                  <Col>
                    <Form.Label>{translate('email')}</Form.Label>
                    <Form.Control value={form.email ? form.email : ''} onChange={e => form && setForm({ ...form, email: e.target.value })} />
                  </Col>
                </Row>
                <Row className='mb-3'>
                  <Col>
                    <Form.Label>{translate('firstName')}</Form.Label>
                    <Form.Control value={form.firstName ? form.firstName : ''} onChange={e => form && setForm({ ...form, firstName: e.target.value })} />
                  </Col>
                </Row>
                <Row className='mb-3'>
                  <Col>
                    <Form.Label>{translate('lastName')}</Form.Label>
                    <Form.Control value={form.lastName ? form.lastName : ''} onChange={e => form && setForm({ ...form, lastName: e.target.value })} />
                  </Col>
                </Row>
                <Row className='mb-3'>
                  <Col>
                    <Form.Label>{translate('phoneNumber')}</Form.Label>
                    <Form.Control value={form.phoneNumber ? form.phoneNumber : ''} onChange={e => form && setForm({ ...form, phoneNumber: e.target.value })} />
                  </Col>
                </Row>
              </>
          }
          <Row className='mb-3'>
            <Form.Label>{translate('court')}</Form.Label>
            <Col>
              <Form.Select value={form.courtId} onChange={e => setForm({ ...form, courtId: parseInt(e.target.value) })}>
                {courts.filter(c => c.sports.findIndex(s => s.valueOf() === form.sport) !== -1).map(c => <option key={c.id} value={c.id}>{c.name}</option>)}
              </Form.Select>
            </Col>
          </Row>
          {reservation.createdByRole != 'USER' &&
              <Row className='mb-3'>
                <Form.Label>{translate('sport')}</Form.Label>
                <Col>
                  <Form.Select value={form.sport} onChange={e => form && setForm({ ...form, sport: e.target.value })}>
                    {sports.map(s => <option key={s} value={s}>{translate(s)}</option>)}
                  </Form.Select>
                </Col>
              </Row>
          }
          {reservation.paymentMethod === "ON_SITE" &&
              <Row className='mb-3'>
                <Col md={12} className='my-2 d-flex'>
                  <Form.Label>{translate('paymentSuccessful')}&nbsp;&nbsp;</Form.Label>
                  <Form.Check checked={form.paymentSuccessful} onChange={e => form && setForm({ ...form, paymentSuccessful: ((e.target as HTMLInputElement).checked) })} />
                </Col>
              </Row>
          }
        </Container>
      </Form>
    </Modal.Body>
    <Modal.Footer>
      {loading && <Spinner animation='border' variant='secondary' className='mx-auto' />}
      {!loading && <>
        <Button variant='secondary' onClick={() => onHide()}>{translate('close')}</Button>
        <Button variant='primary'  onClick={() => submit()}>{translate('save')}</Button>
      </>}
    </Modal.Footer>
  </Modal>;
}

export default CenterReservationUpdateModal;