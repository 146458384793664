import React from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import translate from '../helpers/translations';

interface Props {
  title: string;
  body: any;
  confirmButtonVariant?: string;
  inProgress?: boolean;
  onCancel: () => any;
  onConfirm: () => any;
  dialogClassName?: string;
}

const ConfirmationModal: React.FC<Props> = ({ title, body, confirmButtonVariant = 'primary', inProgress = false, onCancel, onConfirm, dialogClassName }) => {
  return <Modal show onHide={onCancel} dialogClassName={dialogClassName}>
    <Modal.Header>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body className='my-3'>{body}</Modal.Body>
    <Modal.Footer>
      {inProgress && <Spinner animation='border' variant='secondary' className='mx-auto' />}
      {!inProgress && <>
        <Button variant='secondary' onClick={onCancel}>{translate('close')}</Button>
        <Button variant={confirmButtonVariant} onClick={onConfirm}>{translate('confirm')}</Button>
      </>}
    </Modal.Footer>
  </Modal>;
}

export default ConfirmationModal;