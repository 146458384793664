import * as React from "react"
import { SVGProps } from "react"

const Checkmark = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width ? props.width : "16"}
        height={props.height ? props.height : "16"}
        fill="current"
        viewBox="0 0 63 64"
        {...props}
    >
        <g clipPath="url(#clip0_3139_12794)">
            <path
                fill="current"
                d="M14.694 55.543h6.018c.555 0 .97.167 1.387.583l4.27 4.243c3.495 3.522 6.74 3.494 10.234 0l4.27-4.243c.444-.416.832-.583 1.415-.583h5.99c4.964 0 7.265-2.274 7.265-7.265v-5.99c0-.583.167-.97.583-1.415l4.243-4.27c3.522-3.495 3.494-6.74 0-10.234l-4.243-4.27c-.416-.416-.583-.832-.583-1.387v-6.018c0-4.936-2.274-7.265-7.265-7.265h-5.99c-.583 0-.97-.139-1.415-.555l-4.27-4.243c-3.495-3.522-6.74-3.494-10.234 0L22.1 6.874c-.416.416-.832.555-1.387.555h-6.018c-4.964 0-7.265 2.274-7.265 7.265v6.018c0 .555-.139.97-.555 1.387l-4.243 4.27c-3.522 3.495-3.494 6.74 0 10.234l4.243 4.27c.416.444.555.832.555 1.415v5.99c0 4.964 2.301 7.265 7.265 7.265zm13.7-10.51c-.943 0-1.72-.36-2.44-1.331l-6.961-8.541c-.416-.555-.666-1.193-.666-1.803 0-1.276.97-2.302 2.247-2.302.776 0 1.386.277 2.08 1.193l5.63 7.265 11.84-19.024c.527-.86 1.248-1.275 1.997-1.275 1.193 0 2.33.832 2.33 2.107 0 .638-.36 1.276-.694 1.83l-13.033 20.55c-.583.887-1.387 1.331-2.33 1.331z"
            ></path>
        </g>
        <defs>
            <clipPath id="clip0_3139_12794">
                <path fill="#fff" d="M0 0H63V63.028H0z"></path>
            </clipPath>
        </defs>
    </svg>
)
export default Checkmark
