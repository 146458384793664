import * as React from "react"
import { SVGProps } from "react"

const SportsCourt = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="35"
        height="24"
        fill="none"
        viewBox="0 0 35 24"
        {...props}
    >
        <g clipPath="url(#clip0_3061_15606)">
            <path
                fill="#313131"
                d="M4.008 23.526h26.488c2.668 0 3.995-1.328 3.995-3.957V3.983c0-2.63-1.327-3.958-3.995-3.958H4.008C1.34.025 0 1.34 0 3.983v15.586c0 2.642 1.34 3.957 4.008 3.957zm.026-2.055c-1.277 0-1.979-.677-1.979-2.004V4.085c0-1.328.702-2.004 1.979-2.004h26.423c1.264 0 1.98.676 1.98 2.004v15.382c0 1.327-.716 2.004-1.98 2.004H4.035zM0 17.45h5.208c1.698 0 2.68-.983 2.68-2.655V8.744c0-1.672-.982-2.655-2.68-2.655H0v1.8h5.195c.562 0 .894.332.894.906v5.948c0 .575-.332.907-.894.907H0v1.8zm16.314 4.353h1.8V1.749h-1.8v20.054zm.88-4.009c3.6 0 6.077-2.45 6.038-6.025-.025-3.523-2.489-5.987-6.037-6.012-3.536-.038-6 2.413-6 6.012 0 3.575 2.464 6.025 6 6.025zm.013-1.71c-2.527 0-4.314-1.787-4.314-4.327 0-2.528 1.787-4.29 4.314-4.29 2.553 0 4.34 1.762 4.328 4.29-.013 2.54-1.8 4.327-4.328 4.327zm17.284 1.366v-1.8h-5.195c-.549 0-.88-.332-.88-.907V8.795c0-.574.331-.906.88-.906h5.195v-1.8h-5.208c-1.698 0-2.668.983-2.668 2.655v6.05c0 1.673.97 2.656 2.668 2.656h5.208z"
            ></path>
        </g>
        <defs>
            <clipPath id="clip0_3061_15606">
                <path fill="#fff" d="M0 0H34.491V23.526H0z"></path>
            </clipPath>
        </defs>
    </svg>
)
export default SportsCourt
