import React, {useEffect, useState} from 'react';
import {Badge, Modal, Table} from 'react-bootstrap';
import translate from '../../../helpers/translations';
import {sportIcons} from "../../../helpers/sportIcons";
import {formatDateRangeLong, formatDateValueLong} from "../../../helpers/date";
import {getSportsCentersLabel} from "../../../helpers/api";
import Iphone from "../../../components/Icons/Iphone";
import Repeat from "../../../components/Icons/Repeat";
import Person from "../../../components/Icons/Person";

interface Props {
  reservation: ReservationCourtPerson;
  showSportsCenter: boolean;
  onHide: () => any;
}

const getUserString = (reservation: ReservationCourtPerson) => {
  let userString = '-';
  if (reservation.person && (reservation.person.firstName || reservation.person.lastName)) {
    userString = `${reservation.person.firstName ? reservation.person.firstName : ''} ${reservation.person.lastName ? reservation.person.lastName : ''}`;
  } else if (reservation.firstName || reservation.lastName) {
    userString = `${reservation.firstName ? reservation.firstName : ''} ${reservation.lastName ? reservation.lastName : ''}`;
  }
  return userString;
};

const getStatusBadge = (reservation: ReservationCourtPerson) => {
  if(!reservation.active) {
    return <Badge bg='danger'>{translate('CANCELED')}</Badge>
  }
  if(new Date(reservation.to) < new Date()) {
    return <Badge bg='success'>{translate('COMPLETED')}</Badge>
  }
  return <Badge bg='success'>{translate('RESERVED')}</Badge>
}

const ReservationDetailsModal: React.FC<Props> = ({showSportsCenter, reservation, onHide}) => {
  const [sportsCenterName, setSportsCenterName] = useState<string>('');

  useEffect(() => {
    if(showSportsCenter) {
      getSportsCentersLabel(reservation.court.sportsCenterId)
          .then(({data}) => data.name ? setSportsCenterName(data.name) : '')
          .catch(() => setSportsCenterName(''))
    }
  }, [reservation]);

  return <Modal show={true} onHide={onHide} size='lg'>
    <Modal.Header closeButton><strong>{translate('reservationDetails')}</strong></Modal.Header>
    <Modal.Body className='p-4'>
      <Table style={{verticalAlign: 'middle'}}>
        <tbody>
        <tr>
          <th style={{width: '30%', paddingRight: '10px'}}>{translate('status')}</th>
          <td style={{width: '70%'}}>
            {getStatusBadge(reservation)}
          </td>
        </tr>
        <tr>
          <th style={{width: '30%', paddingRight: '10px'}}>{translate('sport')}</th>
          <td style={{width: '70%'}}>
            <img height={20} width={20}
                 src={sportIcons[reservation.sport.toLowerCase().replaceAll('-', '_') as keyof typeof sportIcons]}
                 alt={reservation.sport} style={{margin: '1px'}}
            />&nbsp;&nbsp;{translate(reservation.sport)}
          </td>
        </tr>
        <tr>
          <th style={{width: '30%', paddingRight: '10px'}}>{translate('USER')}</th>
          <td style={{width: '70%'}}>{getUserString(reservation)}</td>
        </tr>
        <tr>
          <th style={{width: '30%', paddingRight: '10px'}}>{translate('email')}</th>
          <td style={{width: '70%'}}>{reservation.person && reservation.person.email ? reservation.person.email : (reservation.email ? reservation.email : '-')}</td>
        </tr>
        <tr>
          <th style={{width: '30%', paddingRight: '10px'}}>{translate('phoneNumber')}</th>
          <td style={{width: '70%'}}>{reservation.person && reservation.person.phoneNumber ? reservation.person.phoneNumber : (reservation.phoneNumber ? reservation.phoneNumber : '-')}</td>
        </tr>
        <tr>
          <th style={{width: '30%', paddingRight: '10px'}}>{translate('court')}</th>
          <td style={{width: '70%'}}>{reservation.court.name}</td>
        </tr>
        {showSportsCenter &&
            <tr>
              <th style={{width: '30%', paddingRight: '10px'}}>{translate('sportsCenter')}</th>
              <td style={{width: '70%'}}>{sportsCenterName}</td>
            </tr>
        }
        <tr>
          <th style={{width: '30%', paddingRight: '10px'}}>{translate('time')}</th>
          <td style={{width: '70%'}}>{formatDateRangeLong(reservation.from, reservation.to)}</td>
        </tr>
        <tr>
          <th style={{width: '30%', paddingRight: '10px'}}>{translate('price')}</th>
          <td style={{width: '70%'}}>{reservation.price} {reservation.currency}</td>
        </tr>
        <tr>
          <th style={{width: '30%', paddingRight: '10px'}}>{translate('labelReservationType')}</th>
          <td>
            {reservation.createdByRole === "USER" ?
                <Iphone style={{marginRight: '3px', verticalAlign: 'text-bottom'}}/> : null}
            {reservation.recurring === true ? <Repeat
                style={{marginRight: '3px', verticalAlign: 'text-bottom'}}/> : null}
            {reservation.createdByRole !== "USER" && !reservation.recurring ? <Person
                style={{marginRight: '3px', verticalAlign: 'text-bottom'}}/> : null}
          </td>
        </tr>
        <tr>
          <th style={{width: '30%', paddingRight: '10px'}}>{translate('paymentMethod')}</th>
          <td style={{width: '70%'}}>{translate(reservation.paymentMethod)}</td>
        </tr>
        <tr>
          <th style={{width: '30%', paddingRight: '10px'}}>{translate('paymentSuccessful')}</th>
          <td style={{width: '70%'}}>{translate(reservation.paymentSuccessful ? 'yes' : 'no')}</td>
        </tr>
        <tr>
          <th style={{width: '30%', paddingRight: '10px'}}>{translate('reservationCreatedAt')}</th>
          <td style={{width: '70%'}}>{formatDateValueLong(reservation.createdAt)}</td>
        </tr>
        {reservation.cancelledAt &&
            <>
              <tr>
                <th style={{width: '30%', paddingRight: '10px'}}>{translate('cancelledAt')}</th>
                <td style={{width: '70%'}}>{formatDateValueLong(reservation.cancelledAt)}</td>
              </tr>
              <tr>
                <th style={{width: '30%', paddingRight: '10px'}}>{translate('cancelledBy')}</th>
                <td style={{width: '70%'}}>{translate(reservation.status.replace('CANCELLED_BY_', '').replace('ADMIN', 'ADMINISTRATOR'))}</td>
              </tr>
            </>
        }
        </tbody>
      </Table>
    </Modal.Body>
  </Modal>
}

export default ReservationDetailsModal;