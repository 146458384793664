import React from 'react';
// @ts-ignore
import logo from '../../assets/logo-simple.svg';
import {Menu, menuClasses, MenuItem, Sidebar, SubMenu, MenuItemStyles} from "react-pro-sidebar";
import {useNavMenu} from "./NavMenuProvider";
import {Typography} from "../Typography";
import translate from "../../helpers/translations";
import CalendarClock from "../Icons/CalendarClock";
import Gears from "../Icons/Gears";
import SportsCourt from "../Icons/SportsCourt";
import Person2 from "../Icons/Person2";
import Person from "../Icons/Person";
import {useAuth} from "../../helpers/auth";
import {Link} from "react-router-dom";
import Bell from "../Icons/Bell";


interface Props {
}

const SRSidebar: React.FC<Props> = () => {
    const {person} = useAuth();
    const {activeSidebarKey, sportsCenter, toggled, setToggled} = useNavMenu()

    const menuItemStyles: MenuItemStyles = {
        root: {
            fontSize: '13px',
            fontWeight: 400,
        },
        icon: {
            color: '#59d0ff',
        },
        subMenuContent: ({level, active}) => ({
            backgroundColor:
                level === 0 ? '#F5F7FA' : 'transparent',
        }),
        button: {
            height: '50px',
            [`&.${menuClasses.active}`]: {
                fontWeight: 'bold',
            },
            '&:hover': {
                backgroundColor: 'transparent',
            }
        },

    }

    const SRMenuItem: React.FC<{ itemKey: NavigationSidebarKey, children: any }> = ({itemKey, children}) => {
        return (
            <MenuItem
                onClick={() => setToggled(false)}
                active={activeSidebarKey == itemKey}
                component={
                    <Link
                        to={"/center/".concat(sportsCenter?.id.toString() + "?action=" + itemKey)}
                        className="link"/>
                }
            >
                {children}
            </MenuItem>
        )
    }

    return <>
        <Sidebar
            style={{zIndex: 1021}}
            toggled={sportsCenter ? toggled : false}
            onBackdropClick={() => setToggled(false)}
            breakPoint='all'
            backgroundColor='#ffffff'
            rootStyles={{color: '#313131'}}
            className='shadow sidebar-radius'
            transitionDuration={200}
        >
            <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                <div style={{display: 'flex', paddingTop: '16px', paddingRight: '16px', alignSelf: 'end'}}>
                    <button onClick={() => setToggled(false)} type="button" aria-label="Close" style={{
                        border: '0',
                        backgroundColor: '#F5F7FA',
                        justifySelf: 'center',
                        display: 'inherit',
                        padding: '4px 5px',
                        borderRadius: '0.375rem'
                    }}>
                        <div style={{fontSize: '14px'}} className="btn-close"></div>
                    </button>
                </div>
                <div style={{flex: 1, marginBottom: '32px', marginTop: '32px'}}>
                    <div style={{padding: '0 24px', marginBottom: '24px'}}>
                        <Typography
                            variant="h5"
                            fontWeight={600}
                            style={{color: '#313131', letterSpacing: '0.5px'}}
                        >
                            {translate('menu')}
                        </Typography>
                    </div>
                    <Menu menuItemStyles={menuItemStyles} transitionDuration={200}>
                        <SubMenu
                            label={translate('aboutCenter')}
                            icon={<Gears height={20} width={20}/>}
                        >
                            <SRMenuItem itemKey="about-general">{translate('general')}</SRMenuItem>
                            <SRMenuItem itemKey="about-working-time">{translate('workingTime')}</SRMenuItem>
                            <SRMenuItem itemKey="about-time-off">{translate('timeOff')}</SRMenuItem>
                        </SubMenu>
                        <SubMenu label={translate('reservations')} icon={<CalendarClock height={20} width={20}/>}>
                            <SRMenuItem itemKey="reservations-table">{translate('table')}</SRMenuItem>
                            <SRMenuItem itemKey="reservations-search">{translate('search')}</SRMenuItem>
                            <SRMenuItem
                                itemKey="reservations-recurring">{translate('recurringReservations')}</SRMenuItem>
                            <SRMenuItem itemKey="reservations-color-settings">{translate('colorSettings')}</SRMenuItem>
                        </SubMenu>
                        <SubMenu label={translate('sportsCourts')} icon={<SportsCourt height={20} width={20}/>}>
                            <SRMenuItem itemKey="courts-offers">{translate('offers')}</SRMenuItem>
                            <SRMenuItem itemKey="courts-payment-method">{translate('paymentMethod')}</SRMenuItem>
                        </SubMenu>
                        <SubMenu label={translate('users')} icon={<Person2 height={20} width={20}/>}>
                            <SRMenuItem itemKey="center-users">{translate('centerUsers')}</SRMenuItem>
                        </SubMenu>
                        <SubMenu label={translate('notifications')} icon={<Bell height={20} width={20}/>}>
                            <SRMenuItem itemKey="center-notification-requests">{translate('myNotifications')}</SRMenuItem>
                        </SubMenu>
                        {person && person.role == 'ADMINISTRATOR' &&
                            <SubMenu label={translate('superAdmin')} icon={<Person height={20} width={20}/>}>
                                <SRMenuItem itemKey="admin-configuration">{translate('configuration')}</SRMenuItem>
                                <SRMenuItem itemKey="admin-courts">{translate('courts')}</SRMenuItem>
                                <SRMenuItem itemKey="admin-gallery">{translate('gallery')}</SRMenuItem>
                                <SRMenuItem itemKey="admin-cover-image">{translate('coverImage')}</SRMenuItem>
                            </SubMenu>
                        }
                    </Menu>
                </div>
                <div style={{paddingBottom: '8px', textAlign: 'center'}}>
                    <img src={logo} alt='Sport Router' style={{
                        height: '1.1rem',
                    }}/>
                </div>
            </div>
        </Sidebar>
    </>
}

export default SRSidebar;