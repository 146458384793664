import { useState, useContext, createContext, useEffect } from 'react';
import { getConfiguration } from './api';
import {useAuth} from "./auth";

const ConfigurationContext = createContext<Configuration | null>(null);

export const ConfigurationProvider: React.FC<{ children: any }> = ({ children }) => {
  const {person} = useAuth();
  const [configuration, setConfiguration] = useState<Configuration | null>(null);

  useEffect(() => {
    if(person) getConfiguration().then(({ data }) => setConfiguration(data));
  }, [person]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ConfigurationContext.Provider value={configuration}>
      {children}
    </ConfigurationContext.Provider>
  );
};

export const useConfiguration = () => useContext(ConfigurationContext);