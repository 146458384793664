import React from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';
import { useToastNotifications } from '../helpers/notifications';

const ToastNotifications: React.FC<{}> = () => {
  const { notifications, close } = useToastNotifications();

  return <ToastContainer position='bottom-start' className='position-fixed mx-3 mb-3'>
    {notifications.map(n => <Toast
      key={n.id}
      bg={n.variant || 'light'}
      show={true}
      autohide={true}
      onClose={() => close(n.id)}>
      <Toast.Header closeButton={false}><strong>{n.header}</strong></Toast.Header>
      <Toast.Body>{n.body}</Toast.Body>
    </Toast>)}
  </ToastContainer>;
};

export default ToastNotifications;