import React, {useEffect, useState} from 'react';
import {Alert, Button, Col, Form, Row, Table} from 'react-bootstrap';
import translate from '../../../helpers/translations';
import {updatePaymentMethods} from '../../../helpers/api';
import CenterInformation from "./CenterInformation";
import ReservationCancellationPolicy from "./ReservationCancellationPolicy";
import HalfHourReservations from "../HalfHourReservations/HalfHourReservations";

interface Props {
  sportsCenter: SportsCenter;
  refresh: () => any;
}

const Configuration: React.FC<Props> = ({ sportsCenter, refresh }) => {
  const [isModified, setIsModified] = useState<boolean>(false);
  const [sports, setSports] = useState<Sport[]>([]);
  const [cancellationPoliciesForm, setCancellationPoliciesForm] = useState<IReservationCancellationPolicy[]>([]);

  useEffect(() => {
    setSports(
        Array.from(new Set(
            sportsCenter.offers
                .flatMap(o => o.courts)
                .flatMap(c => c.sports)
        ))
    )

    setCancellationPoliciesForm(sportsCenter.reservationCancellationPolicies)
  }, [sportsCenter]);


  return <div className='d-flex flex-column my-4'>
    <Alert style={{backgroundColor: '#DCDCDC'}} variant='secondary' className="fw-bold mb-1 shadow-sm">{translate('centerInformation')}</Alert>
    <CenterInformation sportsCenterId={sportsCenter.id} sportsCenter={sportsCenter} refresh={refresh}/>
    <Alert style={{backgroundColor: '#DCDCDC'}} variant='secondary' className="fw-bold mb-0 mt-5 shadow-sm">{translate('reservationCancellation')}</Alert>
    <ReservationCancellationPolicy sportsCenter={sportsCenter} refresh={refresh}/>
    {sportsCenter.halfHourSlot && <>
      <Alert style={{backgroundColor: '#DCDCDC'}} variant='secondary' className="fw-bold mb-0 mt-5 shadow-sm">{translate('halfHourSlot')}</Alert>
      <HalfHourReservations sportsCenter={sportsCenter} refresh={refresh}/>
    </>}
  </div>;
}

export default Configuration;