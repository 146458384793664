import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { getProfile, login, refresh } from '../helpers/api';
import { useAuth } from '../helpers/auth';
import Splash from './Splash';
import translate from '../helpers/translations';
// @ts-ignore
import logo from '../assets/logo.svg';

const Login: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const accessToken = localStorage.getItem('accessToken');
  const refreshToken = localStorage.getItem('refreshToken');
  const { setPerson } = useAuth();

  const fetchProfile = (onError: () => any) => {
    getProfile()
      .then(({ data }) => {
        if (data.role === 'ADMINISTRATOR' || data.role === 'SPORTS_CENTER') {
          setPerson(data);
        } else {
          localStorage.removeItem('accessToken');
          localStorage.removeItem('refreshToken');
          setErrorMsg(translate('consoleOnlyAvailableToAdmins') + '.');
        }
        setLoading(false);
      })
      .catch(onError);
  }

  const refreshTokens = () => {
    refresh()
      .then(({ data: { accessToken } }) => {
        localStorage.setItem('accessToken', accessToken);
        fetchProfile(() => { });
      })
      .catch(() => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        setLoading(false);
      })
  }

  useEffect(() => {
    if (accessToken) {
      fetchProfile(() => {
        if (refreshToken) {
          refreshTokens();
        } else {
          localStorage.removeItem('accessToken');
          setLoading(false);
        }
      })
    } else if (refreshToken) {
      refreshTokens();
    } else {
      setLoading(false);
    }
  }, [accessToken, refreshToken]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = () => {
    if (buttonLoading) {
      return;
    }

    setErrorMsg(null);
    setButtonLoading(true);
    login(email, password)
      .then(({ data }) => {
        localStorage.setItem('accessToken', data.accessToken);
        localStorage.setItem('refreshToken', data.refreshToken);
        setButtonLoading(false);
      })
      .catch(() => {
        setButtonLoading(false);
        setErrorMsg(translate('makeSureCorrectCredentials') + '.');
      })
  }

  if (loading) {
    return <Splash />;
  }

  return (
    <div className='bg-light' style={{ height: '100vh', width: '100vw' }}>
      <Container className='h-100'>
        <Row className='h-100'>
          <Col md={8} lg={6} xl={4} className='mx-auto my-auto'>
            <Container className='p-4'>
              {errorMsg && <Alert variant='danger' style={{ fontSize: '0.85rem' }}>
                <div style={{ fontWeight: '700' }}>{translate('couldNotLogYouIn')}.</div>
                {errorMsg}
              </Alert>}
              <Form>
                <Row>
                  <Col className='d-flex justify-content-center'>
                    <img src={logo} alt='Sport Router' style={{ height: '3rem', marginBottom: '2rem' }} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Control type='email' placeholder={translate('email')} onChange={e => setEmail(e.target.value)} />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Control type='password' placeholder={translate('password')} className='mt-4' onChange={e => setPassword(e.target.value)} />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col className='d-flex justify-content-center'>
                    <Button variant='primary' className='mt-4 px-4' style={{ width: '8rem' }} onClick={() => onSubmit()}>
                      {buttonLoading ? <Spinner animation='border' as='span' size='sm' /> : translate('login')}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Container>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Login;