import * as React from "react"
import { SVGProps } from "react"

const Bell = (props: SVGProps<SVGSVGElement>) => (
    <svg width="41" height="33" viewBox="0 0 41 33" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_3514_3434)">
            <path
                d="M7 24.1175C7 25.1835 7.8178 25.8844 9.20514 25.8844H15.3678C15.4847 28.703 17.6898 31.171 20.7712 31.171C23.8671 31.171 26.0722 28.7176 26.189 25.8844H32.3518C33.7245 25.8844 34.5569 25.1835 34.5569 24.1175C34.5569 22.6571 33.0673 21.3427 31.8115 20.0431C30.8477 19.0353 30.5848 16.9617 30.468 15.2823C30.3657 9.52848 28.8761 5.81918 24.9915 4.41724C24.495 2.50417 22.9325 1 20.7712 1C18.6244 1 17.0473 2.50417 16.5653 4.41724C12.6808 5.81918 11.1912 9.52848 11.089 15.2823C10.9722 16.9617 10.7093 19.0353 9.74547 20.0431C8.47496 21.3427 7 22.6571 7 24.1175ZM9.83309 23.6793V23.5041C10.096 23.0806 10.9722 22.2191 11.7316 21.3719C12.783 20.2037 13.2795 18.3198 13.411 15.4721C13.5278 9.09038 15.4263 7.06047 17.9235 6.37411C18.2886 6.2865 18.493 6.11125 18.5076 5.73155C18.5514 4.21279 19.4276 3.14673 20.7712 3.14673C22.1293 3.14673 22.991 4.21279 23.0493 5.73155C23.0639 6.11125 23.2537 6.2865 23.6189 6.37411C26.1307 7.06047 28.0291 9.09038 28.1459 15.4721C28.2774 18.3198 28.7738 20.2037 29.8108 21.3719C30.5848 22.2191 31.4464 23.0806 31.7092 23.5041V23.6793H9.83309ZM17.646 25.8844H23.9109C23.7941 27.8706 22.5382 29.1118 20.7712 29.1118C19.0187 29.1118 17.7482 27.8706 17.646 25.8844Z"
                fill="#313131"/>
        </g>
        <defs>
            <clipPath id="clip0_3514_3434">
                <rect width="27.5569" height="30.1855" fill="white" transform="translate(7 1)"/>
            </clipPath>
        </defs>
    </svg>
)
export default Bell
