import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Form, Row } from 'react-bootstrap';
import ConfirmationModal from '../../components/ConfirmationModal';
import { deletePerson, getPersons } from '../../helpers/api';
import { useToastNotifications } from '../../helpers/notifications';
import translate from '../../helpers/translations';
import SportsCenterAdministratorModal from './SporsCenterAdministratorModal';
import Splash from "../Splash";
import {useNavMenu} from "../../components/NavMenu/NavMenuProvider";
import SRTable from '../../components/SRTable';
import Checkmark from "../../components/Icons/Checkmark";

const PAGE_SIZE = 50;

const Users: React.FC<{}> = () => {
  const { newToastNotification } = useToastNotifications();
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [query, setQuery] = useState<string>('');
  const [role, setRole] = useState<string | null>('USER');
  const [results, setResults] = useState<Person[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [deletingId, setDeletingId] = useState<number | null>(null);
  const [deleteInProgress, setDeleteInProgress] = useState<boolean>(false);
  const [showAddModal, setShowAddModal] = useState<boolean>(false);

  const {setActiveHeaderKey, setActiveSidebarKey, setSportsCenter} = useNavMenu();

  const search = () => {
    setLoading(true);
    setErrorMsg(null);
    setPageNumber(0);
    getPersons(query, role, 0, PAGE_SIZE)
      .then(({ data, headers }) => {
        setLoading(false);
        setResults(data);
        setTotalCount(parseInt(headers['x-total-count']));
      })
      .catch(({ response: { data } }) => {
        setLoading(false);
        setResults([]);
        setTotalCount(0);
        if (data && data.message) {
          setErrorMsg(data.message);
        } else {
          setErrorMsg(translate('errorFetchingResults') + '.');
        }
      })
  }

  const submit = (e: any) => {
    e.preventDefault()
    search()
  }

  const loadMoreResults = () => {
    setLoading(true);
    getPersons(query, role, pageNumber + 1, PAGE_SIZE)
      .then(({ data, headers }) => {
        setLoading(false);
        setResults(results.concat(data));
        setPageNumber(pageNumber + 1);
        setTotalCount(parseInt(headers['x-total-count']));
      })
      .catch(({ response: { data } }) => {
        setLoading(false);
        setResults([]);
        setTotalCount(0);
        if (data && data.message) {
          setErrorMsg(data.message);
        } else {
          setErrorMsg(translate('errorFetchingResults') + '.');
        }
      })
  }

  const doDelete = () => {
    setDeleteInProgress(true);
    if (deletingId !== null) {
      deletePerson(deletingId)
        .then(() => {
          setDeletingId(null);
          setDeleteInProgress(false);
          newToastNotification(translate('deleteUser'), translate('userSuccessfullyDeleted') + '!');
          search();
        })
        .catch(() => {
          setDeletingId(null);
          setDeleteInProgress(false);
          newToastNotification(translate('deleteUser'), translate('errorDeletingUser') + '.', 'danger');
        })
    }
  }

  useEffect(() => {
    setActiveHeaderKey('users')
    setActiveSidebarKey(undefined)
    setSportsCenter(undefined)
  }, []);

  useEffect(search, [role]); // eslint-disable-line react-hooks/exhaustive-deps

  return <>
    {deletingId != null &&
      <ConfirmationModal
        title={translate('confirmDeleting')}
        body={translate('sureDeletingUser') + '?'}
        confirmButtonVariant='danger'
        inProgress={deleteInProgress}
        onConfirm={doDelete}
        onCancel={() => {
          setDeletingId(null);
          setDeleteInProgress(false);
        }}
      />}
    <SportsCenterAdministratorModal
      show={showAddModal}
      onHide={() => setShowAddModal(false)}
      refresh={search}
    />
    {loading && <Splash />}
      {errorMsg && <Alert variant='danger' style={{ fontSize: '0.85rem' }}>
        <div style={{ fontWeight: '700' }}>{translate('errorFetchingResults')}</div>
        {errorMsg}
      </Alert>}
      <Form onSubmit={submit}>
        <Row>
          <Col md={6} className='d-flex mb-2'>
            <Form.Control className='mx-1' placeholder={translate('email')} value={query} onChange={e => setQuery(e.target.value)} />
            <Button type="submit" className='mx-1'>{translate('search')}</Button>
          </Col>
          <Col md={6} className='d-flex mb-2 ' style={{justifyContent: 'end'}}>
            <Button className='mx-1' variant='secondary' onClick={() => setShowAddModal(true)}>{translate('addSportsCenterAdmin')}</Button>
          </Col>
        </Row>
        <Row className="justify-content-center text-center mx-1">
          <Button
              variant={role === 'USER' ? 'secondary' : ''}
              className='shadow-none my-1 mx-1 col-md-auto'
              onClick={() => {
                setRole(role === 'USER' ? null : 'USER')
              }}>
            {translate('users')}
          </Button>
          <Button
              variant={role === 'SPORTS_CENTER' ? 'secondary' : ''}
              className='shadow-none my-1 mx-1 col-md-auto'
              onClick={() => {
                setRole(role === 'SPORTS_CENTER' ? null : 'SPORTS_CENTER')
              }}>
            {translate('sportsCenterOwners')}
          </Button>
          <Button
              variant={role === 'ADMINISTRATOR' ? 'secondary' : ''}
              className='shadow-none my-1 mx-1 col-md-auto'
              onClick={() => {
                setRole(role === 'ADMINISTRATOR' ? null : 'ADMINISTRATOR')
              }}>
            {translate('superAdmins')}
          </Button>
        </Row>
      </Form>
      {results.length === 0 && <Row>
        <Col>
          <h3 className='text-muted mt-5 text-center'>{loading ? `${translate('loading')}...` : translate('noResults')}</h3>
        </Col>
      </Row>}
      {results.length !== 0 && <>
        <SRTable>
          <thead>
            <tr>
              <th>#</th>
              <th>{translate('name')}</th>
              <th>{translate('email')}</th>
              <th>{translate('phoneNumber')}</th>
              <th>{translate('role')}</th>
              <th>{translate('sportsCenter')}</th>
              <th>{translate('id')}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {results.map((p, index) => <tr key={p.id} className='bg-light'>
              <td className='mx-2'>{totalCount - index}</td>
              <td style={{ minWidth: '120px' }}>
                {
                  !p.firstName && !p.lastName ? '-' :
                      p.firstName && !p.lastName ? p.firstName :
                          !p.firstName && p.lastName ? p.lastName :
                              `${p.firstName} ${p.lastName}`
                }
              </td>
              <td>{p.email || '-'}</td>
              <td>{p.phoneNumber || '-'}</td>
              <td>
                <div>{translate(p.role)}</div>
                {p.sportsCenter && <div style={{ minWidth: '120px' }}>{p.sportsCenter.name} ({translate('id')}={p.sportsCenter.id})</div>}
              </td>
              <td>{p.sportsCenters && p.sportsCenters.length > 0 ? p.sportsCenters.map(sc => sc.name).join(', ') : '-'}</td>
              <td>{p.id}</td>
              <td className='text-end'>
                <div className='text-end d-flex justify-content-end align-items-center'>
                  <Button size="sm" variant='danger' onClick={() => setDeletingId(p.id)}>{translate('delete')}</Button>
                  {p.hasAcceptedTerms ?
                      <Checkmark height={20} width={20} style={{marginLeft: '14px', marginRight: '-14px', fill: p.hasAcceptedTerms ? '#0d6efd' : 'none'}}/> :
                      <span style={{marginLeft: '14px', marginRight: '-14px', width: '20px'}}/>
                  }
                </div>
              </td>
            </tr>)}
          </tbody>
        </SRTable>
        {results.length < totalCount && <Row>
          <Col className='d-flex justify-content-center'>
            <Button variant='link' className='mt-3 mb-5' onClick={loadMoreResults}>{translate('seeMoreResults')}</Button>
          </Col>
        </Row>}
        {results.length === totalCount && <Row>
          <Col className='d-flex justify-content-center'>
            <div className='mt-3 mb-5'>{translate('thatsAllResults')}</div>
          </Col>
        </Row>}
      </>}
  </>;
};

export default Users;