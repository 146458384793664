import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Container, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { createSportsCenterAdministrator, getSportsCenters } from '../../helpers/api';
import { useToastNotifications } from '../../helpers/notifications';
import translate from '../../helpers/translations';
import Splash from '../Splash';
import Select from "react-select";

interface Props {
  show: boolean;
  onHide: () => any;
  refresh: () => any;
}

interface FormValues {
  sportsCenterIds: number[];
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

const SportsCenterAdministratorModal: React.FC<Props> = ({ show, onHide, refresh }) => {
  const { newToastNotification } = useToastNotifications();
  const [sportsCenters, setSportsCenters] = useState<SportsCenter[]>([]);
  const [form, setForm] = useState<FormValues>({ sportsCenterIds: [], email: '', firstName: '', lastName: '', phoneNumber: '' });
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  useEffect(() => {
    getSportsCenters()
      .then(({ data }) => {
        const sportsCenters = data.sort((sc1, sc2) => sc1.id - sc2.id);
        setForm({ ...form, sportsCenterIds: [] })
        setSportsCenters(sportsCenters);
      })
      .catch(() => { });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const submit = () => {
    setLoading(true);
    createSportsCenterAdministrator(form)
      .then(() => {
        setLoading(false);
        refresh();
        onHide();
        newToastNotification(translate('addSportsCenterAdmin'), translate('operationSuccessful') + '.');
      })
      .catch(({ response: { data } }) => {
        setLoading(false);
        if (data && data.message) {
          setErrorMsg(data.message);
        } else {
          setErrorMsg(translate('unexpectedError') + '.');
        }
      })
  }

  if (sportsCenters.length === 0) {
    return <Splash />;
  }

  return <Modal show={show}>
    <Modal.Header><strong>{translate('addSportsCenterAdmin')}</strong></Modal.Header>
    <Modal.Body>
      <Form>
        <Container>
          {errorMsg && <Row>
            <Col>
              <Alert variant='danger' className='mt-2' style={{ fontSize: '0.85rem' }}>
                <div style={{ fontWeight: '700' }}>{errorMsg}</div>
              </Alert>
            </Col>
          </Row>}
          <Row className='mt-2'>
            <Col>
              <Form.Label>{translate('sportsCenter')}</Form.Label>
              <Select
                  isMulti
                  options={sportsCenters.map(sc => ({label: sc.name, value: sc.id}))}
                  value={sportsCenters.filter(sc => form.sportsCenterIds.includes(sc.id)).map(sc => ({label: sc.name, value: sc.id}))}
                  onChange={selected => setForm({ ...form, sportsCenterIds: selected.map(s => s.value) })}
              />
            </Col>
          </Row>
          <Row className='mt-2'>
            <Col>
              <Form.Label>{translate('email')}</Form.Label>
              <Form.Control value={form.email} onChange={e => setForm({ ...form, email: e.target.value })} />
            </Col>
          </Row>
          <Row className='mt-2'>
            <Col>
              <Form.Label>{translate('firstName')}</Form.Label>
              <Form.Control value={form.firstName} onChange={e => setForm({ ...form, firstName: e.target.value })} />
            </Col>
          </Row>
          <Row className='mt-2'>
            <Col>
              <Form.Label>{translate('lastName')}</Form.Label>
              <Form.Control value={form.lastName} onChange={e => setForm({ ...form, lastName: e.target.value })} />
            </Col>
          </Row>
          <Row className='mt-2'>
            <Col>
              <Form.Label>{translate('phoneNumber')}</Form.Label>
              <Form.Control value={form.phoneNumber} onChange={e => setForm({ ...form, phoneNumber: e.target.value })} />
            </Col>
          </Row>
        </Container>
      </Form>
    </Modal.Body>
    <Modal.Footer>
      {loading && <Spinner animation='border' variant='secondary' className='mx-auto' />}
      {!loading && <>
        <Button variant='secondary' onClick={() => onHide()}>{translate('close')}</Button>
        <Button variant='primary' onClick={() => submit()}>{translate('save')}</Button>
      </>}
    </Modal.Footer>
  </Modal>;
}

export default SportsCenterAdministratorModal;