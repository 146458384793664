import React, { useState } from 'react';
import { Button, Col, Container, Row, Table } from 'react-bootstrap';
import { RiMapPin2Line, RiParkingBoxLine, RiPinDistanceLine, RiStarLine } from 'react-icons/ri';
import { BiCoffee } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import ConfirmationModal from '../../components/ConfirmationModal';
import { deleteSportsCenter } from '../../helpers/api';
import { formatDateValue } from '../../helpers/date';
import { useToastNotifications } from '../../helpers/notifications';
import translate from '../../helpers/translations';

interface Props {
  results: SearchResult[];
  refresh: () => any;
}

const iconStyle = {marginBottom: '0.25rem', marginLeft: '0.25rem'};

const CentersResults: React.FC<Props> = ({ results, refresh }) => {
  const { newToastNotification } = useToastNotifications();
  const navigate = useNavigate();
  const offers = results.flatMap(r => r.offers);
  const areCalculatedOffers = offers.length > 0 && offers[0].from;
  const [deletingId1, setDeletingId1] = useState<number | null>(null);
  const [deletingId2, setDeletingId2] = useState<number | null>(null);
  const [deleteInProgress, setDeleteInProgress] = useState<boolean>(false);

  const doDelete = () => {
    setDeleteInProgress(true);
    if (deletingId2 !== null) {
      deleteSportsCenter(deletingId2)
        .then(() => {
          setDeletingId2(null);
          setDeleteInProgress(false);
          newToastNotification(translate('deleteSportsCenter'), translate('sportsCenterSuccessfullyDeleted') + '!');
          refresh();
        })
        .catch(() => {
          setDeletingId2(null);
          setDeleteInProgress(false);
          newToastNotification(translate('deleteSportsCenter'), translate('errorDeletingSportsCenter') + '.', 'danger');
        })
    }
  }

  return <>
    {deletingId1 != null &&
        <ConfirmationModal
            title={`${translate('confirmDeleting')} 1/2`}
            body={`${translate('sureToDelete')} ${results.find(r => r.id === deletingId1)?.name}?`}
            confirmButtonVariant='danger'
            onConfirm={() => {
              setDeletingId1(null);
              setDeletingId2(deletingId1);
            }}
            onCancel={() => {
              setDeletingId1(null);
            }}
        />}
    {deletingId2 != null &&
        <ConfirmationModal
            title={`${translate('confirmDeleting')} 2/2`}
            body={translate('sureToDeleteConfirm')}
            confirmButtonVariant='danger'
            inProgress={deleteInProgress}
            onConfirm={() => {
              setDeletingId2(null);
              setDeleteInProgress(false);
              doDelete()
            }}
            onCancel={() => {
              setDeletingId2(null);
            }}
        />}
    {results.map(r => <Row key={r.id} className='mx-1 my-5 p-3 pt-4 text-dark shadow border rounded'>
      <Col>
        <Container>
          <Row>
            <Col md={6} className='mb-2'>
              <div className='fw-bold'>{r.visible ? r.name : `[${translate('hidden').toUpperCase()}] ${r.name}`}</div>
              <div className='text-muted'>{`${r.address}, ${r.neighborhoodName ? `${r.neighborhoodName}, ` : ''}${r.cityName}, ${r.countryName}`}</div>
              <div className='text-muted'>{translate('id')}: {r.id}</div>
            </Col>
            <Col md={6} className='mb-2 d-flex flex-column align-items-end text-muted' style={{textAlign: "end"}}>
              <div>{`(${r.lat}, ${r.lng})`} <RiMapPin2Line style={iconStyle} /></div>
              <div>{r.rating} <RiStarLine style={iconStyle} /></div>
              <div className="d-flex flex-row">
                <div className={r.parking ? 'text-success' : 'text-danger'}><RiParkingBoxLine style={iconStyle} /></div>
                <div className={r.bar ? 'text-success' : 'text-danger'}><BiCoffee style={iconStyle} /></div>
              </div>
            </Col>
          </Row>
          <hr style={{marginTop: 0, marginBottom: 10}}/>
          <Row>
            <Col className='d-flex justify-content-center'>
             <Button variant='secondary' className='mx-1' onClick={() => navigate(`/center/${r.id}`) }>{translate('select')}</Button>
             <Button variant='danger' className='mx-1' onClick={() => setDeletingId1(r.id)}>{translate('delete')}</Button>
            </Col>
          </Row>
        </Container>
      </Col>
    </Row>)}
  </>
};

export default CentersResults;