import { DAYS_OF_WEEK } from './enums';
import translate from "./translations";

export const padded = (value: number) => `${value <= 9 ? '0' : ''}${value}`;

export const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString();
  return `${day}.${month}.${year}.`;
};

export const formatTime = (dateString: string) => {
  const date = new Date(dateString);
  return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
};

export const dateToDateValue = (date: Date) => {
  const year = date.getFullYear();
  const month = padded(date.getMonth() + 1);
  const day = padded(date.getDate());
  const hours = padded(date.getHours());
  const minutes = padded(date.getMinutes());
  const seconds = padded(date.getSeconds());
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
}

export const dateValueToDate = (date: string) => new Date(date);

export const formatDateValue = (iso: string) => {
  const date = new Date(iso);
  const day = padded(date.getDate());
  const month = padded(date.getMonth() + 1);
  const year = date.getFullYear();
  const hours = padded(date.getHours());
  const minutes = padded(date.getMinutes());
  return `${day}.${month}.${year}. ${hours}:${minutes}`;
}

export const formatDateValueLong = (iso: string) => {
  const dayOfWeek = translate(DAYS_OF_WEEK[(new Date(iso).getDay() + 6) % 7])
  return `${dayOfWeek}, ${formatDateValue(iso)}`;
}

export const formatDateRangeLong = (from: string, to: string) => {
  const dayOfWeek = translate(DAYS_OF_WEEK[(new Date(from).getDay() + 6) % 7])
  return `${dayOfWeek}, ${formatDate(from)} ${formatTime(from)} - ${formatTime(to)} (${Math.abs(new Date(to).getTime() - new Date(from).getTime()) / 36e5}h)`;
}

export const dateToTimeValue = (date: Date) => {
  const hours = padded(date.getHours());
  const minutes = padded(date.getMinutes());
  return `${hours}:${minutes}`;
}

export const timeValueToDate = (time: string) => {
  const parts = time.split(':');
  const date = new Date();
  date.setHours(parseInt(parts[0]));
  date.setMinutes(parseInt(parts[1]));
  return date;
}

export const openingHoursSorter = (x1: any, x2: any) => {
  const dow1 = DAYS_OF_WEEK.findIndex(dow => dow === x1.dayOfWeek);
  const dow2 = DAYS_OF_WEEK.findIndex(dow => dow === x2.dayOfWeek);
  if (dow1 > dow2) {
    return 1;
  } else if (dow2 > dow1) {
    return -1;
  } else if (x1.from > x2.from) {
    return 1;
  } else if (x1.from < x2.from) {
    return -1;
  } else if (x1.to > x2.to) {
    return 1;
  } else {
    return -1;
  }
};

export const dateEquals = (date1: Date, date2: Date) => {
  return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
}

export const dateStringEquals = (date1: string, date2: string) => {
  return dateEquals(new Date(date1), new Date(date2))
}

export const subtractMinutes = (date: Date, minutes: number) => {
  return new Date(date.getTime() - (minutes * 60 * 1000))
}

export const END_OF_DAY = (() => {
  const date = new Date();
  date.setHours(23);
  date.setMinutes(59);
  return date;
})();