import React, {useState} from 'react';
import {Container, Navbar, Nav, Button, NavDropdown, ButtonProps, Dropdown, Row, Form, DropdownButton} from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';
import {useNavigate} from 'react-router-dom';
import {useAuth} from '../../helpers/auth';
import translate from '../../helpers/translations';
// @ts-ignore
import logo from '../../assets/logo.svg';
import {useNavMenu} from "./NavMenuProvider";
import User from "../Icons/User";

const otherLanguage = () => localStorage.getItem('language') === 'EN' ? 'HR' : 'EN';

interface Props {}

const SRHeader: React.FC<Props> = () => {
    const navigate = useNavigate();
    const {person, setPerson} = useAuth();
    const {sportsCenter, activeSidebarKey, activeHeaderKey, onToggleClicked} = useNavMenu()
    const [showDropdown, setShowDropdown] = useState(false);

    const logout = () => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        setPerson(null);
        navigate('/');
    }

    const changeLanguage = () => {
        localStorage.setItem('language', otherLanguage());
        window.location.reload();
    }

    const onBrandClick = () => {
        if(person?.role == 'ADMINISTRATOR') {
            navigate("/centers");
        } else if(person?.role == 'SPORTS_CENTER' && sportsCenter?.id) {
            navigate("/center/".concat(sportsCenter.id.toString()));
        } else {
            navigate("/");
        }
    }

    const handleChangeSportsCenter = (id: number) => {
        setShowDropdown(false)
        navigate("/center/".concat(id.toString(),activeSidebarKey ?  "?action=" + activeSidebarKey : ''), {replace: true})
    }

    const CustomToggle = React.forwardRef<any, any>(({onClick}, ref) => (
        <div
            style={{borderRadius: '40px', width: '40px', height: '40px', margin: '3px', backgroundColor: '#00000026'}}
            className="shadow-sm"
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            <User width={40} height={40} style={{cursor: 'pointer', padding: 0, margin: 0, borderRadius: '40px'}}/>
        </div>
    ));

    return (
        person ?
            <Navbar expand='md' className='border-bottom shadow-sm' style={{backgroundColor: '#F6F8FA'}}>
                <Container fluid style={{paddingLeft: '16px', paddingRight: '16px'}}>
                    <div className="d-flex">
                        <div style={{display: sportsCenter ? 'flex' : 'none', alignItems: 'center'}}>
                            <button disabled={!sportsCenter}
                                    className={"navbar-toggler shadow-sm"}
                                    type="button" style={{
                                display: 'inherit',
                                padding: '4px 5px',
                                backgroundColor: '#fff',
                                opacity: !sportsCenter ? '50%' : undefined
                            }}
                                    onClick={() => onToggleClicked()}>
                                <span className="navbar-toggler-icon" style={{width: '24px', height: '24px'}}></span>
                            </button>
                        </div>
                        <Navbar.Brand onClick={() => onBrandClick()} className={sportsCenter ? "ms-3" : ""} style={{cursor: 'pointer'}}>
                            <img src={logo} alt='Sport Router' style={{
                                height: '1.3rem',
                                marginBottom: '4px'
                            }}/>
                        </Navbar.Brand>
                    </div>

                    <Dropdown align='end' style={{justifySelf: 'flex-end'}} show={showDropdown}
                              autoClose={true} onToggle={() => setShowDropdown(!showDropdown)}>
                        <Dropdown.Toggle as={CustomToggle}/>
                        <Dropdown.Menu className="shadow" style={{borderRadius: '20px', marginTop: '5px'}}>
                            <div className="d-flex align-items-center py-2 px-3"
                                 style={{width: '100%', maxWidth: '100vw', textAlign: 'center'}}>
                                <div><User width={50} height={50} className="shadow-sm" style={{borderRadius: '50px'}}/>
                                </div>
                                <div className="d-flex flex-column mx-3 align-items-start">
                                    <h5 style={{
                                        whiteSpace: "nowrap",
                                        maxWidth: '50vw',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }}
                                        className="m-0">{(person.firstName || person.lastName) ? `${person.firstName ? person.firstName : ''} ${person.lastName ? person.lastName : ''}` : person.email}</h5>
                                    <div className="m-0" style={{
                                        color: 'gray',
                                        maxWidth: '60vw',
                                        minWidth: '200px',
                                        overflow: 'hidden',
                                        whiteSpace: "nowrap",
                                        textOverflow: 'ellipsis',
                                        textAlign: 'start'
                                    }}>{person.role === 'SPORTS_CENTER' && sportsCenter?.name}
                                    </div>
                                </div>
                                <button onClick={() => setShowDropdown(false)} type="button" aria-label="Close"
                                        style={{
                                            border: '0',
                                            backgroundColor: '#F5F7FA',
                                            alignSelf: 'start',
                                            padding: '4px 5px',
                                            borderRadius: '0.375rem'
                                        }}>
                                    <div style={{fontSize: '14px'}} className="btn-close"></div>
                                </button>
                            </div>
                            <hr className="p-0 m-0 my-1"/>
                            {person.role === 'ADMINISTRATOR' && <>
                                <div style={{color: '#313131'}} className='mx-2'>
                                    <Dropdown.Item
                                        style={{height: '40px', fontWeight: activeHeaderKey == 'centers' ? 'bold' : ''}}
                                        title={"test"}><LinkContainer to={`/centers`}><Nav.Link
                                        active={activeHeaderKey === 'centers'}>{translate('centers')}</Nav.Link></LinkContainer></Dropdown.Item>
                                    <Dropdown.Item style={{
                                        height: '40px',
                                        fontWeight: activeHeaderKey == 'reservations' ? 'bold' : ''
                                    }}><LinkContainer to={`/reservations`}><Nav.Link
                                        active={activeHeaderKey === 'reservations'}>{translate('reservations')}</Nav.Link></LinkContainer></Dropdown.Item>
                                    <Dropdown.Item style={{
                                        height: '40px',
                                        fontWeight: activeHeaderKey == 'users' ? 'bold' : ''
                                    }}><LinkContainer to={`/users`}><Nav.Link
                                        active={activeHeaderKey === 'users'}>{translate('users')}</Nav.Link></LinkContainer></Dropdown.Item>
                                    <Dropdown.Item style={{
                                        height: '40px',
                                        fontWeight: activeHeaderKey == 'notification-requests' ? 'bold' : ''
                                    }}><LinkContainer to={`/notification-requests`}><Nav.Link
                                        active={activeHeaderKey === 'notification-requests'}>{translate('notifications')}</Nav.Link></LinkContainer></Dropdown.Item>
                                </div>
                                <hr className="p-0 m-0 my-1"/>
                            </>}
                            {person.role === 'SPORTS_CENTER' && person.sportsCenters && person.sportsCenters.length > 1 && <>
                                <div className="d-flex align-items-center pt-0 pb-2 px-3"
                                     style={{width: '100%', textAlign: 'center'}}>
                                    <Form.Group controlId="adminSportsCenter"
                                                style={{width: '100%', textAlign: 'start'}}>
                                        <Form.Label style={{
                                            fontSize: '12px',
                                            margin: 0,
                                            padding: 0
                                        }}>{translate('sportsCenter')}</Form.Label>
                                        <Form.Select style={{cursor: 'pointer'}} value={sportsCenter?.id}
                                                     aria-label={translate('sportsCenter')}
                                                     onChange={e => handleChangeSportsCenter(+e.target.value)}>
                                            {person.sportsCenters?.map((sc, i) =>
                                                <option key={sc.id} value={sc.id}>{sc.name}</option>)
                                            }
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                                <hr className="p-0 m-0 my-1"/>
                            </>}
                            <div className="d-flex align-items-center py-2 px-3"
                                 style={{width: '100%', textAlign: 'center'}}>
                                <Button variant='outline-secondary' style={{width: '100%'}}
                                        onClick={changeLanguage}>{otherLanguage()}</Button>
                            </div>
                            <div className="d-flex align-items-center py-2 px-3"
                                 style={{width: '100%', textAlign: 'center'}}>
                                <Button variant='outline-danger' style={{width: '100%'}}
                                        onClick={logout}>{translate('logout')}</Button>
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
                </Container>
            </Navbar>
            : null
    );
}

export default SRHeader;