import React, {useEffect, useState} from 'react';
import { Button, Col, Container, Form, InputGroup, Modal, Row} from 'react-bootstrap';
import {
    deleteImage,
    getSportsCenterImages,
    updateSportsCenterImagesOrder,
    uploadImage
} from '../../../helpers/api';
import { useToastNotifications } from '../../../helpers/notifications';
import translate from '../../../helpers/translations';
import Splash from '../../Splash';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

interface Props {
  sportsCenterId: number;
}

const getFileFormat = (file: File) => {
  if (file.name.endsWith('.png')) {
    return 'PNG';
  } else if (file.name.endsWith('.jpg') || file.name.endsWith('.jpeg')) {
    return 'JPEG';
  } else {
    return null;
  }
}

const CenterImages: React.FC<Props> = ({ sportsCenterId }) => {
    const { newToastNotification } = useToastNotifications();
    const [loading, setLoading] = useState<boolean>(false);
    const [images, setImages] = useState<Image[]>([])
    const [imagesFromServer, setImagesFromServer] = useState<Image[]>([])
    const [selectedImage, setSelectedImage] = useState<Image | null>(null);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    const fetchImages = (id: number) => {
        getSportsCenterImages(id)
            .then(({ data }) => {
                setImagesFromServer(data.sort(sortImage))
                setImages(data.sort(sortImage))
            })
    }


    useEffect(() => {
        if(sportsCenterId) {
            fetchImages(sportsCenterId)
        }
    }, [sportsCenterId]);


    // Custom sorting function to place nulls last
    function sortImage(a: Image, b: Image) {
        if (!a.orderNumber && !b.orderNumber) {
            return 0;
        } else if (!a.orderNumber) {
            return 1; // Place nulls last
        } else if (!b.orderNumber) {
            return -1; // Place nulls last
        } else {
            return a.orderNumber - b.orderNumber;
        }
    }

    const isOrderChanged = () => {
        if(images.length != imagesFromServer.length) return true;
        for (let i = 0; i < images.length; i++) {
            if(images[i].id != imagesFromServer[i].id ||
                images[i].orderNumber != imagesFromServer[i].orderNumber
            ) return true
        }
        return false;
    }

    const handleSaveOrder = () => {
        updateSportsCenterImagesOrder(sportsCenterId, {images: images.map(i => ({id: i.id, orderNumber: i.orderNumber}))})
            .catch(({ response: { data } }) => {
                if (data && data.message) {
                    newToastNotification('Error', data.message)
                } else {
                    newToastNotification('Error', translate('unexpectedError'))
                }
            })
            .finally(() => {
                fetchImages(sportsCenterId)
            })
    }

    const doUpload = (file: File | null) => {
        if (!file) {
            return;
        }

        const format = getFileFormat(file);
        if (!format) {
            newToastNotification('Error', translate('unsupportedFormat'))
            return;
        }

        setLoading(true);
        const formData = new FormData();
        formData.append('format', format);
        formData.append('file', file);
        formData.append('sportsCenterId', sportsCenterId.toString());
        formData.append('orderNumber', (images.length + 1).toString());

        uploadImage(formData)
            .then(() => {
                newToastNotification(translate('uploadImage'), translate('operationSuccessful') + '.');
                fetchImages(sportsCenterId);
            })
            .catch(({ response: { data } }) => {
                if (data && data.message) {
                    newToastNotification('Error', data.message)
                } else {
                    newToastNotification('Error', translate('unexpectedError'))
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const doDelete = (id: string) => {
        setLoading(true);
        deleteImage(id, sportsCenterId)
            .then(() => {
                newToastNotification(translate('deleteImage'), translate('operationSuccessful') + '.');
                fetchImages(sportsCenterId);
            })
            .catch(({ response: { data } }) => {
                if (data && data.message) {
                    newToastNotification('Error', data.message)
                } else {
                    newToastNotification('Error', translate('unexpectedError'))
                }
            })
            .finally(() => setLoading(false))
    }

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        setSelectedFile(file || null);
    };

    const openImagePreview = (image: Image) => {
        setSelectedImage(image);
    };

    const closeImagePreview = () => {
        setSelectedImage(null);
    };

    const onDragEnd = (result: any) => {
        if (!result.destination) return;
        const updatedImages = [...images];
        const [reorderedImage] = updatedImages.splice(result.source.index, 1);
        updatedImages.splice(result.destination.index, 0, reorderedImage);
        for (let i = 0; i < updatedImages.length; i++) {
            updatedImages[i].orderNumber = i + 1;
        }
        setImages(updatedImages);
    };

    return (
        <Container>
            {loading && <Splash />}
            <Row className="mt-3">
                <Col md={10}>
                    <Form.Control
                        id="imageUpload"
                        type="file"
                        accept="image/*"
                        className='m-1'
                        onChange={handleFileChange}
                        disabled={loading}
                    />
                </Col>
                <Col md={2}>
                    <Button disabled={loading || !selectedFile} className='m-1' variant="primary" onClick={() => doUpload(selectedFile)}>
                        {translate('uploadImage')}
                    </Button>
                </Col>
            </Row>
            <Row className="mt-3 p-2">
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable" direction="horizontal">
                        {(provided, snapshot) => (
                            <div
                                className='m-1 p-2'
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                style={{display: 'flex', padding: 'grid', overflowX: 'auto', overflowY: 'hidden'}}
                            >
                                {images.map((image, index) => (
                                    <Draggable key={image.id} draggableId={image.id} index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <div className='shadow-sm' style={{ cursor: 'pointer', borderRadius: 5, border: 'solid 2px #f3f3f3', backgroundColor: 'white', padding: '2px', margin: '1px'}}>
                                                    <img className='p-1'src={image.url} style={{height: '150px'}} alt={image.url} onClick={() => openImagePreview(image)}/>
                                                    <hr className='m-1'/>
                                                    <InputGroup className="pb-1 pt-1 d-flex justify-content-center" >
                                                        <Button
                                                            size='sm'
                                                            variant="danger"
                                                            onClick={() =>  doDelete(image.id)}
                                                            disabled={loading}
                                                        >
                                                            {translate('delete')}
                                                        </Button>
                                                    </InputGroup>
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </Row>
            <Row>
                <Col className='text-center mt-4'>
                    <Button onClick={handleSaveOrder} disabled={!isOrderChanged() || loading} className='px-5' variant='primary' >{translate('save')}</Button>
                </Col>
            </Row>
            <Modal show={selectedImage !== null} onHide={closeImagePreview} size='lg' >
                <Modal.Body style={{textAlign: 'center'}}>
                    {selectedImage && (
                        <img
                            src={selectedImage.url}
                            alt={`Image ${selectedImage.id}`}
                            className="img-fluid"
                        />
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeImagePreview}>
                        {translate('close')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>)
}

export default CenterImages;